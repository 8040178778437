<template>
  <section v-if="!this.$store.state.page.isMobile"  style="height: 980px;">
    <!--    <h2 class="subTitle">이벤트</h2>-->


    <!--    <div class="cusbox" style="min-height: 500px;">-->

    <!--      <div class="nbox" style="margin: 20px auto auto auto !important;">-->
    <!--&lt;!&ndash;        <SideLink :category="'customer'"></SideLink>&ndash;&gt;-->

    <!--        <div class="cusRight" style="min-height: 500px;">-->
    <!--          <div class="boHeader">-->
    <!--            <router-link to="/bbs/c/w" class="btn03">문의하기</router-link>-->
    <!--            <a @click="accountReq" class="btn03">계좌문의</a>-->
    <!--          </div>-->
    <!--          <table class="listTable03">-->
    <!--            <colgroup>-->
    <!--              <col width="120" />-->
    <!--              <col width="" />-->
    <!--              <col width="124" />-->
    <!--              <col width="124" />-->
    <!--            </colgroup>-->
    <!--            <tr>-->
    <!--              <th>번호</th>-->
    <!--              <th>제목</th>-->
    <!--              <th>상태</th>-->
    <!--              <th>작성일</th>-->
    <!--            </tr>-->
    <!--            <tr v-if="!lists">-->
    <!--              <td colspan="4" style="text-align: center;">-->
    <!--                <div>-->
    <!--                  <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>-->
    <!--                </div>-->
    <!--              </td>-->
    <!--            </tr>-->
    <!--            <tr v-if="lists && lists.length === 0">-->
    <!--              <td colspan="4">-->
    <!--                작성된 글이 없습니다.-->
    <!--              </td>-->
    <!--            </tr>-->
    <!--            <tr v-if="lists && lists.length > 0" v-for="(row, index) in lists" :key="'customer'+index">-->
    <!--              <td>{{ ((payload.page -1) * payload.size) + (index+1)   }}</td>-->
    <!--              <td class="al"><a @click="movePage(`bbs/c/d/${row.board_seq}`)">{{ row.board_title }}</a></td>-->
    <!--              <td><span-->
    <!--                  class="state" :class="{'coRed': row.replyDate, 'coBlue': !row.replyDate}">-->
    <!--                {{ row.replyDate ? '답변완료' : '대기중' }}-->
    <!--              </span>-->
    <!--              </td>-->
    <!--              <td>{{ row.board_regdatetime | formatDate('YYYY-MM-DD HH:mm:ss') }}</td>-->
    <!--            </tr>-->
    <!--          </table>-->

    <!--          <Pagination v-if="pagination" :req-data="reqData" :payload="payload" :pagination="pagination"></Pagination>-->
    <!--        </div>-->
    <!--      </div>-->

    <!--    </div>-->
    <div id="contents_wrap">
      <div class="contents">
        <div class="tab_container">
          <div id="tab4" class="tab_content fading" style="display: block;">
            <ul class="smk_accordion" style="display: block;">
              <li class="acc_section acc_active">
                <div class="acc_head"><h3>이벤트</h3></div>
                <div class="acc_content" style="display: block;">

                  <div class="acc_content_in_2">
                    <div class="float_inline">
<!--                      <div class="my_search_wrap">-->
<!--&lt;!&ndash;                        <a @click="accountReq" class="btn3ci">계좌문의</a>&ndash;&gt;-->
<!--                        <router-link to="/bbs/c/w" class="btn3cbi">문의 작성</router-link>-->
<!--                      </div>-->
                      <div class="my_search_list" id="qna-div" style="margin: 0px; display: block;">
                        <div id="qnaDt_wrapper" class="dataTables_wrapper no-footer">
                          <table id="qnaDt" class="dataTable no-footer" cellspacing="0" cellpadding="0" width="100%" role="grid" style="width: 100%;">
                            <thead>
                            <tr role="row">
                              <th class="sorting" tabindex="0" aria-controls="qnaDt" rowspan="1" colspan="1" aria-label="제목: activate to sort column ascending" style="width: 498px;">제목</th>
                              <th class="sorting" tabindex="0" aria-controls="qnaDt" rowspan="1" colspan="1" aria-label="보낸이: activate to sort column ascending" style="width: 249px;">작성자</th>
                              <th class="sorting_desc" tabindex="0" aria-controls="qnaDt" rowspan="1" colspan="1" aria-label="수신일시: activate to sort column ascending" style="width: 249px;" aria-sort="descending">작성일시</th>
                            </tr>
                            </thead>
                            <tbody>
                            <template v-for="(row, index) in lists">
                              <tr :key="'noets'+index">
                                <td @click="toggle(index)">{{ row.board_title }}</td>
                                <td @click="toggle(row, index)">관리자</td>
                                <td @click="toggle(row, index)">
                                  {{ row.board_regdatetime | formatDate('YYYY-MM-DD HH:mm:ss') }}
                                </td>
                              </tr>
                              <transition name="fade" :key="'notesContent'+index">

                                <tr class="dt-txt" v-if="checkArray(index)">
                                  <td class="dt-txt" colspan="4" v-html="renderHtml(row.board_content)">
                                  </td>
                                </tr>
                              </transition>
                            </template>
                            </tbody>
                          </table>
                          <Pagination v-if="pagination" :payload="payload" :pagination="pagination" :req-data="reqData" ></Pagination>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>

            <!--            <div id="conf_modal1_delmsg" class="bg_mask_pop2 conf_modal">-->
            <!--              <div class="bg_mask_pop_title">-->
            <!--                <span class="popup_logo"><img src="/images/popup_logo.png"></span>-->
            <!--                <span class="popup_close conf_modal_close"><img src="/images/popup_close.png"></span>-->
            <!--              </div>-->
            <!--              <div class="bg_mask_pop2_in">-->
            <!--                <div class="pop_icon_center">-->
            <!--                  <img src="/images/question_icon.png">-->
            <!--                </div>-->
            <!--                <div class="pop_text">-->
            <!--                  메세지를 삭제 하시겠습니까?-->
            <!--                </div>-->
            <!--                <div class="btn_wrap">-->
            <!--                  <span class="btn3 conf_modal_close">취소</span>-->
            <!--                  <span class="btn3c conf_modal_yes ">확인</span>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->
          </div>

        </div>
      </div>
    </div>

  </section>
  <div v-else style="background-color: #1c2021;">
    <div class="header2">
      <h2>이벤트</h2>
    </div>
    <div class="tabs1">
      <router-link to="/bbs/c/l" class="active">1:1문의</router-link>
      <router-link to="/bbs/n/l">공지사항</router-link>
    </div>

    <div class="bg-content" style="background: #252929;">
      <div class="notice">
        <ul>
          <li v-if="!lists">
            <div style="text-align: center;">
              <Loading></Loading>
            </div>
          </li>
          <li style="background: #252929;" v-if="lists && lists.length === 0">
            <a class="q">
              <span>
                작성된 글이 없습니다.
              </span>
            </a>
          </li>
          <li style="background: #252929;" v-else v-for="(row, index) in lists" :key="'customer'+index">
            <a @click="toggle(index)" class="q">
              <span>
                {{ row.board_title }}
              </span>
              <em>{{ row.board_regdatetime | formatDate('YYYY-MM-DD') }}</em>
            </a>
            <transition name="fade">
              <div class="cont" v-html="renderHtml(row.board_content)" v-if="expandedArticle.indexOf(index)>=0" style="display: block"></div>
            </transition>
            <transition name="fade">대
              <div class="rep" v-for="comment in row.comments" :key="comment.seq" v-html="renderHtml(comment.content)" v-if="expandedArticle.indexOf(index)>=0" style="display: block"></div>
            </transition>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
// import Pagination from '../../../components/Pagination01'
import Loading from '../../../components/LoadingGray'

export default {
  name: "Event",
  components: {
    // Pagination,
    Loading
  },
  data: function(){
    return {
      path: 'bbs/e/l',
      lists: null,
      pagination: null,
      payload: {
        page: 1,
        size: 20,
        keyword: null,
        siteName: 'vikbet'
      },
      expandedArticle: []
    }
  },
  mounted: function() {
    this.reqData(this.payload)
  },
  methods: {
    reqData: function(payload){
      return this.$store.dispatch('GET_EVENT', { payload } )
          .then(data => {
            const result = data.data;
            this.lists = result.payload.list;
            this.pagination = result.payload.pagination;
          }).catch(err => {
            alert(err.response.data.error);
          })
    },
    movePage: function(page){
      this.$router.push({path: `/${page}`})
    },
    renderHtml: function(str){
      str.replace(/(?:\r\n|\r|\n)/g, '<br />')
      return str.split('\n').join('<br />');
    },
    toggle: function (index) {
      if (this.expandedArticle.indexOf(index) >= 0) return this.expandedArticle.splice(this.expandedArticle.indexOf(index), 1)
      this.expandedArticle.push(index)
    },
    checkArray: function (id) {
      let result = this.expandedArticle.indexOf(id) >= 0
      return result
    },
  }
}
</script>

<style scoped>
/*-------------------------------------------------------------------------------------*
 *  Contents                                                                           *
 *-------------------------------------------------------------------------------------*/
#contents_wrap {float:left; width:100%; padding:0 0 50px 0; background-color: #1c2021;}
.contents {width:1330px; margin:0px auto; min-height:600px; }
.contents_in_m20 {width:100%; float:left; margin:0 0 20px 0}

.tab_container {clear: both; float: left; width: 100%;}
.tab_content {padding: 20px 0 0 0;}

ul.tabs {float: left; height: 40px; border-bottom: 1px solid #2d3232; width: 100%;}
ul.tabs li {float: left;height: 40px;line-height: 40px;margin-bottom: -1px;margin-right: 2px;background: #252929;overflow: hidden;position: relative;width:160px; height:40px;text-align:center;font-size:14px; font-weight:bold;display:inline-block;}
ul.tabs li a {background: #252929;color:#6b7a7a; height:40px;display:inline-block;}
ul.tabs li a:hover {background: #2d3232;color:#6b7a7a; height:40px;display:inline-block;}
html ul.tabs li.active a:hover{background: #2d3232;color:orange ; height:40px;display:inline-block;border: 1px solid #2d3232;border-bottom: none;}
html ul.tabs li.active a  {background: #1c2021;color:orange ;border: 1px solid #2d3232;border-bottom: none; height:40px;display:inline-block;}

/*fade*/
.tab_content.fading{
  opacity: 1;
  animation: fading 0.4s linear;
}

.fading {
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-duration: 705ms;
  -moz-animation-timing-function: ease-in-out;
  -moz-animation-duration: 705ms;
}

@keyframes fading {
  from { opacity: 0 }
  to { opacity: 1 }

}

.notice_img{width:300px; height:180px; display: block;}
.notice_img img{width:300px; height:180px;}
.notice_text {text-align:center; width:280px;padding:0 10px 0 10px;  display: block;}
.notice_text_t {color:#ffffff; margin:20px 0 0 0}
.notice_text_c { margin:10px 0 0 0}
.view_c_in_img {margin:0 30px 0 0; float:left;}
.view_c_in_t {width:100%; margin:0 0 20px 0; float:left;}
.view_c_in {width:100%;}
.view_title {float:left; padding:30px 0 0 0 }
.view_t {font-size:30px; color:#ffffff; letter-spacing:-1px; line-height:35px; text-align:left}
.view_center {width:1330px; margin:0px auto}
.view_date {margin:10px 0 0 0; text-align:left}
.view_c_in_text { text-align:left}
.viewchange .resultitem {vertical-align: top;display: block; margin: 10px 12px 0;position: relative;text-align:center}
.viewchange .resultitem .box {vertical-align: top;display: block;overflow: hidden; background-size: cover; position: relative;cursor: pointer;}
.viewchange .resultitem>.box>a.clickevent { position: absolute;top: 0px; bottom: 0px;left: 0px;right: 0px;height: auto;}
.picflex {display: flex; flex-wrap: wrap;}
.picflex .resultitem {position: static;width: auto;border: 0;height: 260px;flex-grow: 1;}
.picflex .resultitem .box {border: none;width: 300px; height: 260px;line-height: normal; }
.picflex .resultitem.open {height: 675px;}
.picflex .resultitem.open:after {content: "";font-size: 0px;width: 0;height: 0;border-style: solid;border-width: 0px 20px 20px 20px; border-color: transparent transparent #121516 transparent; top: -10px;position: relative;}
.picflex .detailview {display: none; background: #121516;height: 395px;position: absolute;left: 0;right: 0;margin-top: 20px;overflow: auto;}
.picflex .resultitem.open .detailview {display: block;}
.controllers {text-align: right;}
.controllers a {display: inline-block;margin:30px 0 0 0;}

.picflex .resultitem{
  max-width: 308px!important;
}

.view_c_in_img img{
  width: 300px;
  height: 180px;
}
.view_c_in_img{
  margin:0px!important;
  width: 30%!important;
  float: left;
  text-align: left;

}
.view_c_in_text{
  width: 70%!important;
  float:right!important;
  margin-bottom: 20px;

}

.view_c_in_text p{
  padding:6px;
}
.picflex .detailview{
  min-height: 396px;
  height: auto!important;
}
</style>

