<template>
  <div>
    <transition name="fade">
      <div class="menu_2_wrap" v-if="isView2" id="TOP_BOX" style="top: 0px;">
        <div style="padding:0% 5% 5% 5%;  width:90% ">
          <div class="menu_2_wrap_in">
            <p class="user_name" v-if="user.members_grade === 0">신규
              <span class="user_name_b">{{ user.members_nickname }}</span>님
            </p>
            <p class="user_name" v-else>Lv.{{ user.members_grade }} 
              <span class="user_name_b">{{ user.members_nickname }}</span>님
            </p>
            <span class="close_2" style="top:55px;" @click="isView2 = false">×</span>
          </div>
          <div class="menu_2_wrap_in">
            <table width="100%" cellspacing="0" cellpadding="0">
              <tbody>
              <tr>
                <td class="down_td1">
                  <router-link to="/cash/in"><span class="popover_btn">충전신청</span></router-link>
                </td>
                <td class="down_td2"><router-link to="/cash/out"><span class="popover_btn">환전신청</span></router-link></td>
              </tr>
              <tr>
                <td class="down_td1"><router-link to="/point/change"><span class="popover_btn">포인트전환</span></router-link></td>
                <td class="down_td1"><router-link to="/event/roulette"><span class="popover_btn">룰렛: <span style="color: orange;">{{ user.roulette }}</span> 장</span></router-link></td>
                <!--                <td class="down_td2"><router-link to="/casino/deposit"><span class="popover_btn">카지노 머니 전환</span></router-link></td>-->
              </tr>
              <tr>
                <td class="down_td1"><router-link to="/info/user"><span class="popover_btn">내 정보</span></router-link></td>
                <td class="down_td2"><router-link to="/info/bet"><span class="popover_btn">베팅내역</span></router-link></td>
              </tr>
              <tr>
                <td class="down_td1"><router-link to="/bbs/c/l"><span class="popover_btn">1:1문의</span></router-link></td>
                <td class="down_td2"><router-link to="/bbs/msg/l"><span class="popover_btn">쪽지함</span></router-link></td>
              </tr>
              <tr>
                <td class="down_td1"><router-link to="/event/chulsuk"><span class="popover_btn">출석 이벤트</span></router-link></td>
                <td class="down_td2"><router-link to="/info/recommender"><span class="popover_btn">추천인</span></router-link></td>
              </tr>
<!--              <tr>-->
<!--                <td class="down_td1"><router-link to="/event/roulette"><span class="popover_btn">룰렛: <span style="color: orange;">{{ user.roulette }}</span> 장</span></router-link></td>-->
<!--                &lt;!&ndash;                <td class="down_td2"><router-link to="/event/lotto"><span class="popover_btn">로또: <span style="color: orange;">{{ user.lotto }}</span> 장</span></router-link></td>&ndash;&gt;-->
<!--              </tr>-->
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </transition>
    <div id="header_wrap">

      <div class="menu">
        <a>
        <span class="menu-icon" @click="isView = !isView">
          <span class="menu-line menu-line-1"></span>
          <span id="DISCONNECT" class="menu-line menu-line-2" style="background-color:white;"></span>
          <span class="menu-line menu-line-3"></span>
        </span>
        </a>
      </div>

      <div class="aside" :class="{'in': isView}" style="overflow:auto;">
        <div class="aside-header">
          <div style="width:250px;padding-left:10px;">
            Welcome to &nbsp;
            <img src="@/assets/img/main/vikbet_logo_main.png" height="20" width="auto">
          </div>
          <div class="close"
               data-dismiss="aside"
               aria-hidden="true"
               id="closeclose"
               style="float:right"
               @click="isView = false">
            <img src="@/assets/img/ico/close_x.png">
          </div>
        </div>
        <div class="nav_list_1">
          <ul class="menu_accordion">
            <li>
              <span class="menu_acc_h">
                  <a @click="toggleMenu('이벤트')"><img src="@/assets/img/ico/ham_icon_002.png"> 이벤트</a>
              </span>
              <transition name="fade">
                <div class="menu_acc_b" v-if="expendedSubMenu.indexOf('이벤트') >= 0" style="background-color: #172021; display: block;" >
                  <ul class="menu_acc_b_in">
                    <li><router-link to="/event/roulette"><div class="menu_btn_3">룰렛</div></router-link></li>
<!--                    <li><router-link to="/event/lotto"><div class="menu_btn_4">로또</div></router-link></li>-->
                    <li><router-link to="/event/chulsuk"><div class="menu_btn_4">출석체크</div></router-link></li>
                  </ul>
                </div>
              </transition>
            </li>
          </ul>
          <ul class="menu_accordion">
            <li>
              <span class="menu_acc_h">
                  <a @click="toggleMenu('스포츠')"><img src="@/assets/img/ico/ham_icon_001.png"> 스포츠</a>
              </span>
              <transition name="fade">
                <div class="menu_acc_b" v-if="expendedSubMenu.indexOf('스포츠') >= 0" style="background-color: #172021; display: block;">
                  <ul class="menu_acc_b_in">
                    <li v-if="koreanVisible"><router-link to="/sport/korean"><div class="menu_btn_4">엘스포츠 한국형</div></router-link></li>
                    <li v-if="europeanVisible"><router-link to="/sport/lsport/prematch"><div class="menu_btn_4">엘스포츠 유럽형</div></router-link></li>
                    <li v-if="koreanVisible"><router-link to="/sport/korean_new"><div class="menu_btn_4">벳컨 한국형</div></router-link></li>
                    <li v-if="europeanVisible"><router-link to="/sport/prematch"><div class="menu_btn_4">벳컨 유럽형</div></router-link></li>
                    <li><router-link to="/sport/special"><div class="menu_btn_4">스페셜</div></router-link></li>
                  </ul>
                </div>
              </transition>
            </li>
          </ul>
          <ul class="menu_accordion">
            <li>
              <span class="menu_acc_h">
                  <a @click="toggleMenu('카지노')"><img src="@/assets/img/ico/ham_icon_004.png"> 카지노</a>
              </span>
              <transition name="fade">
                <div class="menu_acc_b" v-if="expendedSubMenu.indexOf('카지노') >= 0" style="background-color: #172021; display: block;">
                  <ul class="menu_acc_b_in" v-if="user">
                    <li><router-link to="/casino/mlive"><div class="menu_btn_4">라이브 카지노</div></router-link></li>
                    <li><router-link to="/casino/mslot"><div class="menu_btn_3">슬롯 게임</div></router-link></li>
                    <!-- <li><router-link to="/casino/deposit"><div class="menu_btn_4">카지노 충전</div></router-link></li>
                    <li><router-link to="/casino/withdrawal"><div class="menu_btn_4">카지노 환전</div></router-link></li> -->
<!--                    <li><a @click="openCasino('')"><div class="menu_btn_4">마이크로게이밍 카지노</div></a></li>-->
                  </ul>
                </div>
              </transition>
            </li>
          </ul>
          <ul class="menu_accordion">
            <li>
              <span class="menu_acc_h">
                  <a @click="toggleMenu('미니게임')"><img src="@/assets/img/ico/ham_icon_005.png"> 미니게임</a>
              </span>
              <transition name="fade">
                <div class="menu_acc_b" v-if="expendedSubMenu.indexOf('미니게임') >= 0" style="background-color: #172021; display: block;">
                  <ul class="menu_acc_b_in">
                    <li v-if="tokenRouletteVisible"><router-link to="/token/roulette"><div class="menu_btn_4">토큰-룰렛</div></router-link></li>
                    <li v-if="tokenBaccaratVisible"><router-link to="/token/baccarat"><div class="menu_btn_4">토큰-바카라1</div></router-link></li>
                    <li v-if="tokenHighlowVisible"><router-link to="/token/highlow"><div class="menu_btn_4">토큰-하이로우</div></router-link></li>
                    <li v-if="tokenHighlow5sVisible"><router-link to="/token/highlow5s"><div class="menu_btn_4">토큰-하이로우5초</div></router-link></li>
                    <li v-if="lotusOEVisible"><router-link to="/lotus/oe"><div class="menu_btn_3">로투스-홀짝</div></router-link></li>
                    <li v-if="lotusBaccarat1Visible"><router-link to="/lotus/baccarat1"><div class="menu_btn_4">로투스-바카라1</div></router-link></li>
                    <!-- <li v-if="powerballVisible"><router-link to="/mini/npowerball"><div class="menu_btn_4">네임드-N파워볼</div></router-link></li>
                    <li v-if="powerladderVisible"><router-link to="/mini/npowerladder"><div class="menu_btn_4">네임드-N파워사다리</div></router-link></li> -->
                    <li v-if="powerballVisible"><router-link to="/mini/dhpowerball"><div class="menu_btn_4">동행파워볼</div></router-link></li>
                  </ul>
                </div>
              </transition>
            </li>
          </ul>
          <span class="menu_acc_c">
            <router-link to="/sport/live2" style="color:#B0BDBF"><img src="@/assets/img/ico/ico_aside4.png" style="margin-left:2px; width: 23px; height: 23px;">&nbsp; 실시간</router-link>
          </span>
          <ul class="menu_accordion">
            <li>
              <span class="menu_acc_h">
                  <a @click="toggleMenu('인플레이')"><img src="@/assets/img/ico/ico_aside2.png"> 인플레이</a>
              </span>
              <transition name="fade">
                <div class="menu_acc_b" v-if="expendedSubMenu.indexOf('인플레이') >= 0" style="background-color: #172021; display: block;">
                  <ul class="menu_acc_b_in">
                    <li><router-link to="/sport/livematch"><div class="menu_btn_4">인플레이</div></router-link></li>
                    <li><router-link to="/sport/lsport/livematch"><div class="menu_btn_4">조합 인플레이</div></router-link></li>
                    <li v-if="kInplayVisible"><router-link to="/sport/kinplay"><div class="menu_btn_4">K-인플레이</div></router-link></li>
                  </ul>
                </div>
              </transition>
            </li>
          </ul>

          <!--<span class="menu_acc_c" v-if="inplayVisible">-->
          <!--  <router-link to="/sport/livematch" style="color:#B0BDBF"><img src="@/assets/img/ico/ico_aside2.png" style="margin-left:3px; width: 23px; height: 23px;">&nbsp; 인플레이</router-link>-->
          <!--</span>-->
          <span class="menu_acc_c">
            <router-link to="/info/bet" style="color:#B0BDBF"><img src="@/assets/img/ico/ico_aside1.png" style="margin-left:2px; width: 23px; height: 23px;">&nbsp; 베팅내역</router-link>
          </span>


          <!--        <ul class="menu_accordion">-->
          <!--          <li>-->
          <!--            <span class="menu_acc_h">-->
          <!--              <a href="#"><img src="/img/ham_icon_005.png"> 미니/스피드 게임</a>-->
          <!--            </span>-->
          <!--            <div class="menu_acc_b" style="background-color: #172021">-->
          <!--              <ul class="menu_acc_b_in">-->
          <!--                <li><a href="javascript:run_bomb()"><div class="menu_btn_5">폭탄제거</div></a></li>-->
          <!--                <li><a href="/img/bomb_guide"><div class="menu_btn_5" style="color:yellow">폭탄제거 가이드 </div></a></li>-->
          <!--                <li><a href="javascript:run_mine()"><div class="menu_btn_5">지뢰찾기</div></a></li>-->
          <!--                <li><a href="/doc/mine_guide"><div class="menu_btn_5" style="color:yellow">지뢰찾기 가이드</div></a></li>-->
          <!--                <li><a href="javascript:run_keno()"><div class="menu_btn_5">스피드 키노</div></a></li>-->
          <!--                <li><a href="javascript:run_blackwhite()"><div class="menu_btn_5">블랙&amp;화이트</div></a></li>-->
          <!--                <li><a href="javascript:run_turtle()"><div class="menu_btn_5">거북이 레이싱</div></a></li>-->
          <!--                <li><a href="javascript:run_mgplus('SMG_happyMonsterClaw')"><div class="menu_btn_5">인형뽑기</div></a></li>-->
          <!--                <li><a href="javascript:run_endsutda2()"><div class="menu_btn_5">끗땡섯다 </div></a></li>-->
          <!--                <li><a href="javascript:run_luckywheels()"><div class="menu_btn_5">행운의 룰렛 </div></a></li>-->
          <!--                <li><a href="/spincube/slot"><div class="menu_btn_5">슬롯 게임 </div></a></li>-->

          <!--                &lt;!&ndash; 스피드 게임 &ndash;&gt;-->
          <!--                <li><a href="javascript:run_plinko()"><div class="menu_btn_5">플링코</div></a></li>-->
          <!--                <li><a href="javascript:run_coin()"><div class="menu_btn_5">코인</div></a></li>-->
          <!--                <li><a href="javascript:run_dice()"><div class="menu_btn_5">주사위</div></a></li>-->
          <!--                <li><a href="javascript:run_speedroulette()"><div class="menu_btn_5">스핀</div></a></li>-->
          <!--                <li><a href="javascript:run_hilow()"><div class="menu_btn_5">하이로우</div></a></li>-->
          <!--              </ul>-->
          <!--            </div>-->
          <!--          </li>-->
          <!--        </ul>-->
        </div>

        <div class="nav_list_2">
          <span class="menu_acc_d">
            <router-link  to="/sport/result" style="color:#B0BDBF">경기결과</router-link>
          </span>
          <span class="menu_acc_d">
            <router-link style="color:#B0BDBF" to="/bbs/n/l">공지사항 / 이벤트</router-link>
          </span>
          <span class="menu_acc_d">
            <router-link style="color:#B0BDBF" to="/bbs/rule/l">도움말</router-link>
          </span>
          <span class="menu_acc_d">
            <router-link style="color:#B0BDBF" to="/level">레벨별 혜택</router-link>
          </span>

<!--          <ul class="menu_accordion">-->
<!--            <li>-->
<!--            <span class="menu_acc_h" style="background-color:#2E3939">-->
<!--                <a href="#" style="color:#B0BDBF"> 도움말</a>-->
<!--            </span>-->

<!--              <div class="menu_acc_b" style="background-color: #172021">-->
<!--                <ul class="menu_acc_b_in">-->
<!--                  <li><a href="/doc/bc_guide">-->
<!--                    <div class="menu_btn_5">스포츠 베팅 가이드</div>-->
<!--                  </a></li>-->
<!--                  <li><a href="/doc/casino_guide">-->
<!--                    <div class="menu_btn_5">카지노 베팅 가이드</div>-->
<!--                  </a></li>-->
<!--                  <li><a href="/doc/bet_rule">-->
<!--                    <div class="menu_btn_5">베팅 규정</div>-->
<!--                  </a></li>-->
<!--                  <li><a href="/doc/faq">-->
<!--                    <div class="menu_btn_5">자주 묻는 질문</div>-->
<!--                  </a></li>-->
<!--                  <li><a href="/doc/rule">-->
<!--                    <div class="menu_btn_5">이용 약관</div>-->
<!--                  </a></li>-->
<!--                  <li><a href="/doc/slot_help">-->
<!--                    <div class="menu_btn_5">슬롯게임 가이드</div>-->
<!--                  </a></li>-->
<!--                </ul>-->
<!--              </div>-->
<!--            </li>-->
<!--          </ul>-->

          <div style="width:100%;background-color:#252E2E;height:1px;"></div>

<!--          <span class="menu_acc_d">-->
<!--            <a style="color:#B0BDBF" href="/img/level_mobile.png?cache=8">레벨별 혜택</a>-->
<!--          </span>-->


        </div>


        <div class="menu_btn_wrap" v-if="!user">
          <table cellspacing="0" cellpadding="0" width="100%">
            <tbody style="height: 50px;">
            <tr style="height: 50px;">
              <td style="width:48%; padding:0 2% 0 0">
                <router-link to="/login"><span class="btn1c">로그인</span></router-link>
              </td>
              <td style="width:48%;  padding:0 0 0 2%"><router-link to="/join"><span class="btn1x">회원가입</span></router-link></td>
            </tr>
            <tr></tr>
            </tbody>
          </table>
        </div>
        <div class="menu_btn_wrap" v-else>
          <table cellspacing="0" cellpadding="0" width="100%">
            <tbody style="height: 50px;">
            <tr style="height: 50px;">
              <td style="width:48%; padding:0 6% 0 0">
                <a @click="logOut"><span class="btn1cb">로그아웃</span></a>
              </td>
            </tr>
            <tr></tr>
            </tbody>
          </table>
        </div>
      </div>

      <h1 class="logo">
        <router-link to="/main">
          <img src="@/assets/img/main/vikbet_logo_main.png" style="padding-bottom: 5px;" height="48" width="auto">
        </router-link>
      </h1>

      <template v-if="!user">
        <div class="util_right">
          <router-link to="/login">
            <div class="top_btn4__">로그인</div>
          </router-link>
        </div>
        <div class="util_right">
          <router-link to="/join">
          <div class="top_btn4__" style="background-color:gray">회원가입</div>
          </router-link>
        </div>
      </template>
      <template v-else>
        <div class="util_right">
          <router-link to="/bbs/c/l">
            <div class="top_btn4__" style="background-color:purple">문의</div>
          </router-link>
        </div>
        <div class="util_right"><router-link to="/cash/in">
          <div class="top_btn4__">충전신청</div>
        </router-link></div>
        <div class="util_wrap open_2" id="TOP_MONEY_BOX" style="font-size:15px" @click="isView2 = !isView2">
          <div class="util_money_wrap">머니 <span class="util_money" id="TOP_MONEY">{{ user.members_cash | makeComma }}</span></div>
          <div class="util_point_wrap">포인트 <span class="util_point" id="TOP_POINT">{{ user.members_point | makeComma }}</span></div>
          <div class="util_left_arrow">
            <a><img src="@/assets/img/ico/select_mark.jpg?a=1" style="margin:3px 0 0 0; "></a>
          </div>
        </div>
      </template>


    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import MSG from "@/contants/msg";

export default {
  name: "MHeader",
  // props: ['user'],
  data() {
    return {
      isView: false,
      isView2: false,
      expendedSubMenu: [],
      kplayFlag: true,
    }
  },
  computed: {
    menuVisible(){
      return this.$store.getters['MENU_VISIBLE'];
    },
    inplayVisible(){
      return this.menuVisible?.find(e => e.name === '인플레이').visible
    },
    kInplayVisible(){
      return this.menuVisible?.find(e => e.name === 'K인플레이').visible
    },
    holdemVisible(){
      return this.menuVisible?.find(e => e.name === '홀덤').visible
    },
    europeanVisible(){
      return this.menuVisible?.find(e => e.name === '스포츠-유럽형').visible
    },
    koreanVisible(){
      return this.menuVisible?.find(e => e.name === '스포츠-한국형').visible
    },
    korean2Visible(){
      return this.menuVisible?.find(e => e.name === '스포츠-한국형2').visible
    },
    liveVisible(){
      return this.menuVisible?.find(e => e.name === '라이브').visible
    },
    lotusOEVisible(){
      return this.menuVisible?.find(e => e.name === '로투스-홀짝').visible
    },
    lotusBaccarat1Visible(){
      return this.menuVisible?.find(e => e.name === '로투스-바카라1').visible
    },
    tokenRouletteVisible(){
      return this.menuVisible?.find(e => e.name === '토큰-룰렛').visible
    },
    tokenBaccaratVisible(){
      return this.menuVisible?.find(e => e.name === '토큰-바카라').visible
    },
    tokenHighlowVisible(){
      return this.menuVisible?.find(e => e.name === '토큰-하이로우').visible
    },
    tokenHighlow5sVisible(){
      return this.menuVisible?.find(e => e.name === '토큰-하이로우5초').visible
    },
    powerballVisible(){
      return this.menuVisible?.find(e => e.name === '파워볼').visible
    },
    powerladderVisible(){
      return this.menuVisible?.find(e => e.name === '파워사다리').visible
    },
    ...mapState({
      user: state => state.user.user,
      isMobile: state => state.page.isMobile,
    }),
  },
  watch: {
    $route(){
      this.isView = false;
      this.isView2 = false;
    }
  },
  methods: {
    logOut: function(){
      localStorage.removeItem('authToken');
      this.$store.dispatch("LOGOUT", null)
      this.$router.push({ path: '/main'})
    },
    toggleMenu(menu){
      console.log(menu);
      const index = this.expendedSubMenu.indexOf(menu);
      if (index < 0) return this.expendedSubMenu.push(menu);
      this.expendedSubMenu.splice(index, 1)
    },
    openCasino: function(game){
      if (!this.user) return alert(MSG.isNotLogin);
      const config = this.user.members_detail_setting;
      this.$store.dispatch('CHECK_CASINO_WALLET');
      if (game === 'evolution' && !this.menuVisible.find(e => e.name === '에볼루션').visible){
        return this.$store.dispatch('SHOW_ERR_MSG3', '에볼루션 점검중입니다.');
      }
      if (game === 'asia' && !this.menuVisible.find(e => e.name === '아시아').visible){
        return this.$store.dispatch('SHOW_ERR_MSG3', '아시아는 점검중입니다.');
      }
      if (game === 'evolution' && !config.베팅권한.에볼루션.active){
        this.$store.dispatch('SET_ERR_MSG', '에볼루션은 이용하실 수 없습니다.');
        this.$store.dispatch('SHOW_MODAL', 'error2');
        return;
      }
      if (game === 'asia' && !config.베팅권한.아시아게이밍.active){
        this.$store.dispatch('SET_ERR_MSG', '아시아게이밍은 이용하실 수 없습니다.');
        this.$store.dispatch('SHOW_MODAL', 'error2');
        return;
      }
      const data = {
        payload: {
          type: game === 'evolution' ? 45 : 44,
        }
      }
      this.$store.dispatch('GET_LOBBY', data)
          .then(data => {
            const result = data.data;
            if (result.payload) {
              window.open(result.payload.data.lobbyUrl, 'Casino', '_blank')
            }
          })
          .catch(err => {
            alert(err.response.data.error);
          })
    },
    openKplay: function(product_id){
      if (!this.user) return alert(MSG.isNotLogin);
      if (!this.kplayFlag) return alert('처리 중입니다. 잠시만 기다려주세요')
      this.kplayFlag = false
      const data = {
        "domain_url": window.location.protocol + "//" + window.location.host,
        product_id,
        "type": 0,
        "is_mobile": this.isMobile
      }

      this.$store.dispatch('GET_KPLAY_SPORTS', data)
          .then(data => {
            const result = data.data;
            if (result.url) {
              console.log()
              window.open(result.url, 'KPlay', '_blank')
              this.kplayFlag = true
            }
          })
          .catch(err => {
            alert(err.response.data.error);
          })
    },
  }
}
</script>

<style scoped>
/*-------------------------------------------------------------------------------------*
 *  Header                                                                             *
 *-------------------------------------------------------------------------------------*/
#header_wrap {
  width: 100%;
  position: fixed;
  top: 0px;
  left: 0;
  background: #252b2b;
  height: 50px;
  line-height: 50px;
  z-index: 9999
}

.logo {
  position: absolute;
  left: 40px;
  top: 0px
}

.logo img {
  height: 20px;
  width: auto;
}


/* top ▲ */
.go-top {
  display: block;
  width: 40px;
  height: 40px;
  line-height: 35px;
  text-align: center;
  font-size: 30px;
  position: fixed;
  bottom: -40px;
  left: 20px;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
  background-color: #404040;
  color: #FFFFFF;
  text-decoration: none;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  z-index: 999
}

.go-top.show {
  bottom: 10px;
}

.go-top:hover {
  background-color: orange;
  color: #FFFFFF;
}

/*-------------------------------------------------------------------------------------*
 *  nav                                                                                *
 *-------------------------------------------------------------------------------------*/


.aside-header {
  width: 100%;
  height: 50px;
  line-height: 50px;
  background: #1e1f1f;
  padding: 0 0 0 0;
}

.nav_list_1 {
  width: 100%;
}

.nav_list_2 {
  width: 100%
}

.nav_list_1 {
  border-bottom: solid 1px #000000
}


.menu_btn_3 {
  width: 94%;
  height: 45px;
  line-height: 45px;
  color: #ffffff;
  padding: 0 3% 0 3%;
  background: #172021;
  border-bottom: solid 1px rgba(0, 0, 0, 0.2);
  border-top: solid 1px rgba(0, 0, 0, 0.2);
  text-align: left;
}

.menu_btn_4 {
  width: 94%;
  height: 45px;
  line-height: 45px;
  color: #ffffff;
  padding: 0 3% 0 3%;
  background: #172021;
  border-bottom: solid 1px rgba(0, 0, 0, 0.2);
  text-align: left;
}

.menu_btn_5 {
  width: 94%;
  height: 45px;
  line-height: 45px;
  color: #ffffff;
  padding: 0 3% 0 3%;
  background: #172021;
  text-align: left;
}


.menu_btn_wrap {
  width: 90%;
  padding: 0 5% 0 5%;
  margin: 10px 0 0 0
}

.menu_btn_wrap > ul > li {
  width: 50%
}


.menu_acc_c {
  width: 100%;
  height: 45px;
  line-height: 45px;
  display: block;
  text-align: left;
  padding: 0 3% 0 3%;
  border-bottom: solid 1px rgba(0, 0, 0, 0.5);
  background: #292f30;
}

.menu_accordion {
  border-bottom: solid 1px rgba(0, 0, 0, 0.5);
}

/*
.menu_acc_c a { width:100%; height:45px; line-height:45px; display:inline-block ; text-align:left; color:#ffffff; background:url("/design/images/accordion-closed.png") center right no-repeat;}
*/
.menu_acc_d {
  width: 100%;
  height: 45px;
  line-height: 45px;
  display: block;
  text-align: left;
  padding: 0 3% 0 3%;
  background: #2e3939;
  border-bottom: solid 1px rgba(0, 0, 0, 0.2);
  text-align: left;
}

/*
.menu_acc_d a { width:100%; height:45px; line-height:45px; display:inline-block ; text-align:left; color:#b0bdbf;  background:url("/design/images/accordion-closed.png") center right no-repeat;}

 */
.menu_accordion {
  list-style: none;
  padding: 0;
}

.menu_accordion li .menu_acc_h {
  position: relative;
  background: #292f30;
  line-height: 45px;
}

.menu_accordion li .menu_acc_h img {
  height: 30px;
  width: auto
}

.menu_acc_c img {
  height: 30px;
  width: auto
}

.menu_accordion span.menu_acc_h {
  width: 100%;
  height: 45px;
  line-height: 45px;
  display: block;
}

.menu_accordion span.menu_acc_h a {
  background: url("/design/images/accordion-closed.png") center right no-repeat;
  width: 94%;
  height: 45px;
  line-height: 45px;
  display: inline-block;
  text-align: left;
  margin: 0 3% 0 3%;
  color: #ffffff;
}

.menu_acc_b_in li {
}

.menu_accordion span.active a {
  background: url("/design/images/accordion-opened.png") center right no-repeat;
}

.menu_accordion li div.menu_acc_b {
  display: none;
}

.menu_acc_b_in li {
  text-align: left;
}

.mask_wrap {
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.7);
  display: none;
  z-index: 1
}

.menu_2_wrap {
  width: 100%;
  position: fixed;
  left: 0;
  top: -350px;
  background: #1e2323;
  /* height: 305px; */
  z-index: 22;
  padding: 50px 0 0 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, .7)
}

.down_td1 {
  width: 49%;
  padding: 0 2% 1% 0
}

.down_td2 {
  width: 49%;
  padding: 0 0 1% 0
}

.aside {
  min-width: 320px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  border-right: 1px solid #333;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  z-index: 2000;
  width: 0px;
  min-width: 0px;
  overflow: hidden;
  transition: 0.35s width ease, 0.35s min-width ease;
  background: #292f30;
}

.aside .aside-header {
  position: relative;


}

.aside .aside-header .close {
  position: absolute;
  right: 10px;
  top: 0;
  cursor: pointer;

}

.aside .aside-header .close img {
  height: 25px;
  width: auto

}

.aside .aside-contents {

}

.aside.in {
  width: 100%;
  min-width: 320px;
}

.aside-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;

  opacity: 0;
  display: none;
}

.aside-backdrop.in {
  display: block;
  opacity: 0.5;
}


.menu {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 50px;
  width: 35px;


}

.menu-link {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1002;
}

.menu-icon {
  position: absolute;
  width: 20px;
  height: 14px;
  margin: auto;
  left: 0;
  top: 0;
  right: 0;
  bottom: 1px;
}

.menu-circle {
  background-color: #252b2b;
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 50%;
  transform: scale(1);
  z-index: 1000;
  transition: all 0.5s ease-in-out;
}

.menu-overlay {
  visibility: hidden;
  background-color: #292f30;
  color: #333;
  height: 100%;
  width: 100%;
  position: fixed;
  text-align: center;
  transition: opacity 0.2s ease-in-out;
  z-index: 1001;
  overflow-y: auto
}


.menu-line {
  background-color: #ffffff;
  height: 2px;
  width: 100%;
  border-radius: 2px;
  position: absolute;
  left: 0;
  transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}

.menu-line-1 {
  top: 0;
}

.menu-line-2 {
  top: 0;
  bottom: 0;
  margin: auto;
}

.menu-line-3 {
  bottom: 0;
}

.menu:hover .menu-circle {
}

.menu-overlay.open {
  opacity: 1;
  visibility: visible;
}

.menu.open .menu-circle {
  transform: scale(60);
}

.menu.open .menu-icon {
}

.menu.open .menu-line-2 {
  opacity: 0;
}

.menu.open .menu-line-1 {
  transform: translateY(7px) translateY(-50%) rotate(-45deg);
  background: #27b3e5
}

.menu.open .menu-line-3 {
  transform: translateY(-7px) translateY(50%) rotate(45deg);
  background: #27b3e5
}


.util_wrap {
  float: right;
  margin: 5px 10px 0 0;
  background: #1e2323;
  line-height: 1em;
  padding: 5px 25px 5px 12px;
  position: relative;
  border-radius: 5px
}

.util_money_wrap {
  font-size: 0.8em;
}

.util_point_wrap {
  font-size: 0.8em
}

.util_money {
  color: #ffa800;
  float: right;
  margin: 0 0 0 7px
}

.util_point {
  color: #ffa800;
  float: right;
  margin: 0 0 0 7px
}

.util_left_arrow {
  position: absolute;
  right: 10px;
  top: 10px
}

.util_left {
  float: left;
  margin: 10px 0 0 0
}

.util_center {
  float: left;
  margin: 0 0 0 7px
}

.util_right {
  float: right;
  margin: 0 10px 0 0
}

span.open_2 {
  position: fixed;
  left: 10px;
  top: 10px;
  cursor: pointer;
  z-index: 3
}

span.close_2 {
  position: absolute;
  right: 20px;
  top: 70px;
  cursor: pointer;
  color: #ffffff;
  FONT-SIZE: 25PX
}

span.close_2 A {
  color: #ffffff
}

span.open_2 {
  line-height: 50px
}

span.open_2 img {
  height: 40px;
  width: auto
}

/*-------------------------------------------------------------------------------------*
 * 201702수정                                                                              *
 *-------------------------------------------------------------------------------------*/
.top_btn4__ {
  padding: 0 10px 0 10px;
  height: 28px;
  line-height: 28px;
  display: inline-block;
  background: #ffa800;
  color: #ffffff;
  text-align: center;
  font-weight: 700;
  font-size: 13px
}

.swipe_date_wrap {
  width: 100%;
  float: left;
  overflow-x: scroll;
  overflow-y: hidden;
  height: 43px;
  line-height: 43px;
  background: #272c2e
}

.swipe_date_in {
  width: 1000px;
  float: left;
  padding: 0 0 0 10px
}

.date_tab_on {
  padding: 0 10px 0 10px;
  height: 35px;
  line-height: 35px;
  border: solid 1px orange;
  display: inline-block;
  font-size: 11pt
}

.date_tab {
  padding: 0 10px 0 10px;
  height: 35px;
  line-height: 35px;
  display: inline-block;
  font-size: 11pt;
  background-color: #202020
}

.y_bet_num {
  background: #343d40;
  border-radius: 20px;
  padding: 3px 10px 3px 10px
}

.swipe_date_in ul li {
  float: left;
  line-height: 40px;
  margin: 0 3px 0 3px
}

.user_name {
  font-weight: 700;
  padding: 10px 0 10px 0;
  float: left;
  font-size: 15px;
}

.user_name_b {
  color: orange;
}

.btn1c {
  width: 137px;
  height: 40px;
  display: inline-block;
  background: orange;
  text-align: center;
  color: #ffffff;
  line-height: 40px;
  border-radius: 5px;
  font-weight: bold;
}

.btn1cb {
  width: 100%;
  height: 40px;
  display: inline-block;
  background: orange;
  text-align: center;
  color: #ffffff;
  line-height: 40px;
  border-radius: 5px;
  font-weight: bold;
}

.btn1x {
  width: 137px;
  height: 40px;
  display: inline-block;
  background: #363e3f;
  text-align: center;
  color: #ffffff;
  line-height: 40px;
  border-radius: 5px;
  font-weight: bold;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.8s
}

.fade-enter,
.fade-leave-to
  /* .fade-leave-active in <2.1.8 */

{
  opacity: 0
}

/* point_layer */
.popover-wrapper {
}

.example-popover-2 {
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
  -moz-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
  -webkit-transition: all 240ms cubic-bezier(.34, 1.61, .7, 1);
  -o-transition: all 240ms cubic-bezier(.34, 1.61, .7, 1);
  transition: all 240ms cubic-bezier(.34, 1.61, .7, 1);
  -webkit-transform: scale(0);
  transform: scale(0);
  transform-origin: 29px -10px;
  opacity: 0;
  position: absolute;
  z-index: 1000;
  margin-top: 5px;
  border-radius: 10px;
  background-color: #141616
}

.popover-wrapper.open .example-popover-2 {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

.popover-wrapper.open .btn-dropdown {
  outline: 0
}

.example-popover-2:before {
  position: absolute;
  top: -16px;
  left: 16px;
  border: 8px solid transparent;
  border-bottom-color: #aab2bd
}

.example-popover-2:after {
  position: absolute;
  top: -14px;
  left: 17px;
  border: 7px solid transparent;
  border-bottom-color: #fff
}

.example-popover-2 .popover-body {
  margin: 0;
  padding: 4px 0;
  max-height: 300px;
  overflow: scroll
}

.example-popover-2 .popover-body.popover-body-padded {
  padding: 8px
}

.example-popover-2 .popover-header .popover-close-btn {
  float: right
}

.popover-wrapper.right .example-popover-2 {
  transform-origin: 275px -10px;
  right: -90px;
  top: 27px
}

.popover-wrapper.right .example-popover-2:before {
  left: auto;
  right: 16px
}

.popover-wrapper.right .example-popover-2:after {
  left: auto;
  right: 17px
}

.example-popover-2 .popover-body {
  overflow: hidden;
  padding: 30px 20px 20px 20px;
  width: 245px;
}

.popover-body-in {
  width: 100%;
  float: left;
  height: 25px;
  line-height: 25px;
}

.popover_t {
  float: left;
  font-size: 15px
}

.popover_money {
  color: #ffa800;
  float: right
}

.popover_point {
  color: #ffa800;
  float: right
}

.popover-body-btn {
  width: 100%;
  float: left;
  margin: 15px 0 0 0
}

.popover-body-btn_in {
  width: 100%;
  float: left;
  margin: 0 0 2px 0
}

.popover_btn {
  width: 100%;
  height: 35px;
  border-radius: 5px;
  background: #363636;
  text-align: center;
  line-height: 35px;
  display: inline-block;
  color: #e1e1e1;
  font-size: 14px;
}

.popover_btn:hover {
  background: #444444
}

.menu_accordion {list-style:none; padding:0;}
.menu_accordion li .menu_acc_h {position: relative;background:#292f30; line-height:45px;}
.menu_accordion li .menu_acc_h img{height:30px; width:auto}
.menu_acc_c img{height:30px; width:auto}
.menu_accordion span.menu_acc_h {width:100%; height:45px;line-height:45px; display:block ;}
.menu_accordion span.menu_acc_h a{ background:url("../assets/img/ico/accordion-closed.png") center right no-repeat; width:94%; height:45px;line-height:45px; display:inline-block ; text-align:left; margin:0 3% 0 3%; color:#ffffff; }
.menu_acc_b_in li {}
.menu_accordion span.active  a{background:url("../assets/img/ico/accordion-opened.png") center right no-repeat;}
.menu_accordion li div.menu_acc_b {display: none;}
.menu_acc_b_in li {text-align:left; }
</style>
