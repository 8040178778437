<template>
  <div class="m-main" style="min-height: 400px;">
    <div id="contents_wrap">
      <div class="sub_title">
        <span class="arrow_back">
          <router-link to="/main"><img src="@/assets/img/ico/arrow_back.png"></router-link>
        </span>
        로그인
      </div>

      <div class="contents">
        <div class="sub_wrap">
          <table class="login_table">
            <tbody>
            <tr>
              <td style="width: 88%;">
                <input class="input_style01h input_blue" name="login_id" value="" maxlength="40" placeholder="아이디" style="width: 96%;" v-model="signInForm.userId">
              </td>
              <td><a @click="alert('고객센터에 문의하세요')"><img src="@/assets/img/ico/input_mark.png" width="auto" height="42px"></a></td>
            </tr>
            <tr></tr>
            </tbody>
          </table>
          <table cellspacing="0" cellpadding="0" class="login_table">
            <tbody>
              <tr>
                <td style="width: 88%;"><input class="input_style01h input_red" type="password" name="login_pw" value="" maxlength="40" placeholder="비밀번호" style="width: 96%;" v-model="signInForm.userPw"></td>
                <td><a @click="alert('고객센터에 문의하세요')"><img src="@/assets/img/ico/input_mark.png" width="auto" height="42px"></a></td>
              </tr>
              <tr>
              </tr>
            </tbody>
          </table>
          <table cellspacing="0" cellpadding="0" style="padding:20px 0 0 0; width: 100%; margin-top: 30px;">
            <tbody>
            <tr>
              <td style="width: 100%;">
                <a v-if="!proceeding" @click="doLogin"><span class="btn3t">로그인</span></a>
                <a v-else><span class="btn3t">로그인</span></a>
              </td>
            </tr><tr>
            </tr></tbody></table>
          <table cellspacing="0" cellpadding="0" style="padding:20px 0 0 0; width: 100%; margin-top: 30px;" width="100%">
            <tbody><tr>
              <td style="width: 100%; text-align: center;">아직 회원이 아니시라면 회원가입 후 이용해 주시기 바랍니다.</td>
            </tr><tr>
            </tr></tbody></table>
          <table cellspacing="0" cellpadding="0" style="padding:20px 0 0 0; width: 100%; margin-top: 30px;" width="100%">
            <tbody><tr>
              <td width="100%" align="center"><router-link to="/join" class="fade_1_open"><span class="btn3i">회원가입</span></router-link></td>
            </tr><tr>
            </tr></tbody></table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MSG from "@/contants/msg";
import { mapState } from 'vuex';

export default {
  name: "Login",
  data: () => ({
    login_popup: false,
    chatScript: null,
    signInForm: {
      userId: '',
      userPw: '',
      sitename: 'vikbet',
      version: null,
    },
    proceeding: false
  }),
  computed: {
    ...mapState({
      version: state => state.version.version,
    }),
  },
  methods: {
    movePage: function(uri){
      this.$router.push({ path: `/${uri}`})
    },
    doLogin: function() {
      this.proceeding=true
      if(!this.signInForm.userId) return this.$store.dispatch('SHOW_ERR_MSG', MSG.error.emptyId);
      if(!this.signInForm.userPw) return this.$store.dispatch('SHOW_ERR_MSG', MSG.error.emptyPassword);
      this.signInForm.version = this.version
      this.$store.dispatch('LOGIN', this.signInForm)
          .then((response) => {
            if (response.status === 200) {
              // 로그인 성공시 처리
              this.$store.dispatch('SHOW_ERR_MSG', MSG.success.welcome);
              this.$router.push({ path: '/main'})
              this.proceeding=false
            }
          })
          .catch((error) => {
            // 로그인 실패시 처리
            this.$store.dispatch('SHOW_ERR_MSG', error.response.data.error);
            // alert(error.response.data.error);
            this.proceeding=false
          })
    },
  },
}
</script>

<style scoped>
/*-------------------------------------------------------------------------------------*
 *  Contents                                                                           *
 *-------------------------------------------------------------------------------------*/
#contents_wrap {width:100%;position:relative;}
.contents {padding:5px; margin:0px auto}
.main_con {width:90%;margin:0 0 2% 0; float:left; padding:0 5% 0 5%}
.main_con_left, .main_con_right{width:46%; float:left; margin:2%}
.main_con_left img, .main_con_right img{width:100%; max-width:100%; height:auto}
.sub_wrap {width:100%;  float:left; padding:0 0 30px 0; clear:both}
.sub_wrap2 {width:100%; display:block; padding:0 0 30px 0}
/* visual */
.main_visual {width:930px; float:left;}
.jssorl-004-double-tail-spin img {animation-name: jssorl-004-double-tail-spin;animation-duration: 1.2s;animation-iteration-count: infinite;animation-timing-function: linear;}
@keyframes jssorl-004-double-tail-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
.jssorb051 .i {position:absolute;cursor:pointer;}
.jssorb051 .i .b {fill:#fff;fill-opacity:0.8;stroke:#333;stroke-width:400;stroke-miterlimit:10;stroke-opacity:0.5;}
.jssorb051 .i:hover .b {fill-opacity:.7;}
.jssorb051 .iav .b {fill:orange;}
.jssorb051 .i.idn {opacity:.3;}
.jssora051 {display:block;position:absolute;cursor:pointer;}
.jssora051 .a {fill:none;stroke:#fff;stroke-width:360;stroke-miterlimit:10;}
.jssora051:hover {opacity:.8;}
.jssora051.jssora051dn {opacity:.5;}
.jssora051.jssora051ds {opacity:.3;pointer-events:none;}

.sub_title {width:100%;margin:0 0 0 0; height:55px; line-height:55px; text-align:center; font-size:1.6em; color:#ffffff; background:#151818; font-weight:bold; position:relative;}
.arrow_back {position:absolute; left:5px; top:0}
.arrow_back img{height:25px; width:auto}

/* 로딩,로그인 */
.intro_wrap {width:100%; height:100%; overflow:hidden}
.intro_wrap .intro_logo{ width:100%; padding:35% 0 10% 0; float:left;}
.intro_wrap .intro_logo h1 {text-align:center;}
.intro_wrap .intro_logo img {height:75px; width:auto}
.spinner { position:absolute; top:50%; left:44%; }
.box { width:100%; height:100vh; position:relative}
.login_box {width:300px; padding:0 0 50px 0; margin:0px auto}
.login_logo{ width:100%; padding:15% 0 10% 0; float:left;}
.login_logo h1{text-align:center;}
.login_logo img{height:75px; width:auto;}


/* 로그인, 회원가입 */
.join_table {width:100%; padding:8px 0 8px 0}
.ps_5 {padding:5px 0 0 0}
.login_table {width:100%; padding:8px 0 8px 0; margin-top: 15px;}

/* input */
input { font-family: Nanum Gothic; color:#ffffff}
input:not([type='checkbox,radio']):focus::-webkit-input-placeholder {color: transparent;} /* 크롬에서 placeholder 사라지게 */
input:not([type='checkbox,radio']):focus::-moz-input-placeholder {color: transparent;} /* 크롬에서 placeholder 사라지게 */
input:not([type='checkbox,radio']):focus::-o-input-placeholder {color: transparent;} /* 크롬에서 placeholder 사라지게 */
textarea:not([type='checkbox,radio']):focus::-webkit-input-placeholder {color: transparent;} /* 크롬에서 placeholder 사라지게 */

input:focus {outline:none}
select:focus {outline:none}
.input_style01h {border:solid 1px #505455; background:none; height:40px; line-height:25px; padding:0 0 0 10px; color:#6b7f7f; width:96%}
.select_style01 {border:solid 1px #505455; height:40px; line-height:25px; padding:0 0 0 10px; color:#6b7f7f; width:100%; background:url(/design/images/select___.png) 95% 50% no-repeat ; background-size:7% auto }
.select_style02 {border:solid 1px #505455; height:40px; line-height:25px; padding:0 0 0 10px; color:#6b7f7f; width:100%; background:url(/design/images/select___.png) 97% 50% no-repeat ; background-size:5% auto }
.select_style03 {border:solid 1px #505455; height:40px; line-height:25px; padding:0 0 0 10px; color:#6b7f7f; width:100%; background:url(/design/images/select___.png) 95% 50% no-repeat ; background-size:12% auto }
.input_style02 { background:#000000; border:none;height:45px; line-height:17px; padding:0 0 0 10px; color:#6b7f7f; width:100%}
.input_style03 { background:#000000; border:none;height:24px; line-height:12px; padding:0 0 0 10px; color:#6b7f7f; width:100%}
.input_style04 { background:#000000; border:none;height:30px; line-height:17px; padding:0 0 0 10px; color:#6b7f7f; width:100%}
.input_blue:focus {border:solid 1px orange;  background:none; height:40px; line-height:20px; padding:0 0 0 10px; color:#ffffff; outline:none;width:100%}
.input_red:focus {border:solid 1px orange;  background:none; height:40px; line-height:20px; padding:0 0 0 10px; color:#ffffff; outline:none;width:100%}

.btn3t {
  width: 100%;
  height: 40px;
  display: inline-block;
  background: orange;
  text-align: center;
  color: #ffffff;
  line-height: 40px;
  border-radius: 5px;
  font-weight: bold;
}

.btn3i {
  width: 100%;
  height: 40px;
  display: inline-block;
  background: #363e3f;
  text-align: center;
  color: #ffffff;
  line-height: 40px;
  border-radius: 5px;
  font-weight: bold;
}
</style>
