<template>
    <tbody>
      <tr v-if="originList">
        <td colspan="10">
          <table class="ng-isolate-scope" style="width: 100%">
            <tbody>
              <tr>
                <td style="width: 100%">
                  <div class="ng-scope"
                     :class="{
                    'soccer_wrap': game.sportId === 6046,
                    'hockey_wrap': game.sportId === 35232,
                    'basketball_wrap': game.sportId === 48242,
                    'tennis_wrap': game.sportId === 54094,
                    'volleyball_wrap': game.sportId === 154830,
                    'football_wrap': game.sportId === 131506,
                    'baseball_wrap': game.sportId === 154914,
                    'e_sport_wrap': game.sportId === 687890,
                   }"
                  >
                      <div class="ng-scope-gamebox">
                            <div class="game_title">
                                <p>{{ game.league | leagueName }}</p>
                                <span>{{ game | currentPeriodLsportInplay('period') }}</span>
                            </div>

                            <div class="game_score_newbox">
                                <div>
                                    <p>{{ game.homeTeam | teamName }}</p>
                                    <p>{{ game.awayTeam | teamName }}</p>
                                </div>
                                <table>
                                    <thead>
                                        <tr>
                                            <template v-if="game.sportId == 154914">
                                              <template v-for="l in Number(9)">
                                                <th :key="l+'titlePeriod'+game.id">
                                                  {{ l | periodDetailLsport(game.sportId) }}
                                                </th>
                                              </template>
                                            </template>
                                            <template v-else-if="game.sportId == 48242 || game.sportId == 131506">
                                               <template v-for="l in Number(4)">
                                                <th :key="l+'titlePeriod'+game.id">
                                                  {{ l | periodDetailLsport(game.sportId) }}
                                                </th>
                                              </template>
                                            </template>
                                            <template v-else-if="game.sportId == 6046">
                                               <template v-for="l in Number(2)">
                                                <th :key="l+'titlePeriod'+game.id">
                                                  {{ l | periodDetailLsport(game.sportId) }}
                                                </th>
                                              </template>
                                            </template>
                                            <template v-else-if="game.sportId == 35232">
                                               <template v-for="l in Number(3)">
                                                <th :key="l+'titlePeriod'+game.id">
                                                  {{ l | periodDetailLsport(game.sportId) }}
                                                </th>
                                              </template>
                                            </template>
                                            <template v-else-if="game.sportId == 154830">
                                               <template v-for="l in Number(5)">
                                                <th :key="l+'titlePeriod'+game.id">
                                                  {{ l | periodDetailLsport(game.sportId) }}
                                                </th>
                                              </template>
                                            </template>
                                            <template v-else>
                                               <template v-for="l in Number(game.currentPeriod)">
                                                <th :key="l+'titlePeriod'+game.id">
                                                  {{ l | periodDetailLsport(game.sportId) }}
                                                </th>
                                              </template>
                                            </template>
                                            <th>점수</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <template v-if="game.sportId == 154914">
                                              <template v-for="l in Number(9)">
                                                <td :key="l+'titlePeriod'+game.id">
                                                  {{ game[`set${l}Score`] ? game[`set${l}Score`].split(':')[0] : '' }}
                                                </td>
                                              </template>
                                            </template>
                                            <template v-else-if="game.sportId == 48242">
                                               <template v-for="l in Number(4)">
                                                <td :key="l+'titlePeriod'+game.id">
                                                  {{ game[`set${l}Score`] ? game[`set${l}Score`].split(':')[0] : '' }}
                                                </td>
                                              </template>
                                            </template>
                                            <template v-else-if="game.sportId == 6046">
                                               <template v-for="l in Number(2)">
                                                <td :key="l+'titlePeriod'+game.id">
                                                  {{ game[`set${l}Score`] ? game[`set${l}Score`].split(':')[0] : '' }}
                                                </td>
                                              </template>
                                            </template>
                                            <template v-else>
                                               <template v-for="l in Number(game.currentPeriod)">
                                                <td :key="l+'titlePeriod'+game.id">
                                                  {{ game[`set${l}Score`] ? game[`set${l}Score`].split(':')[0] : '' }}
                                                </td>
                                              </template>
                                            </template>
                                            <td>{{ game.homeResult }}</td>
                                        </tr>
                                        <tr>
                                            <template v-if="game.sportId == 154914">
                                              <template v-for="l in Number(9)">
                                                <td :key="l+'titlePeriod'+game.id">
                                                  {{ game[`set${l}Score`] ? game[`set${l}Score`].split(':')[1] : '' }}
                                                </td>
                                              </template>
                                            </template>
                                            <template v-else-if="game.sportId == 48242">
                                               <template v-for="l in Number(4)">
                                                <td :key="l+'titlePeriod'+game.id">
                                                  {{ game[`set${l}Score`] ? game[`set${l}Score`].split(':')[1] : '' }}
                                                </td>
                                              </template>
                                            </template>
                                            <template v-else-if="game.sportId == 6046">
                                               <template v-for="l in Number(2)">
                                                <td :key="l+'titlePeriod'+game.id">
                                                  {{ game[`set${l}Score`] ? game[`set${l}Score`].split(':')[1] : '' }}
                                                </td>
                                              </template>
                                            </template>
                                            <template v-else>
                                               <template v-for="l in Number(game.currentPeriod)">
                                                <td :key="l+'titlePeriod'+game.id">
                                                  {{ game[`set${l}Score`] ? game[`set${l}Score`].split(':')[1] : '' }}
                                                </td>
                                              </template>
                                            </template>
                                            <td>{{ game.awayResult }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <p class="ng-scope-gamebox-time">{{ game.subResult }}</p>
                        </div>
                    </div>
                </td>
              </tr>
            </tbody>
          </table>
          <template v-if="game.status === '종료' || game.isVisible === 'n'  || game.isSuspended === 'y' || !originList.find(e => e.isVisible === 'y') || checkBlockGame(game)">
              경기정보
            <div class="live_wrap">
              <div class="ng-scope" style="">
                <table class="bet_table_2" style="height:400px; width:100%; font-weight: 700; font-size:15px;">
                  <tbody>
                  <tr>
                    <td style="color: white;"> 현재 가능한 베팅이 없습니다.<br><br><br> 베팅이 가능한 항목은 화면에 자동으로 나타납니다.</td>
                  </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td style="text-align: center; color: #b7b7b7;">
                        인플레이 베팅에 표시되는 시간 표시는 지표 역할을 합니다. 회사는 점수 또는 시간과 같이 표시되는 정보의 정확성 및 최신성에 대해 책임을 지지 않습니다.
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </template>
          <template v-else>
            <div style="padding:5px 0">
              <table class="sports_in_tab" cellpadding="0" cellspacing="0">
                <tbody>
                  <tr>
                    <td>
                    <span class="sports_in_tab_off_1" @click="selectMarket('STAR')">
                      <img src="@/assets/img/ico/favor_on.png"> <span
                        class="live_tab_count">{{ stared.filter(e => e.gameId === game.id).length }}</span>
                    </span>
                    </td>
                    <td>
                      <a @click="selectMarket(null)">
                      <span class="sports_in_tab_off_2" :class="{'sports_in_tab_on_2': selectedMarket === null}">
                        전체 <span class="live_tab_count">{{ originList.filter(e => e.isVisible === 'y').length }}</span>
                      </span>
                      </a>
                    </td>
                    <td>
                      <a @click="selectMarket('승무패')">
                      <span class="sports_in_tab_off_2" :class="{'sports_in_tab_on_2': selectedMarket === '승무패'}">
                        승무패 <span class="live_tab_count">{{
                          originList.filter(e => (e.market.nameKr?.indexOf('승무패') >= 0 || e.market.nameKr?.indexOf('승패') >= 0) && e.isVisible === 'y').length
                        }}</span>
                      </span>
                      </a>
                    </td>
                    <td>
                      <a @click="selectMarket('오버언더')">
                      <span class="sports_in_tab_off_2" :class="{'sports_in_tab_on_2': selectedMarket === '오버언더'}">
                        오버/언더 <span class="live_tab_count">{{
                          originList.filter(e => e.market.nameKr?.indexOf('오버언더') >= 0 && e.isVisible === 'y').length
                        }}</span>
                      </span>
                      </a>
                    </td>
                    <td>
                      <a @click="selectMarket('핸디캡')">
                      <span class="sports_in_tab_off_2" :class="{'sports_in_tab_on_2': selectedMarket === '핸디캡'}">
                        핸디캡 <span class="live_tab_count">{{
                          originList.filter(e => e.market.nameKr?.indexOf('핸디캡') >= 0 && e.isVisible === 'y').length
                        }}</span>
                      </span>
                      </a>
                    </td>
                    <td>
                      <a @click="selectMarket('ETC')">
                      <span class="sports_in_tab_off_2" :class="{'sports_in_tab_on_2': selectedMarket === 'ETC'}">
                        기타  <span class="live_tab_count">{{
                          originList.filter(e =>
                              (e.market.nameKr?.indexOf('핸디캡') === -1 &&
                                  e.market.nameKr?.indexOf('승패') === -1 &&
                                  e.market.nameKr?.indexOf('승패') === -1 &&
                                  e.market.nameKr?.indexOf('승무패') === -1 &&
                                  e.market.nameKr?.indexOf('오버언더') === -1) && e.isVisible === 'y'
                          ).length
                        }}</span>
                      </span>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <template v-if="!selectedMarket">
                <template v-for="(row, index) in marketSort(originList)">
                  <template>
                    <div class="accordion-header_2" :key="'europeanFoldersDetail'+row.seq+index"
                         v-if="originList.filter(e => String(e.marketId) === String(row.id))?.find(f => f.isSuspended === 'n')"
                    >
                      <span>
                        <a href="#" class="left_menu_in_1_l_1">
                          <a href="#" class="left_menu_in_1_l_1">
                            <img v-if="stared.find(e => e.gameId === game.id && e.seq === row.seq && e.periodSequence === '0')"
                                 @click="addStartedMarket(row.seq, '0')" src="@/assets/img/ico/favor_on.png">
                            <img v-else @click="addStartedMarket(row.seq, '0')" src="@/assets/img/ico/favor_off.png">
                          </a>
                        </a>
                      </span>
                      <span style="margin-left:10px;">
                        <b>
                            {{ row | marketNameLsport(game) }}
                        </b>
                      </span>
                      <span style="float: right; width:20px;" @click="folderClosed(row.seq, '0')"
                            v-if="!closed.find(e => e.seq === row.seq && e.period === '0')">
                        <img src="@/assets/img/ico/accordion-opened.png" alt="">
                      </span>
                      <span style="float: right; width:20px;" @click="folderClosed(row.seq, '0')" v-else>
                        <img src="@/assets/img/ico/accordion-closed.png" alt="">
                      </span>
                    </div>
                    <div v-if="!closed.find(e => e.seq === row.seq && e.period === '0')" :key="'europeanFoldersDetailOdds'+row.seq+index">
                      <table class="nav-tabs" cellpadding="0" cellspacing="0" style="width:100%; margin: 0;">
                        <tbody>
                        <template v-for="(ro2, index2) in originList.filter(e => String(e.marketId) === String(row.id))">
                          <template v-if="ro2.bets.length < 4">
                            <template v-if="row.nameKr?.indexOf('정확한') >= 0">
                              <template v-for="l in arrLenDiv(ro2.bets)">
                                <tr v-if="ro2.isSuspended === 'n'"
                                    :key="'CorrectScore'+l+ro2.seq">
                                  <!--<template v-if="ro2.isSuspended === 'y'">-->
                                  <!--  <template v-for="bet in ro2.bets">-->
                                  <!--    <DetailCorrectScoreLock :key="'betDetail'+bet.id" :bets="bet">-->
                                  <!--    </DetailCorrectScoreLock>-->
                                  <!--  </template>-->
                                  <!--</template>-->
                                  <template>
                                    <template v-for="bet in ro2.bets.slice((l - 1) * 4, l * 4)">
                                      <template v-if="bet.isVisible === 'y'">
                                        <DetailCorrectScorePrice
                                            :key="'betDetail'+bet.id"
                                            :bets="bet"
                                            :folder="ro2"
                                            :add-cart="addCart"
                                            :check-cart="checkCart"
                                            :game="game"
                                        >
                                        </DetailCorrectScorePrice>
                                      </template>
                                      <template v-else>
                                        <DetailCorrectScoreLock :key="'betDetail'+bet.id" :bets="bet">
                                        </DetailCorrectScoreLock>
                                      </template>
                                    </template>
                                  </template>
                                </tr>
                              </template>
                            </template>
                            <template>
                              <tr :key="'europeanfolders2'+index2" v-if="ro2.isSuspended === 'n'">
                                <template>
                                  <DetailPrice
                                      :bets="ro2.bets.find(e => e.name === '1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name ==='Home' || e.name === 'Yes')"
                                      :folder="ro2"
                                      :add-cart="addCart"
                                      :check-cart="checkCart"
                                      :game="game"
                                      :team="game.homeTeam"
                                      :width="ro2.bets.length"
                                  ></DetailPrice>
                                  <DetailPrice
                                      v-if="ro2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'Away Goal')"
                                      :bets="ro2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'Away Goal')"
                                      :folder="ro2"
                                      :add-cart="addCart"
                                      :check-cart="checkCart"
                                      :game="game"
                                      :team="game.homeTeam"
                                      :width="ro2.bets.length"
                                  ></DetailPrice>
                                  <DetailPrice
                                      :bets="ro2.bets.find(e => e.name === '2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even'|| e.name ==='Away' || e.name === 'No')"
                                      :folder="ro2"
                                      :add-cart="addCart"
                                      :check-cart="checkCart"
                                      :game="game"
                                      :team="game.awayTeam"
                                      :width="ro2.bets.length"
                                  ></DetailPrice>
                                </template>
                              </tr>
                            </template>
                          </template>
                          <template v-else>
                            <template v-for="l in arrLenDiv(ro2.bets)">
                              <tr v-if="ro2.isSuspended === 'n'" :key="'CorrectScore'+l+ro2.seq">
                                <template v-for="bet in ro2.bets.slice((l - 1) * 4, l * 4)">
                                  <template v-if="bet.status !== 'opened'">
                                    <DetailCorrectScoreLock :key="'betDetail'+bet.id" :bets="bet">
                                    </DetailCorrectScoreLock>
                                  </template>
                                  <template v-else>
                                    <DetailCorrectScorePrice
                                        :key="'betDetail'+bet.id"
                                        :bets="bet"
                                        :folder="ro2"
                                        :add-cart="addCart"
                                        :check-cart="checkCart"
                                        :game="game"
                                    >
                                    </DetailCorrectScorePrice>
                                  </template>
                                </template>

                              </tr>
                            </template>
                          </template>
                        </template>
                        </tbody>
                      </table>
                    </div>
                  </template>
                </template>
              </template>
              <template v-else>
                <template v-for="(row, index) in marketSort(lists)">
                  <template>
                    <div
                        class="accordion-header_2"
                        :key="'europeanFoldersDetail'+row.seq+index"
                        v-if="lists.filter(e => String(e.marketId) === String(row.id))?.find(f => f.isVisible === 'y')"
                    >
                      <span>
                        <a href="#" class="left_menu_in_1_l_1">
                          <img v-if="stared.find(e => e.gameId === game.id && e.seq === row.seq && e.periodSequence === '0')"
                               @click="addStartedMarket(row.seq, '0')" src="@/assets/img/ico/favor_on.png">
                          <img v-else @click="addStartedMarket(row.seq, '0')" src="@/assets/img/ico/favor_off.png">
                        </a>
                      </span>
                      <span style="margin-left:10px;">
                        <b>
                            {{ row | marketNameLsport(game) }}
                        </b>
                      </span>
                      <span style="float: right; width:20px;" @click="folderClosed(row.seq, '0')" v-if="!closed.find(e => e.seq === row.seq && e.period === '0')">
                          <img src="@/assets/img/ico/accordion-opened.png" alt="">
                        </span>
                      <span style="float: right; width:20px;" @click="folderClosed(row.seq, '0')" v-else>
                          <img src="@/assets/img/ico/accordion-closed.png" alt="">
                      </span>
                    </div>
                    <div v-if="!closed.find(e => e.seq === row.seq && e.period === '0')" :key="'europeanFoldersDetailOdds'+row.seq+index">
                      <table class="nav-tabs" cellpadding="0" cellspacing="0" style="width:100%; margin: 0;">
                        <tbody>
                        <template v-for="(ro2, index2) in lists.filter(e => String(e.marketId) === String(row.id))">
                          <template v-if="ro2.bets.length < 4">
                            <template v-if="row.nameKr?.indexOf('정확한') >= 0">
                              <template v-for="l in arrLenDiv(ro2.bets)">
                                <tr v-if="ro2.isSuspended === 'n'" :key="'CorrectScore'+l+ro2.seq">
                                  <!--<template v-if="ro2.isSuspended === 'y'">-->
                                  <!--  <template v-for="bet in ro2.bets.slice((l - 1) * 4, l * 4)">-->
                                  <!--    <DetailCorrectScoreLock :key="'betDetail'+bet.id" :bets="bet">-->
                                  <!--    </DetailCorrectScoreLock>-->
                                  <!--  </template>-->
                                  <!--</template>-->
                                  <template>
                                    <template v-for="bet in ro2.bets.slice((l - 1) * 4, l * 4)">
                                      <template v-if="bet.isVisible === 'y'">
                                        <DetailCorrectScorePrice
                                            :key="'betDetail'+bet.id"
                                            :bets="bet"
                                            :folder="ro2"
                                            :add-cart="addCart"
                                            :check-cart="checkCart"
                                            :game="game"
                                        >
                                        </DetailCorrectScorePrice>
                                      </template>
                                      <template v-else>
                                        <DetailCorrectScoreLock :key="'betDetail'+bet.id" :bets="bet">
                                        </DetailCorrectScoreLock>
                                      </template>
                                    </template>
                                  </template>
                                </tr>
                              </template>
                            </template>
                            <template v-else>
                              <tr :key="'europeanfolders2'+index2" v-if="ro2.isSuspended === 'n'">
                                <!--<template v-if="ro2.isSuspended === 'y'">-->
                                <!--  <DetailLock-->
                                <!--      :bets="ro2.bets.find(e => e.name === '1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name ==='Home')"-->
                                <!--      :folder="ro2"-->
                                <!--      :add-cart="addCart"-->
                                <!--      :check-cart="checkCart"-->
                                <!--      :game="game"-->
                                <!--      :team="game.homeTeam"-->
                                <!--      :width="ro2.bets.length"-->
                                <!--  ></DetailLock>-->
                                <!--  <DetailLock-->
                                <!--      v-if="ro2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'Away Goal')"-->
                                <!--      :bets="ro2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'Away Goal')"-->
                                <!--      :folder="ro2"-->
                                <!--      :add-cart="addCart"-->
                                <!--      :check-cart="checkCart"-->
                                <!--      :game="game"-->
                                <!--      :team="game.homeTeam"-->
                                <!--      :width="ro2.bets.length"-->
                                <!--  ></DetailLock>-->
                                <!--  <DetailLock-->
                                <!--      :bets="ro2.bets.find(e => e.name === '2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name ==='Away')"-->
                                <!--      :folder="ro2"-->
                                <!--      :add-cart="addCart"-->
                                <!--      :check-cart="checkCart"-->
                                <!--      :game="game"-->
                                <!--      :team="game.awayTeam"-->
                                <!--      :width="ro2.bets.length"-->
                                <!--  ></DetailLock>-->
                                <!--</template>-->
                                <template>
                                  <DetailPrice
                                      :bets="ro2.bets.find(e => e.name === '1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name ==='Home' || e.name === 'Yes')"
                                      :folder="ro2"
                                      :add-cart="addCart"
                                      :check-cart="checkCart"
                                      :game="game"
                                      :team="game.homeTeam"
                                      :width="ro2.bets.length"
                                  ></DetailPrice>
                                  <DetailPrice
                                      v-if="ro2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'Away Goal')"
                                      :bets="ro2.bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'Away Goal')"
                                      :folder="ro2"
                                      :add-cart="addCart"
                                      :check-cart="checkCart"
                                      :game="game"
                                      :team="game.homeTeam"
                                      :width="ro2.bets.length"
                                  ></DetailPrice>
                                  <DetailPrice
                                      :bets="ro2.bets.find(e => e.name === '2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even'|| e.name ==='Away' || e.name === 'No')"
                                      :folder="ro2"
                                      :add-cart="addCart"
                                      :check-cart="checkCart"
                                      :game="game"
                                      :team="game.awayTeam"
                                      :width="ro2.bets.length"
                                  ></DetailPrice>
                                </template>
                              </tr>
                            </template>
                          </template>
                          <template v-else>
                            <template v-for="l in arrLenDiv(ro2.bets)">
                              <tr v-if="ro2.isVisible === 'y'" :key="'CorrectScore'+l+ro2.seq">
                                <template v-for="bet in ro2.bets.slice((l - 1) * 4, l * 4)">
                                  <template v-if="bet.status !== 'opened'">
                                    <DetailCorrectScoreLock :key="'betDetail'+bet.id" :bets="bet">
                                    </DetailCorrectScoreLock>
                                  </template>
                                  <template v-else>
                                    <DetailCorrectScorePrice
                                        :key="'betDetail'+bet.id"
                                        :bets="bet"
                                        :folder="ro2"
                                        :add-cart="addCart"
                                        :check-cart="checkCart"
                                        :game="game"
                                    >
                                    </DetailCorrectScorePrice>
                                  </template>
                                </template>

                              </tr>
                            </template>
                          </template>
                        </template>
                        </tbody>
                      </table>
                    </div>
                  </template>
                </template>
              </template>
            </div>
          </template>
        </td>
      </tr>
      <tr v-else>
        <td colspan="10" style="text-align: center;">
          <Loading></Loading>
        </td>
      </tr>
    </tbody>
</template>

<script>
import Loading from '@/components/Loading';
import DetailPrice from './DetailPrice'
//import DetailLock from './DetailLock'
import DetailCorrectScoreLock from './DetailCorrectScoreLock'
import DetailCorrectScorePrice from './DetailCorrectScorePrice'
import {mapState} from "vuex";
import { io } from 'socket.io-client';

export default {
  name: "InpSelectedGame",
  components: {
    DetailPrice,
    //DetailLock,
    DetailCorrectScoreLock,
    DetailCorrectScorePrice,
    Loading,
  },
  props: ['game', 'addCart', 'checkCart', 'checkBlockGame', 'blockTypeSetting'],
  data: function () {
    return {
      // game: null,
      originList: null,
      lists: null,
      isLoading: false,
      selectedMarket: null,
      closed: [],
      timeCheck: true,
      scoreCheck: true,
      lsport_socket: null,
    }
  },
  created(){
    this.lsport_socket = io(`https://lsports.j6rgtq98rh95vq5wbqsrdacx3.xyz`);
  },
  mounted() {
  console.log(this.game, 'renewal')
    this.lists = this.originList;
    this.initFolder();
    this.interval = setInterval(this.initFolder, 5000);
    this.lsport_socket.on('gameUpdate', this.gameUpdate)
    this.lsport_socket.on('scoreUpdate', this.scoreUpdate)
    this.lsport_socket.on('oddsUpdate', this.oddsUpdate)
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  computed: {
    ...mapState({
      stared: state => state.inplay.inplayStared,
      user: state => state.user.user,
    }),
  },
  watch: {
    selectedMarket: function (val) {
      if (!val) return this.lists = this.originList;
      if (val === '승무패' || val === '핸디캡' || val === '오버언더') {
        return this.lists = this.originList.filter(e => e.market.nameKr?.indexOf(val) >= 0);
      }

      if (val === 'ETC') {
        return this.lists = this.originList.filter(e => {
          if (this.game.sportSeq === 210) return [11573, 11574, 11923, 12930, 11684, 11685, 11567, 11572, 11568, 11727, 13619, 11732].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 211) return [11652, 11653, 11654, 11658, 11659, 11656, 11717, 11718].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 3) return [11536, 16637, 12530, 12531, 12566, 12567, 12568, 13558, 14049].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 214) return [11522, 11540, 11543, 11675, 12105].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 215) return [11633, 11630, 11627].indexOf(e.marketSeq) >= 0
          if (this.game.sportId === 11) return [13102, 13101].indexOf(e.marketSeq) >= 0
          if (this.game.sportSeq === 267) return [12646, 12648, 12653, 12812, 12827, 12777, 12788, 12799, 12813, 12828, 12645, 12647, 12652, 12810, 12825, 12780, 12791, 12802, 12816, 12831, 12778, 12789, 12800, 12814, 12829, 12782, 12793, 12804, 12818, 12833, 12783, 12794, 12805, 12819, 12834, 12784, 12795, 12806, 12820, 12835].indexOf(e.marketSeq) >= 0
        });
      }


      if (val === 'STAR') {
        this.lists = [];
        this.stared.map(s => {
          const lists = this.originList.filter(l => String(s.gameId) === String(this.game.id) && String(s.seq) === String(l.marketSeq) && String(s.periodSequence) === String(l.periodSequence))
          lists.map(folder => this.lists.push(folder));
        })

        return;
      }

      this.lists = this.originList.filter(e => e.market.nameKr?.indexOf(val) >= 0);
    },
    game:{
      handler: function(val, newVal){
        //경기 시간 변동체크
        if (val && newVal && val.id !== newVal.id) this.lists = this.originList;
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    initFolder: function () {
      const payload = {
        gameId: this.game.id
      }
      return this.$store.dispatch('GET_LSPORT_INPLAY_DETAIL', { payload })
          .then(data => {
            const result = data.data;
            if (result.success) {
              this.game = result.payload.game;
              if (!result.payload.game) this.$router.push({ name: '스포츠-인플레이(엘스포츠)'});
              this.originList = result.payload.list;
              this.lists = this.originList;
              this.isLoading = true;
              this.selectMarket(this.selectedMarket)
            } else {
              console.log('인플레이 경기요청 에러', result)
              this.$store.dispatch('SHOW_ERR_MSG3', result.msg);
            }
          }).catch(err => {
            this.$store.dispatch('SHOW_ERR_MSG', err.response.data.error);
          })
    },
    /**
     * 토글 액션
     * */
    marketSort: function (folders) {
      const markets = folders.map(e => e.market);
      const arrUnique = markets.filter((character, idx, arr) => {
        return arr.findIndex((item) => item.id === character.id) === idx
      });
      arrUnique.sort()
      return arrUnique
    },
    toggleDetail: function (id) {
      this.toggle(this.openDetailBet, id)
    },
    removeAtIndex: function (arr, index) {
      this.openDetailBet.splice(index, 1);
    },
    toggle: function (arr, item, getValue = item => item) {
      const index = arr.findIndex(i => getValue(i) === getValue(item));
      if (index === -1) {
        this.openDetailBet = [...arr, item]
        return;
      }

      return this.removeAtIndex(arr, index);
    },
    uniqPeriod: function (marketId) {
      const periods = this.originList.filter(e => String(e.marketId) === String(marketId)).map(e => e.periodSequence);
      let set = new Set(periods);
      const uniqueArr = [...set];
      uniqueArr.sort()
      return uniqueArr
    },
    arrLenDiv: function (arr, div = 4) {
      return Math.floor(arr.length / div) + 1
    },
    // 스코어 업데이트
    scoreUpdate: function (data) {
      const result = JSON.parse(data);
      for (const game of result) {
        if (String(game.id) === String(this.game.id)) {
          this.game.homeResult = game.homeResult;
          this.game.awayResult = game.awayResult;
          if (game.set1Score) this.game.set1Score = game.set1Score;
          if (game.set2Score) this.game.set2Score = game.set2Score;
          if (game.set3Score) this.game.set3Score = game.set3Score;
          if (game.set4Score) this.game.set4Score = game.set4Score;
          if (game.set5Score) this.game.set5Score = game.set5Score;
          if (game.set6Score) this.game.set6Score = game.set6Score;
          if (game.set7Score) this.game.set7Score = game.set7Score;
          if (game.set8Score) this.game.set8Score = game.set8Score;
          if (game.set9Score) this.game.set9Score = game.set9Score;
        }
      }
    },
    // 매치 상태 업데이트
    gameUpdate: function (data) {
      const result = JSON.parse(data);
      // console.log(results, 'match')
      for (const game of result) {
        if (String(this.game.id) === String(game.id)) {
          if (game.stauts) this.game.status = '진행';
        }
      }
    },
    addStartedMarket: function (seq, period) {
      const market = {
        gameId: this.game.id,
        seq: seq,
        periodSequence: period,
      }
      this.$store.dispatch('list/inplayStarted', market);
    },
    folderClosed: function (seq, period) {
      const data = {
        seq,
        period
      };
      const index = this.closed.findIndex(e => e.seq === seq && e.period === period)
      if (index >= 0) return this.closed.splice(index, 1);
      this.closed.push(data);
    },
    selectMarket: function (market) {
      this.selectedMarket = market;
    },
    oddsUpdate: function(data){
      const result = JSON.parse(data);
      console.log(result, 'renewalOddsUpdate ')
      for (const folder of result) {
        // console.log(result);
        const dbFolder = this.lists.find((inFolder) => inFolder.id === folder.id);
        if (dbFolder) {
          dbFolder.isSuspended = folder.isSuspended;
          for (const bet of folder.bets) {
            const dbBet = dbFolder.bets.find((inBet) => inBet.id === bet.id);
            if (dbBet) {
              console.log(dbBet, bet, '디테일 배당변경')
              dbBet.price = bet.price;
              dbBet.status = bet.status;
            }
          }
        }
      }
    },
    // 스포츠별 피어리드별 타임 체크
    sportPeriodCheck: function (period) {
      if (this.game.sportSeq === 3 && Number(period) >= 4) return false;
      return true;
    },
  },
}
</script>

<style scoped>
.listTable02 th {
  height: 40px;
}
</style>

