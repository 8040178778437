<template>
  <div class="m-game-wrap">
    <div class="m-game-body"
         v-for="(row, index) in lists"
         v-if="row.status === '진행' || row.status ==='대기'"
         :key="'mobileGameList' + index"
    >
      <template v-if="row.isVisible ==='y'">
        <div class="m-game">
          <div class="day">
            <img v-if="row.sport.icon" :src="row.sport.icon">
            <img style="margin-left: 4px;" v-if="row.location.icon" :src="row.location.icon" alt="">
            <span class="t2">
              {{ row.league | leagueName }}
            </span>
            <span class="t3">{{ row | currentPeriodLsportInplay('period') }}</span>
            <div class="right" v-if="row.foldersCnt.cnt > 0" @click="moveMobileGameDetail(row)">
              <span style="color:#aa7bf5;">[{{ row.league.maxAmount | amtKor }}]</span>
              + {{ row.foldersCnt.cnt }}
            </div>
            <div class="right" v-else>
              <span style="color:#aa7bf5; margin-right: 10px;">[{{ row.league.maxAmount | amtKor }}]</span>
              <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
            </div>
          </div>
          <div class="team">
            <div class="name">
              <span>
                {{ row.homeTeam | teamName }}
              </span>
              <!--     스코어         -->
              <span>
                <strong class="main_color">
                  {{ row.homeResult }}
                </strong>
                vs
                <strong class="main_color">
                  {{ row.awayResult }}
                </strong>
              </span>
              <span>
                {{ row.awayTeam | teamName }}
              </span>
            </div>
          </div>
          <template v-if="row.folders.length === 0 && row.foldersCnt.cnt === 0">
            <div class="choice">
              <dl>
                <!-- 종목 -->
                <dt>
                  <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                </dt>
              </dl>
              <dl>
                <!-- 종목 -->
                <dt>
                  <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                </dt>
              </dl>
              <dl>
                <!-- 종목 -->
                <dt>
                  <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                </dt>
              </dl>
            </div>
          </template>
          <template v-else-if="row.folders.length === 0 && row.foldersCnt.cnt > 0">
            <div class="choice">
              <dl>
                <!-- 종목 -->
                <dt>
                  <font-awesome-icon icon="fs fa-circle-plus"/>
                </dt>
              </dl>
              <dl>
                <!-- 종목 -->
                <dt>
                  <font-awesome-icon icon="fs fa-circle-plus"/>
                </dt>
              </dl>
              <dl>
                <!-- 종목 -->
                <dt>
                  <font-awesome-icon icon="fs fa-circle-plus"/>
                </dt>
              </dl>
            </div>
          </template>
          <template v-else>
            <div class="choice" v-if="row.folders[0].isVisible === 'y' && row.folders[0].isSuspended === 'n' && !row.folders[0].bets.find(e => Number(e.price) === 0)">
              <MPrice
                  :bets="row.folders[0].bets.find(e => e.name === '1' || e.name === 'Over' || e.name === 'Home')"
                  :folder="row.folders[0]"
                  :add-cart="addCart"
                  :check-cart="checkCart"
                  :game="row"
              ></MPrice>
              <MPrice
                  v-if="row.folders[0].bets.find(e => e.name === 'X')"
                  :bets="row.folders[0].bets.find(e => e.name === 'X')"
                  :folder="row.folders[0]"
                  :add-cart="addCart"
                  :check-cart="checkCart"
                  :game="row"
              ></MPrice>
              <MPrice
                  :bets="row.folders[0].bets.find(e => e.name === '2' || e.name === 'Under' ||  e.name === 'Away')"
                  :folder="row.folders[0]"
                  :add-cart="addCart"
                  :check-cart="checkCart"
                  :game="row"
              ></MPrice>
            </div>
            <div class="choice" v-else-if="row.folders[0].isVisible === 'y' && (row.folders[0].isSuspended === 'y' || row.folders[0].bets.find(e => Number(e.price) === 0))">
              <dl>
                <!-- 종목 -->
                <dt>
                  <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                </dt>
              </dl>
              <dl v-if="row.folders[0].bets.find(e => e.name ==='X')">
                <!-- 종목 -->
                <dt>
                  <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                </dt>
              </dl>
              <dl>
                <!-- 종목 -->
                <dt>
                  <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                </dt>
              </dl>
            </div>
            <div class="choice" v-else-if="row.folders[0].isVisible === 'n'">
              <dl>
                <!-- 종목 -->
                <dt>
                  <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                </dt>
              </dl>
              <dl>
                <!-- 종목 -->
                <dt>
                  <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                </dt>
              </dl>
              <dl>
                <!-- 종목 -->
                <dt>
                  <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                </dt>
              </dl>
            </div>
          </template>
        </div>
      </template>
    </div>

  </div>
</template>

<script>
import {mapState} from "vuex";
import MPrice from '@/components/Game/Sports/LsportInplay/MInplayListPrice'
import { io } from 'socket.io-client';

export default {
  name: "MInpList",
  components: {
    // Category,
    MPrice,
  },
  props: ['originList', 'addCart', 'checkCart', 'waitList', 'isLoading', 'selectedGame', 'checkBlockGame', 'blockTypeSetting'],
  data: function(){
    return {
      lsport_socket: null,
      openDetail: [],
    }
  },
  created(){
    this.lsport_socket = io(`https://lsports.j6rgtq98rh95vq5wbqsrdacx3.xyz`);
  },
  computed: {
    lists: function () {
      return this.originList
    },
    ...mapState({
      user: state => state.user.user,
      cartItem: state => state.inplay.cart,
    }),
  },
  mounted: function () {
    this.lsport_socket.on('gameUpdate', this.gameUpdate);
    this.lsport_socket.on('oddsUpdate', this.oddsUpdate);
    this.lsport_socket.on('scoreUpdate', this.scoreUpdate);
  },
  methods: {
    // 매치 상태 변경
    matchStats: function (data) {
      const results = JSON.parse(data);
      for (const r in results) {
        const result = results[r];
        const game = this.lists.find(e => String(e.id) === String(result.EventId))
        if (game) {
          const score = result.Score;
          if (score) {
            game.homeResult = score.split(':')[0] || 0;
            game.awayResult = score.split(':')[1] || 0;
            game.currentPeriod = result.Period;
          }
        }
      }
    },
    // 매치 상태 업데이트
    matchUpdate: function (data) {
      const results = JSON.parse(data);
      for (const r in results) {
        const matchData = results[r];
        // console.log('matchUpdate', matchData);
        const game = this.originList.find(e => String(e.id) === String(matchData.Id));
        if (game) {
          const cartItem = this.cartItem.find(e => String(e.gameId) === String(matchData.Id));
          if (cartItem) {
            cartItem.betsStatus = matchData.IsSuspended || !matchData.IsVisible ? 'y' : 'n';
            cartItem.betsUpdated = true;
          }
          if (matchData.IsStarted) game.status = '진행';
          game.isVisible = matchData.IsVisible ? 'y' : 'n';
          game.isSuspended = matchData.IsSuspended ? 'y' : 'n';
          if (matchData.MatchStatus === 2) {
            game.status = '종료';
            setTimeout(() => {
              this.removeGames(game.seq)
            }, 3000)
          }
        }
      }
    },
    removeGames: function (gameSeq) {
      if (this.lists && this.lists.length > 0) {
        let index = this.lists.indexOf(e => e.seq === gameSeq)
        if (index>=0) {
          this.lists.splice(index, 1)
        }
      }
      // 카트 아이템수정
      if (this.cartItem){
        let index = this.cartItem.indexOf(e => e.seq === gameSeq)
        if (index >= 0) {
          this.$store.state.inplay.cart.splice(index, 1);
        }
      }
    },
    moveMobileGameDetail: function(game){
      this.$router.push({ name: '스포츠-엘스포츠(인플레이)-자세히', query: { gid: game.id }})
    },
    gameUpdate(data){
      const result = JSON.parse(data);
      console.log(result, '경기 업데이트');
      for (const game of result) {
        const dbGame = this.lists.findIndex((listGame) => String(listGame.id) === String(game.id));
        if (dbGame >= 0) {
          if (game.status === '종료') this.lists.splice(dbGame, 1)
          // console.log(dbGame, 'DB경기')
        }
      }
    },
    oddsUpdate(data){
      const result = JSON.parse(data);
      this.$store.dispatch('UPDATE_LSPORT_INPLAY_LIST_ODDS', result);

      if (result.length === 0) return;

      for (const folder of result){
        const dbGame = this.lists.find((listGame) => String(listGame.id) === String(folder.gameId));
        // 일치하는 폴더 정보가있을시
        if (dbGame.folders.length === 0) continue;
        const inListfolder = dbGame.folders.find((gameFolder) => gameFolder.id === folder.id);
        if (inListfolder) {
          inListfolder.isSuspended = folder.isSuspended;
          for (const rmqBet of folder.bets) {
            const inBet = inListfolder.bets.find((inLiBet) => inLiBet.id === rmqBet.id);
            // console.log(inBet, '디비 BET');
            inBet.price = rmqBet.price;
            inBet.Status = rmqBet.status;
          }
        }
      }
      // console.log(result, '배당 업데이트');
    },
    scoreUpdate(data){
      const result = JSON.parse(data);
      console.log(result, '스코어 업데이트');
      this.$store.dispatch('UPDATE_LSPORT_INPLAY_LIST_SCORE', result);

      for (const game of result) {
        const dbGame = this.lists.find((listGame) => String(listGame.id) === String(game.id));
        if (dbGame) {
          dbGame.homeResult = game.homeResult;
          dbGame.awayResult = game.awayResult;
          dbGame.currentPeriod = game.currentPeriod;
          dbGame.currentTime = game.currentTime;


          // console.log(dbGame, 'DB 스코어경기');
        }
      }

    },
  },
}
</script>

<style scoped>
.main_color {
  color: orange;
}
</style>
