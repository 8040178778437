<template>
  <dl :class="{'active': checkCart(bets)}" @click="addCart(bets, folder)"
    v-if="folder.isSuspended === 'n'"
  >
    <dt>
    <!-- <dt v-if="type !== 'draw'"> -->
      {{ teamName }} {{ line }}
    </dt>
    <!-- <dt v-else>
      무
    </dt> -->
    <dd>
      <i v-if="priceStatus === 'up'" class="ico-up blink"></i>
      <i v-if="priceStatus === 'down'" class="ico-down blink"></i>
      <span class="orange--text" style="margin-right: 5px;">{{ price }}</span>
      <span style="font-size: 10px; color: #fff;">
        {{ startPrice }}
      </span>
    </dd>
  </dl>
  <dl v-else>
    <dt>
      {{ teamName }} {{ line }}
    </dt>
    <dd>
      <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
    </dd>
  </dl>
</template>

<script>
export default {
  name: "Price",
  props: ['bets', 'folder', 'addCart', 'checkCart', 'game'],
  data: function(){
    return {
      startPrice: null,
      priceStatus: null,
    }
  },
  computed: {
    price: function(){
      return this.bets.price
    },
    teamName: function(){
      let name = this.bets.name;
      if (this.bets.name === '1') name = `${this.game.homeTeam.nameKr || this.game.homeTeam.nameEn} `;
      if (this.bets.name === '2') name = `${this.game.awayTeam.nameKr || this.game.awayTeam.nameEn} `;
      if (this.bets.name === 'Over') return `오버 ` ;
      if (this.bets.name === 'Under') return `언더 `;
      if (this.bets.name === 'X') name = '무';
      if (this.bets.name === '1X') name = '1팀승 또는 무승부';
      if (this.bets.name === '12') name = '1팀승 또는 2팀승';
      if (this.bets.name === 'X2') name = '무승부 또는 2팀승';
      if (this.bets.name === 'Odd') name = '홀';
      if (this.bets.name === 'Even') name = '짝';
      if (this.bets.name === 'Yes') name = '예';
      if (this.bets.name === 'No') name = '아니오';
      if (this.bets.name === 'No Goal') name = '노골';
    
      return name;
    },
    line: function(){
      let name = this.bets.line ||'';
      if(name) name = `(${this.bets.line.replace(' (0-0)', '')})`;
      return name
    }
  },
  watch: {
    price: function(newVal, val){
      if (Number(newVal) - Number(val) < 0) this.priceStatus = 'down';
      if (Number(newVal) - Number(val) > 0) this.priceStatus = 'up';
      if (Number(newVal) !== Number(val)) this.startPrice = val;
    },
    priceStatus: function(){
      setTimeout(() => {
        this.priceStatus = null
        this.startPrice = null;
      } ,5000)
    },
  },
}
</script>

<style scoped>
.blink {
  animation: top-arrow-down 1s 10!important;
}
</style>
