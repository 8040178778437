<template>
  <div class="contents_in_m20" v-if="isLoading">
    <div class="main_title_1" id="myid" mytip="this is my tooltip">인기베팅경기 </div>
    <div id="third" class="carouseller">
      <a class="carouseller__left" @click="movePage(-1)"> <img src="@/assets/img/ico/arrow_left.png" style="vertical-align:middle; margin-right: 2px;"></a>
      <a class="carouseller__right" @click="movePage(1)"> <img src="@/assets/img/ico/arrow_right.png" style="vertical-align:middle; margin-left: 2px;"></a>
      <div class="carouseller__wrap">
        <div class="carouseller__list" style="width: 5500px; left: 0px;">
          <div class="car__4 ng-scope" style="text-align: center; font-size: 30px;" v-for="row in lists" :key="'popular' + row.game_seq">
            <a style="cursor: pointer">
              <div class="main_bet_time">
                <p class="main_bet_time_icon">
                  <img :src="row.league_icon">
                </p>
                <p class="main_bet_time_text ng-binding">
                  {{ row.start_time | formatDate('YYYY-MM-DD') }}<br> {{ row.start_time | formatDate('HH:mm') }}
                </p>
              </div>
              <div class="main_bet_game">
                <div>
                  <div class="ng-scope">
                    <div class="main_bet_name">
                      <img :src="row.sports_image">
                      <div class="main_bet_name_text_1">
                        <span class="ng-binding"><img :src="row.home_team_icon" width="30">&nbsp;&nbsp;{{ row.home_team_name_kr }}</span><br>
                        <br>
                        <span class="ng-binding"><img :src="row.away_team_icon" width="30">&nbsp;&nbsp;{{ row.away_team_name_kr }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <span class="main_bet_btn" @click="$router.push({path: '/sport/prematch'})">베팅하기</span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="contents_in_m20" v-else style="text-align: center; padding-top:20px;">
    <Loading></Loading>
  </div>
</template>

<script>
import Loading from '@/components/Loading'

export default {
  name: 'PopuplarGame',
  components: {
    Loading
  },
  data: () => ({
    originList: [],
    isLoading: false,
    page: 0,
    lists: [],
  }),
  mounted () {
    this.reqData();
  },
  watch: {
    page(val){
      this.lists = this.originList.slice(val, (val+1) * 3)
    },
  },
  methods: {
    reqData: function(){
      this.$store.dispatch('GET_POPULAR_GAME', { payload: { sitename: 'vikbet' }}).then((res) => {
        const data = res.data;
        this.isLoading = true;
        this.originList = data.payload.list;
        this.lists = this.originList.slice(0, 3);
      })
    },
    movePage(page){
      this.page += page;
    }
  }
}
</script>

<style>

/* 인기베팅경기 */
.main_title_1 {width:1328px; height:38px; float:left; border:solid 1px #262a2a;  color:#b7b7b7; line-height:38px; font-size: 14px; font-weight:700; letter-spacing:-1px; text-indent:20px; margin:0 0 10px 0}
.carouseller:before, .carouseller:after {  content: " ";  display: table;}
.carouseller:after { clear: both;}
.carouseller { position: relative; width:100%; float:left;}
.carouseller [class*="car__"]{display: inline-block;position: relative; height:120px;float: left; -webkit-box-sizing: border-box; -moz-box-sizing: border-box;box-sizing: border-box; width:435px;}
.carouseller [class*="car__"]:first-child  { margin-left: 0 !important;}
.carouseller .car__4 { width: 435px; margin:12px 12px 0 0 ;}
.carouseller .car__4:hover {cursor:pointer}
.carouseller .carouseller__list { position: relative; width: 8000px;}
.carouseller .carouseller__wrap { overflow: hidden; position: relative; width:1330px}
.carouseller .carouseller__left  {width:18px; height:18px; background:#293133; text-align:center; position: absolute; right:40px; top:-40px; border-radius:18px; color:#ffffff; font-size:10px;font-family: Nanum Gothic ; line-height:18px; -webkit-transition: all 0.5s ease; -moz-transition: all 0.5s ease; -ms-transition: all 0.5s ease; -o-transition: all 0.5s ease; transition: all 0.5s ease; }
.carouseller .carouseller__right {width:18px; height:18px; background:#293133; text-align:center; position: absolute; right:12px; top:-40px; border-radius:18px; color:#ffffff; font-size:10px;font-family: Nanum Gothic ; line-height:18px; -webkit-transition: all 0.5s ease; -moz-transition: all 0.5s ease; -ms-transition: all 0.5s ease; -o-transition: all 0.5s ease; transition: all 0.5s ease; }
.carouseller .carouseller__left:hover {background:orange; }
.carouseller .carouseller__right:hover {background:orange; }
.main_bet_time {padding:15px 0 0 0;  width:130px; height:105px; background:#262a2b;float:left }
.main_bet_time_icon{text-align:center; margin:0 0 10px 0;}
.main_bet_time_text{text-align:center;line-height:16px;-webkit-transition: all 0.5s ease; -moz-transition: all 0.5s ease; -ms-transition: all 0.5s ease; -o-transition: all 0.5s ease; transition: all 0.5s ease;}
.main_bet_game {width:305px; height:120px; float:left; }
.main_bet_name {width:100%; height:81px; position:relative}
.main_bet_btn {width:100%; float:left; display:inline-block; color:#ffffff; text-align:center;  background:#293133; height:39px; line-height:39px; font-weight:700;  -webkit-transition: all 0.5s ease; -moz-transition: all 0.5s ease; -ms-transition: all 0.5s ease; -o-transition: all 0.5s ease; transition: all 0.5s ease;  }
.carouseller .car__4:hover .main_bet_btn{background-color:orange; }
.carouseller .car__4:hover .main_bet_time_text{color:#ffffff;text-shadow: 0 0 30px #0aafe6; }
.carouseller .car__4:hover .main_bet_name{text-shadow: 0 0 30px #0aafe6;}
</style>
