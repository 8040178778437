<template>
  <header class="notLogin" style="height: 161px; background-color: #1c2021 !important;">
    <div class="nav" :class="{'nav-shrink': navHeader}">
      <div class="util_wrap"></div>
      <ul class="util_left">
        <CurrentTime></CurrentTime>
        <li><router-link to="/bbs/n/l"><span class="util_btn">공지 / 이벤트</span></router-link></li>
        <li style="margin-top: 10px;"> &nbsp;|&nbsp; </li>
        <li><router-link to="/bbs/rule/l"><span class="util_btn">도움말</span></router-link></li>
        <li style="margin-top: 10px;"> &nbsp;|&nbsp; </li>
        <li><router-link to="/sport/result"><span class="util_btn">경기결과</span></router-link></li>
        <li style="margin-top: 10px;"> &nbsp;|&nbsp; </li>
        <li><router-link to="/level"><span class="util_btn">레벨별 혜택</span></router-link></li>
      </ul>
      <div id="uhead">
        <ul class="util_right" v-if="user">
          <li>
            <span class="popover-wrapper right" :class="{'open': toggle2}">
						<div data-role="popover" data-target="example-popover-2" style="cursor:pointer;"><img src="@/assets/img/ico/select_mark.png" @click="toggleModalTop"></div>
						<div class="popover-modal example-popover-2">
							<div class="popover-body">
								<div @click="toggle2 = false;" data-toggle-role="close" style="position:absolute; right:20px; top:0px; cursor:pointer;">x</div>
								<div class="popover-body-in">
									<span class="popover_t">보유머니</span> <span class="popover_money">{{ user.members_cash | makeComma }}</span>
								</div>
								<div class="popover-body-in">
									<span class="popover_t">보유포인트</span> <span class="popover_money">{{ user.members_point | makeComma }}</span>
								</div>
								<div class="popover-body-btn" id="user_popup_menu">
									<div class="popover-body-btn_in">
										<router-link to="/cash/in" data-pg="sb4" data-tab="tab1" style="margin-right: 3px;"><span class="popover_btn" @click="toggle2 = !toggle2">충전신청</span></router-link>
										<router-link to="/cash/out" data-pg="sb4" data-tab="tab2"><span class="popover_btn" @click="toggle2 = !toggle2">환전신청</span></router-link>
									</div>
									<div class="popover-body-btn_in">
										<router-link to="/point/change" data-pg="sb4" data-tab="tab3" style="margin-right: 3px;"><span class="popover_btn" @click="toggle2 = !toggle2">포인트전환</span></router-link>
                    <span class="showLogoutModal popover_btn" style="color: #8a8c8d!important; cursor: pointer;" @click="logOut">로그아웃 </span>

									</div>
									<div class="popover-body-btn_in">
									</div>
								</div>
							</div>
						</div>
					</span>
          </li>
          <li class="hdr-money-row">머니 <span class="util_money money_dsp">{{ user.members_cash | makeComma }}</span></li>
          <li>포인트 <span class="util_point point_dsp">{{ user.members_point | makeComma }}</span></li>
        </ul>
        <div class="top_wrap">
          <div class="top" id="user-header">
            <span class="logo"><router-link to="/"><img class="imgLogo" src="@/assets/img/main/vikbet_logo_main.png" alt=""></router-link></span>

            <ul class="top_right" v-if="!user">
              <li>
                <input id="userid-header-input" type="text" class="input_style0011" name="userid" placeholder="아이디" v-model="signInForm.userId" style="width: 139px;">
                <img class="login-img-validator" data-tab="tab1" id="userid-img" src="@/assets/img/ico/input_mark.png" style="cursor: pointer; margin-top: -4px; height: 34px;">
              </li>
              <!-- input_blue 인풋활성화 -->
              <li>
                <input id="passwd-header-input" type="password" class="input_style0011" name="passwd" placeholder="비밀번호" v-model="signInForm.userPw" style="width: 139px;" @keypress.enter="doLogin">
                <img class="login-img-validator" data-tab="tab2" id="passwd-img" src="@/assets/img/ico/input_mark.png" style="cursor: pointer; margin-top: -5px; height: 34px;">
              </li>

              <!-- input_red 인풋조건미충족 -->
              <li>
                <span v-if="!proceeding" type="submit" class="top_btn1" id="lginHderBtn" @click="doLogin">로그인</span>
                <span v-else type="submit" class="top_btn1" id="lginHderBtn">로그인</span>
              </li>
              <li><span class="top_btn2 fade_1_open" @click="$store.dispatch('SHOW_MODAL', 'signup')">회원가입</span></li>
            </ul>
            <ul class="top_right" v-else id="user_menu_lst">
              <li>
                <div class="nick-cont">
                  <span v-if="user.members_grade === 0" class="user-lvl">신규</span>
                  <span v-else class="user-lvl">LV.{{ user.members_grade }}</span>
                  {{ user.members_nickname }}
                </div>
              </li>
              <li>
								<span class="popover-wrapper right" :class="{'open': toggle}" id="user-pop">
									<div data-role="popover" data-target="example-popover-2">
										<img src="@/assets/img/ico/select_mark.png" style="margin-top: 6px;margin-right: 5px;cursor:pointer" @click="toggleModalUser">
									</div>
									<transition name="fade">
                    <div class="popover-modal example-popover-2" style="right:auto; transform-origin: 29px -10px;">
										<div class="popover-body">
											<div data-toggle-role="close" @click="toggle = false;" style="position:absolute; right:20px; top:10px; cursor:pointer;">×</div>
											<div class="popover-body-btn" style="margin:0px;">
												<div class="popover-body-btn_in">
													<router-link to="/info/user" style="margin-right: 3px;"><span class="popover_btn" @click="toggle = !toggle">내 정보 </span></router-link>
													<router-link to="/info/bet" ><span class="popover_btn" @click="toggle = !toggle">베팅내역</span></router-link>
												</div>
												<div class="popover-body-btn_in">
													<router-link to="/bbs/c/l" style="margin-right: 3px;"><span class="popover_btn" @click="toggle = !toggle">1:1문의</span></router-link>
													<router-link to="/bbs/msg/l" ><span class="popover_btn" @click="toggle = !toggle">쪽지함</span></router-link>
                        </div>
                        <div class="popover-body-btn_in">
													<router-link to="/info/recommender" style="margin-right: 3px;"><span class="popover_btn" @click="toggle = !toggle">추천인</span></router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                  </transition>
                </span>
              </li>
              <li>
								<span class=" top_btn2 ticks" style="text-align:center;" v-if="!navHeader">
									<router-link to="/event/roulette" class="linktominigame" style="color: white;">룰렛 : <span id="tknum">{{ user.roulette }}</span> 장</router-link>
                </span>
              </li>
<!--              <li>-->
<!--                <span class=" top_btn2 ticks" style="text-align:center;" v-if="!navHeader">-->
<!--									<router-link to="/event/lotto" class="linktominigame" style="color: white;">로또 : <span id="tknum">{{ user.lotto }}</span> 장</router-link>-->
<!--                </span>-->
<!--              </li>-->
              <li>
                <span class=" top_btn2 ticks" style="text-align:center; background-color: #6c1116;" v-if="!navHeader" >
									<router-link to="/event/chulsuk" class="linktominigame" style="color: white;">출석체크</router-link>
                </span>
              </li>
              <li>
                <span class="popover-wrapper right" id="charge-pop">
									<div>
										<router-link to="/cash/in" data-pg="sb4" data-tab="tab1" class="top_btn1"> 충전신청 </router-link>
                  </div>
								</span>
              </li>
              <li><router-link to="/cash/out" data-pg="sb4" data-tab="tab2" class="top_btn1"> 환전신청  </router-link></li>
              <!--<li><a href="/cash/in.jsp" data-pg="sb4" data-tab="tab4" class="top_btn3" > 머니전환  </a></li>-->
              <!-- <li><router-link to="/bbs/c/l" data-pg="sb5" data-tab="tab3" class="top_btn1">1:1문의</router-link></li> -->
              <li v-if="!navHeader"><router-link to="/bbs/c/l" data-pg="sb5" data-tab="tab3" class="top_btn1">1:1문의<span v-if="user.unread" class="nav-counter" id="notification"></span></router-link></li>
              <li><a @click="logOut"><span class="top_btn2">로그아웃</span></a></li>
            </ul>
          </div>
        </div>
      </div>

      <div class="nav_wrap">
        <div class="stroke" @mouseleave="subMouseOut">
          <ul id="nav-menu">
            <!--<li><a href="/event/worldcup.jsp" style="padding: 0px; margin: 0 0px;"><img src="/event/img/worldcup/wc_event3.jpg" style="height: 50px;"></a></span> </li> -->
            <li v-if="inplayVisible" @mouseover="subMouseIn('인플레이')"><router-link to="/sport/livematch"><span id="lmtitle">인플레이</span></router-link></li>
<!--            <li v-if="liveVisible" @mouseover="subMouseIn('실시간')"><router-link to="/sport/live2"><span id="lmtitle">실시간</span></router-link></li>-->
            <!--<li ><router-link to="/sport/prematch"><span id="lmtitle">유럽형</span></router-link></li>-->
            <li @mouseover="subMouseIn('스포츠')"><router-link to="/sport/lsport/prematch"><span id="lmtitle" >스포츠</span></router-link></li>
            <li @mouseover="subMouseIn('카지노')"><router-link to="/casino/live">카지노</router-link></li>
            <li @mouseover="subMouseIn('미니게임')"><router-link to="/mini/home">미니게임</router-link></li>
          </ul>
          <div class="sub_menu_wrap">
            <transition name="fade">
              <div style="display: block;" v-if="subMenu.indexOf('인플레이') >= 0">
                <ul>
                  <li v-if="inplayVisible">
                    <router-link to="/sport/livematch" >
                      <img src="@/assets/img/menu/인플레이 로고.png">
                      인플레이
                    </router-link>
                  </li>
                  <li v-if="inplayVisible">
                    <router-link to="/sport/lsport/livematch">
                      <img src="@/assets/img/menu/조합 인플레이 로고.png">
                      조합 인플레이
                    </router-link>
                  </li>
                  <li v-if="kInplayVisible">
                    <router-link to="/sport/kinplay">
                      <img src="@/assets/img/ico/K인플레이아이콘.jpg">
                      K-인플레이
                    </router-link>
                  </li>
                </ul>
              </div>
              <div style="display: block;" v-if="subMenu.indexOf('스포츠') >= 0">
                <ul>
                  <li v-if="liveVisible">
                    <router-link to="/sport/live2" >
                      <img src="@/assets/img/menu/실시간.png">
                      실시간
                    </router-link>
                  </li>
                  <li v-if="koreanVisible">
                    <router-link to="/sport/korean_new" >
                      <img src="@/assets/img/menu/뱃컨한국형3.png">
                      벳컨 한국형
                    </router-link>
                  </li>
                  <li v-if="europeanVisible">
                    <router-link to="/sport/prematch">
                      <img src="@/assets/img/menu/뱃컨유럽형3.png">
                      벳컨 유럽형
                    </router-link>
                  </li>
                  <li v-if="koreanVisible">
                    <router-link to="/sport/korean" >
                      <img src="@/assets/img/menu/한국형1.png">
                      엘스포츠 한국형
                    </router-link>
                  </li>
                  <li v-if="europeanVisible">
                    <router-link to="/sport/lsport/prematch">
                      <img src="@/assets/img/menu/엘스포츠3.png">
                      엘스포츠 유럽형
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/sport/special">
                      <img src="@/assets/img/menu/스페셜.png">
                      스페셜
                    </router-link>
                  </li>
                </ul>
              </div>
              <div  style="display: block;" v-if="subMenu.indexOf('카지노') >= 0">
                <ul>
                  <li>
                    <router-link to="/casino/live" >
                      <img src="@/assets/img/ico/casino/live.jpeg">
                      <!-- <img src="@/assets/img/menu/에볼.png"> -->
                      라이브카지노
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/casino/slot" >
                      <img src="@/assets/img/ico/casino/slot.jpeg">
                      <!-- <img src="@/assets/img/menu/AG.png"> -->
                      슬롯게임
                    </router-link>
                  </li>
                  <li v-if="holdemVisible">
                    <a @click="openKplay(10002)" >
                      <img src="@/assets/img/ico/홀덤아이콘.jpg">
                      <!-- <img src="@/assets/img/menu/AG.png"> -->
                      홀덤
                    </a>
                  </li>
                  <!-- <li>
                    <router-link to="/casino/deposit">
                      <div>충전</div>
                      카지노 충전
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/casino/withdrawal">
                      <div>환전</div>
                      카지노 환전
                    </router-link>
                  </li> -->
                </ul>
              </div>
              <div v-if="subMenu.indexOf('미니게임') >= 0" style="display: block;" >
                <ul>
                  <li>
                    <router-link to="/token/roulette" >
                      <img src="@/assets/img/menu/룰렛.png">
                      룰렛
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/token/baccarat">
                      <img src="@/assets/img/menu/바카라.png">
                      바카라
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/token/highlow">
                      <img src="@/assets/img/menu/하이로우.png">
                      하이로우
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/token/highlow5s">
                      <img src="@/assets/img/menu/하이로우5초.png">
                      하이로우5초
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/lotus/oe">
                      <img src="https://static-sdsoft.s3.ap-northeast-1.amazonaws.com/%E1%84%85%E1%85%A9%E1%84%90%E1%85%AE%E1%84%89%E1%85%B3%E1%84%92%E1%85%A9%E1%86%AF%E1%84%8D%E1%85%A1%E1%86%A8.png" style="object-fit: cover;">
                      로투스 홀짝
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/lotus/baccarat1">
                      <img src="https://static-sdsoft.s3.ap-northeast-1.amazonaws.com/%E1%84%85%E1%85%A9%E1%84%90%E1%85%AE%E1%84%89%E1%85%B3%E1%84%87%E1%85%A1%E1%84%8F%E1%85%A1%E1%84%85%E1%85%A1.png" style="object-fit: cover;">
                      로투스 바카라1
                    </router-link>
                  </li>
                  <!-- <li>
                    <router-link to="/mini/npowerball">
                      <img src="@/assets/img/minigame/new-powerball-2.webp">
                      네임드 파워볼
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/mini/npowerladder">
                      <img src="@/assets/img/minigame/new-powerladder-2.webp">
                      네임드 사다리
                    </router-link>
                  </li> -->
                  <li>
                    <router-link to="/mini/dhpowerball">
                      <img src="@/assets/img/menu/동행파워볼DD.png">
                      동행 파워볼
                    </router-link>
                  </li>
                </ul>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import CurrentTime from '@/components/CurrentTime';
import {mapState} from "vuex";
import MSG from "@/contants/msg";
// import CurrentLanguage from '@/components/CurrentLanguage';

export default {
  name: "Header",
  components: {
    CurrentTime,
    // CurrentLanguage
  },
  props:['currentMenu'],
  data(){
    return {
      navHeader: false,
      toggle: false,
      toggle2: false,
      signInForm: {
        userId: null,
        userPw: null,
        sitename: 'vikbet'
      },
      subMenu: '',
      proceeding: false,
      kplayFlag: true,
    }
  },
  computed: {
    menuVisible(){
      return this.$store.getters['MENU_VISIBLE'];
    },
    inplayVisible(){
      return this.menuVisible?.find(e => e.name === '인플레이').visible
    },
    kInplayVisible(){
      return this.menuVisible?.find(e => e.name === 'K인플레이').visible
    },
    holdemVisible(){
      return this.menuVisible?.find(e => e.name === '홀덤').visible
    },
    europeanVisible(){
      return this.menuVisible?.find(e => e.name === '스포츠-유럽형').visible
    },
    koreanVisible(){
      return this.menuVisible?.find(e => e.name === '스포츠-한국형').visible
    },
    korean2Visible(){
      return this.menuVisible?.find(e => e.name === '스포츠-한국형2').visible
    },
    liveVisible(){
      return this.menuVisible?.find(e => e.name === '라이브').visible
    },
    ...mapState({
      user: state => state.user.user,
      version: state => state.version.version,
      isMobile: state => state.page.isMobile,
    }),
  },
  methods:{
    logOut: function(){
      localStorage.removeItem('authToken');
      this.$store.dispatch("LOGOUT", null)
      this.$router.push({ path: '/main'})
    },
    handleScroll () {
      if (window.scrollY > 30) {
        this.navHeader = true;
      } else {
        this.navHeader = false;
      }
      // Any code to be executed when the window is scrolled
    },
    doLogin: function() {
      this.proceeding = true
      if(!this.signInForm.userId) return this.$store.dispatch('SHOW_ERR_MSG', MSG.error.emptyId);
      if(!this.signInForm.userPw) return this.$store.dispatch('SHOW_ERR_MSG', MSG.error.emptyPassword);
      this.signInForm.version = this.version
      this.$store.dispatch('LOGIN', this.signInForm)
          .then((response) => {
            if (response.status === 200) {
              // 로그인 성공시 처리
              this.$store.dispatch('SHOW_ERR_MSG', MSG.success.welcome);
              this.signInForm.userId = null
              this.signInForm.userPw = null
              this.proceeding = false
            }
          })
          .catch((error) => {
            // 로그인 실패시 처리
            this.$store.dispatch('SHOW_ERR_MSG', error.response.data.error);
            // alert(error.response.data.error);
            this.proceeding = false
          })
    },
    toggleModalTop: function() {
      this.toggle2 = !this.toggle2
      this.toggle = false;
    },
    toggleModalUser: function() {
      this.toggle = !this.toggle
      this.toggle2 = false;
    },
    subMouseOut(){
      // console.log('mouse out');
      this.subMenu = '';
    },
    subMouseIn(menu){
      // console.log(menu);
      this.subMenu = menu;
    },
    openCasino: function(game){
      if (!this.user) return alert(MSG.isNotLogin);
      const config = this.user.members_detail_setting;
      this.$store.dispatch('CHECK_CASINO_WALLET');
      if (game === 'evolution' && !this.menuVisible.find(e => e.name === '에볼루션').visible){
        return this.$store.dispatch('SHOW_ERR_MSG3', '에볼루션 점검중입니다.');
      }
      if (game === 'asia' && !this.menuVisible.find(e => e.name === '아시아').visible){
        return this.$store.dispatch('SHOW_ERR_MSG3', '아시아는 점검중입니다.');
      }
      if (game === 'evolution' && !config.베팅권한.에볼루션.active){
        this.$store.dispatch('SET_ERR_MSG', '에볼루션은 이용하실 수 없습니다.');
        this.$store.dispatch('SHOW_MODAL', 'error2');
        return;
      }
      if (game === 'asia' && !config.베팅권한.아시아게이밍.active){
        this.$store.dispatch('SET_ERR_MSG', '아시아게이밍은 이용하실 수 없습니다.');
        this.$store.dispatch('SHOW_MODAL', 'error2');
        return;
      }
      const data = {
        payload: {
          type: game === 'evolution' ? 45 : 44,
        }
      }
      this.$store.dispatch('GET_LOBBY', data)
          .then(data => {
            const result = data.data;
            if (result.payload) {
              window.open(result.payload.data.lobbyUrl, 'Casino', '_blank')
            }
          })
          .catch(err => {
            alert(err.response.data.error);
          })
    },
    openKplay: function(product_id){
      if (!this.user) return alert(MSG.isNotLogin);
      if (!this.kplayFlag) return alert('처리 중입니다. 잠시만 기다려주세요')
      this.kplayFlag = false
      const data = {
        "domain_url": window.location.protocol + "//" + window.location.host,
        product_id,
        "type": 0,
        "is_mobile": this.isMobile
      }

      this.$store.dispatch('GET_KPLAY_SPORTS', data)
          .then(data => {
            const result = data.data;
            if (result.url) {
              console.log()
              window.open(result.url, 'KPlay', '_blank')
              this.kplayFlag = true
            }
          })
          .catch(err => {
            alert(err.response.data.error);
          })
    },
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
}
</script>

<style scoped>
.notLogin {
}
.nav {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 161px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 5px 20px #0d0f0f;z-index:10001;
}
/* util */
/* util */
.nav .util_wrap {width:100%; height:34px; float:left; background:#141415}
.nav .util_left {position:absolute; left:0; top:0}
.nav .util_right {position:absolute; right:20px; top:0;  line-height:34px;}
.util_left li{float:left;}
.util_time {width:90px; height:34px; line-height:34px; text-align:center;  border-right:solid 1px #2d3232; display:inline-block}
.util_btn {padding:0 20px 0 20px; margin:7px 0 0 0; line-height:25px; color:#ffffff; display:inline-block;  margin:4px 0 0 2px;}
.util_btn:hover {background:#3a4141}
.util_right li{float:right;margin:0 0 0 10px}
.util_money {color:#ffa800}
.util_point {color:#ffa800}

#nav-menu {
  border-bottom: 1px solid #ffaf14;
  border-top: 1px solid #353535;
}

.nav-shrink #nav-menu {
  border-top: 1px solid #353535;
}

img.imgLogo { height: 43px; width: 155px; margin-left:30px; margin-top: 15px;}

.top_wrap {width:100%; float:left; height:75px; background: #2d3232;}
.top_right {float:right; margin:22px 20px 0 0}
.top_right li {float:left;margin:0 0 0 5px}
.top_btn1 {position:relative; width:90px; height:34px; line-height:32px;  display:inline-block; background:#ffa800; color:#ffffff; font-size:14px; text-align:center; font-weight:700; border-radius: 3px 3px 3px 3px;}
.top_btn1:hover {background:#ffaf14}
.top_btn2 {
  width:90px; height:34px; line-height:32px;  display:inline-block; background:#666969; color:#ffffff; font-size:14px; text-align:center; font-weight:700;
  cursor:pointer;
  border-radius: 3px 3px 3px 3px;
}
.top_btn2:hover {background:#717474}
.top_btn3 {width:90px; height:32px; line-height:32px;  display:inline-block; background:#ff8a00; color:#ffffff; font-size:14px; text-align:center; font-weight:700}
.top_btn3:hover {background:#ff8f0c}

.input_style0011 {border:solid 1px #505455; background:none; height:34px; line-height:34px; padding:0 0 0 10px; color:#c1c3c4; box-sizing: border-box; }

/* gnb */
.nav_wrap {width:100%; float:left; height:52px; }
.stroke {}
.stroke ul {text-align: center;}
.stroke ul li {display: inline-block;}
.stroke ul li a { display: block;padding: 15px 10px 18px 10px;text-decoration: none; color: #c1c3c4;font-weight: 700;text-transform: uppercase;margin: 0 20px;font-size:16px;}
.stroke ul li a,.stroke ul li a:after,.stroke ul li a:before {transition: all .5s;}
.stroke ul li a:hover {color: #ffffff;}
/* stroke */
.stroke ul li a{position: relative;}
.stroke ul li a:after {position: absolute;bottom: 0;left: 0; right: 0;margin: auto;width: 0%;content: '.';color: transparent; background: orange; height:2px;}
.stroke ul li a:hover:after {width: 100%;}

/* 스크롤시 header 변화 */
.nav-shrink {position:fixed; top: 0;left: 0;width: 100%; background:#2d3232; height:52px; z-index:10001; box-shadow: 0 5px 20px #0d0f0f;-webkit-transition: all 0.3s ease-in-out;transition: all 0.3s ease-in-out; border-bottom: 1px solid #ffaf14;}
.nav-shrink .util_wrap {display:none}
.nav-shrink .util_left {display:none}
.nav-shrink .util_right {float:right; margin:10px 430px 0 0}
.nav-shrink .top_wrap {height:1px;}
.nav-shrink .logo {position:absolute; left:0; top:0}
.nav-shrink .logo img {width:99px !important; height:23px !important;}

.nav-shrink .top_right {float:right; margin:10px 20px 0 0; }
.nav-shrink .stroke { position:absolute; top:0;}
.nav-shrink .stroke #nav-menu{ padding-left: 200px;}
.nav-shrink .stroke ul { text-align: left;}
.nav-shrink .stroke ul li a {display: block; padding: 17px 5px 18px 5px;text-decoration: none;color: #aaa;font-weight: 700;text-transform: uppercase;margin: 0 10px;font-size:14px;}
.nav-shrink .stroke ul li a:hover {color: #ffffff; }
.nav-shrink .popover-modal {margin:-2px 0 0 0}
.nav-shrink .sub_menu_wrap { width: 1800px}
.nav-shrink .top_right {position:absolute; right:0; top:0px; z-index: 300000}

ul#nav-menu li a:focus{
  outline:none!important;
}
.user-lvl{
  font-weight:700;
  margin-right:5px;
  font-size: 15px;
}

.nick-cont{
  /*width: 152px;*/
  /*height: 32px;*/
  /*background-position: 118px 0px;*/
  /*border: solid 1px #505455;*/
  margin-top: 7px;
  margin-right: 5px;
}
.nick{
  margin-top: 10px;
  margin-left: 10px;
  color: #c1c3c4;
  z-index: 2;
}
#lvl {
  cursor:pointer;
  position: relative;
  top: 8px;
  left: 4px;
  height: 22px;
  margin-right: 5px;
}

/* point_layer */
.popover-wrapper{position:relative}
.example-popover-2{-webkit-box-shadow:0 6px 12px rgba(0,0,0,.175);-moz-box-shadow:0 6px 12px rgba(0,0,0,.175);box-shadow:0 6px 12px rgba(0,0,0,.175);-webkit-transition:all 240ms cubic-bezier(.34,1.61,.7,1);-o-transition:all 240ms cubic-bezier(.34,1.61,.7,1);transition:all 240ms cubic-bezier(.34,1.61,.7,1);-webkit-transform:scale(0);transform:scale(0);transform-origin:29px -10px;opacity:0;position:absolute;z-index:1000;margin-top:5px;border-radius:10px;background-color:#141616}
.popover-wrapper.open .example-popover-2{-webkit-transform:scale(1);transform:scale(1);opacity:1}
.popover-wrapper.open .btn-dropdown{outline:0}
.example-popover-2:before{position:absolute;top:-16px;left:16px;border:8px solid transparent;border-bottom-color:#aab2bd}
.example-popover-2:after{position:absolute;top:-14px;left:17px;border:7px solid transparent;border-bottom-color:#fff}
.example-popover-2 .popover-body{margin:0;padding:4px 0;max-height:300px;overflow:scroll}
.example-popover-2 .popover-body.popover-body-padded{padding:8px}
.example-popover-2 .popover-header .popover-close-btn{float:right}
.popover-wrapper.right .example-popover-2{transform-origin:275px -10px;right:0}
.popover-wrapper.right .example-popover-2:before{left:auto;right:16px}
.popover-wrapper.right .example-popover-2:after{left:auto;right:17px}
.example-popover-2 .popover-body { overflow:hidden; padding:30px 20px 20px 20px; width:244px; }
.popover-body-in {width:100%; float:left;  height:20px; line-height:20px;}
.popover_t {float:left}
.popover_money {color:#ffa800; float:right}
.popover_point { color:#ffa800; float:right}
.popover-body-btn {width:100%; float:left; margin:15px 0 0 0}
.popover-body-btn_in {width:100%; float:left; margin:0 0 2px 0}
.popover_btn {width:120px; height:33px; border-radius:5px; background:#363636; text-align:center; line-height:33px; display:inline-block}
.popover_btn:hover {background:#444444}

.nav-counter {
 position:absolute;
 right: 1px;
 width:12px;
 height:12px;
 border-radius: 6px;
 margin-top: -4px;
 margin-right: -4px;
 font-weight: normal;
 font-size: small;
 color: white;
 text-align: center;
 text-shadow: 0 1px rgba(0, 0, 0, 0.2);
 background: #e23442;
 /* border: 1px solid #911f28; */
 background-image: -webkit-linear-gradient(top, #e8616c, #dd202f);
 background-image: -moz-linear-gradient(top, #e8616c, #dd202f);
 background-image: -o-linear-gradient(top, #e8616c, #dd202f);
 background-image: linear-gradient(to bottom, #e8616c, #dd202f);
 -webkit-box-shadow: inset 0 0 1px 1px rgba(255, 255, 255, 0.1), 0 1px rgba(0, 0, 0, 0.12);
 box-shadow: inset 0 0 1px 1px rgba(255, 255, 255, 0.1), 0 1px rgba(0, 0, 0, 0.12);
}

#notification {
  background: #e23442;
  /* border: 1px solid #911f28; */
  position: absolute;
}


.sub_menu_wrap{
  background: #000 url(../assets/img/menu/mline_oran.png) 50% bottom no-repeat;
}
.sub_menu_wrap ul{
  display: flex;
  align-items: center;
  width: 1280px;
  margin: 0 auto;
  flex-wrap: wrap;
  background: url(../assets/img/menu/line_oran.png) no-repeat center bottom;
}
.sub_menu_wrap ul:last-child{
  background: none;
}

.sub_menu_wrap ul li a {
  position: relative;
  display: block;
  width: 170px;
  line-height: 28px;
  font-size: 14px;
  text-align: center;
  color: #ccc;
  margin: 0 auto;
  padding: 0;
}
.sub_menu_wrap ul li{
  margin-right: 52px;
  margin-bottom: 25px;
  margin-top: 25px;
}
.sub_menu_wrap ul li:last-child{
  margin-right: 0;
}
.sub_menu_wrap ul li a img {
  display: block;
  width: 170px;
  height: 170px;
  border-radius: 10px;
  border: 1px solid #111;
}
.sub_menu_wrap ul li a div {
  display: table-cell;
  width: 170px;
  height: 170px;
  border-radius: 10px;
  text-align: center;
  vertical-align: middle;
  font-size: 20px;
  border: 1px solid #111;
}
.sub_menu_wrap ul li a:hover img{
  border-color: #ffaf14;
}
.sub_menu_wrap ul li a:hover div{
  border-color: #ffaf14;
}
.sub_menu_wrap ul li a:hover {
  color: #ffaf14;
}
.sub_menu_wrap >div {
  display:none
}
</style>
