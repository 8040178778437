<template>
  <td width="260" class="game_right_wrap" valign="top">
    <div>
      <div class="game_right_wrap " id="sidebar">
        <div class="game_right_wrap_1">

          <div class="slip_title_wrap">
            <p class="tooltip" style="cursor:pointer">
              <img v-if="!sticky" src="@/assets/img/ico/pinoff.png" @click="toggleStick" @mouseover="upHere = true" @mouseleave="upHere = false">
              <span class="tooltiptext2" style="display: block; z-index: 20000;" v-if="upHere">
                클릭하시면 베팅슬립이 <font class="font_002">고정</font>되어<br> 스크롤에 따라 <font class="font_002">움직이지 않습니다.</font>
              </span>
              베팅슬립
            </p>
<!--            <ul>-->
<!--              <li style="font-size:10px">배당 변경 자동적용</li>-->
<!--              <li>-->
<!--                <label><input type="checkbox" class="switch" :checked="isUpdate" @click="isUpdate = !isUpdate"><span><em></em></span></label>-->
<!--              </li>-->
<!--            </ul>-->
          </div>

          <div v-if="cartItem.length === 0" style="width: 100%; height: 40px; line-height: 40px; margin: 0px 0px 1px; text-align: center; color: #ffffff;">
            베팅을 원하시면 <br>
            원하시는 경기의 배당을 선택해 주세요.
          </div>
          <div v-else-if="cartItem.length > 0" style="display: block;" class="animate-bottom">
            <div class="slip_tab_wrap" style="">
              <ul>
                <li><a @click="changeSingle(true)"><span :class="{'slip_tab_on2': isSingle, 'slip_tab': !isSingle}">싱글</span></a></li>
                <li><a @click="changeSingle(false)"><span :class="{'slip_tab_on2': !isSingle, 'slip_tab': isSingle}">다폴더</span></a></li>
                <li><a @click="clearCart"><img src="@/assets/img/ico/waste.jpg"></a></li>
              </ul>
            </div>
            <Single v-if="isSingle" :clear-cart="clearCart" :single-bet-cart-set-amount="singleBetCartSetAmount" :is-update="isUpdate" :fixed="fixed"></Single>
            <Multi v-else :clear-cart="clearCart" :is-update="isUpdate" :fixed="fixed"></Multi>
          </div>
        </div>
      </div>
    </div>
  </td>
</template>

<script>
import Single from "@/components/Betting/LsportSingle";
import Multi from "@/components/Betting/LsportMulti";
import {mapState} from "vuex";

export default {
  props: {
    toggleStick: {
      type: Function
    },
    fixed: {
      type: Boolean,
      default: false
    },
  },
  name: "KoreanLsportCart",
  components: {
    Single,
    Multi,
  },
  data: function(){
    return {
      isSingle: false,
      //베팅 진행중
      process: false,
      complete: false,
      sticky: false,
      upHere: false,
      isUpdate: true,
    }
  },
  computed:{
    ...mapState({
      user: state => state.user.user,
      cartItem: state => state.lsport.cart,
    }),
  },
  watch:{
    isSingle: function(val){
      if (val){
        this.singleBetCartSetAmount()
      }
    },
    cartItem: {
      handler: function (val) {
        // 베팅타입 설정
        if (val.length === 1) {
          this.isSingle = true;
        }
        if (val.length > 1) {
          this.isSingle = false;
        }
        // 카트에 보너스 타입이있을시 해당조건 아래일시 보너스 삭제 삭제되도록
        const bonus = val.find(e => e.betType === '보너스');
        if (bonus) {
          const folderCnt = bonus.homeNameKr.split('폴더')[0];
          if (val.length <= Number(folderCnt) ) {
            this.$store.dispatch('DELETE_LSPORT_CART_ITEM', bonus);
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    clearCart: function (){
      return this.$store.dispatch('ALL_LSPORT_CLEAR_ITEM')
    },
    changeSingle: function(is){
      this.isSingle = is;
    },
    singleBetCartSetAmount(){
      for (const c in this.cartItem){
        this.cartItem[c].betAmount = 0;
        this.cartItem[c].totalResult = this.cartItem[c].betAmount * this.cartItem[c].odds;
      }
    },
  },
}
</script>

<style scoped>

</style>
