<template>
  <div class="main_board_wrap"  style="cursor: pointer;">
    <!-- tab -->

    <div class="main_title_2">실시간 경기목록</div>
    <div id="tabs">
      <ul>
        <li>
          <a style="cursor: pointer"  :class="{'tabulous_active' : selectedSport === null}" @click="sort(null)">&nbsp;&nbsp;&nbsp;전체&nbsp;&nbsp;&nbsp;</a>
        </li>

        <li class="ng-scope" style="" v-for="(sport, index) in sports" :key="'inpSport'+index">
          <a :class="{'tabulous_active' : selectedSport === sport.seq}" style="cursor:pointer" @click="sort(sport.seq)">
        &nbsp;&nbsp;&nbsp;<img :src="sport.icon" width="15">&nbsp;&nbsp;&nbsp;
          </a>
        </li>
      </ul>
    </div>
    <table width="100%" cellpadding="0" cellspacing="0" class="board_table_2">
      <tbody>
      <tr>
        <td class="board_table_2_t" style="color:#ef1c26;width:222px;"></td>
        <td class="board_table_2_t" style="color:#ef1c26;width:55px;">승</td>
        <td class="board_table_2_t" style="color:#ef1c26;width:55px;">무</td>
        <td class="board_table_2_t" style="color:#ef1c26;width:55px;">패</td>
        <td class="board_table_2_t" style="width:33px">&nbsp;</td>
      </tr>
      </tbody>
    </table>
    <div class="main_board" v-if="isLoading">
      <div id="tableTicker_wrap3" style="width: 100%; height: 139px; position: relative; overflow: hidden;"><table width="100%" cellpadding="0" cellspacing="0" class="myTable11" style="position: absolute; top: 0px; margin-top: 0px;">
        <tbody>
        <!-- ngRepeat: dl in DL --><!-- end ngRepeat: dl in DL --><!-- end ngRepeat: dl in DL --><!-- end ngRepeat: dl in DL --><!-- end ngRepeat: dl in DL --><!-- end ngRepeat: dl in DL --><!-- end ngRepeat: dl in DL --><!-- end ngRepeat: dl in DL --><!-- end ngRepeat: dl in DL --><!-- end ngRepeat: dl in DL --><!-- end ngRepeat: dl in DL --><!-- end ngRepeat: dl in DL --><!-- end ngRepeat: dl in DL --><!-- end ngRepeat: dl in DL --><!-- end ngRepeat: dl in DL --><!-- end ngRepeat: dl in DL --><!-- end ngRepeat: dl in DL -->
        <tr class="ng-scope">
          <td>
            <table width="100%" cellpadding="0" cellspacing="0" class="board_table_1_1">
              <tbody>
              <tr>
                <table width="100%" cellpadding="0" cellspacing="0" class="board_table_1_1">
                  <tbody
                      :style="listStyle"
                      @mouseenter="toggleAnimation"
                      @mouseleave="toggleAnimation"
                  >
                    <template v-for="(tableItem, index) in lists">
                      <tr
                        v-if="tableItem.isVisible === 'y'"
                        :key="index"
                        :style="itemStyle"
                        @click="moveDetail(tableItem.seq)"
                      >
                        <table width="100%" cellpadding="0" cellspacing="0" class="board_table_1_1">
                          <tbody>
                          <tr  style="cursor: pointer" class="ng-scope">

                            <td class="board_bet_home_2 ng-binding" style="width:222px;text-align:left;padding-left:20px;">
                              {{ tableItem.homeTeam | teamName }} <font style="color:#5c7785;padding:0 5px 0 5px;">vs</font> {{ tableItem.awayTeam | teamName }}</td>
                            <template v-if="tableItem.isSuspended === 'n'">
                              <template v-if="tableItem.folders[0]">
                                <template v-if="tableItem.folders[0].isVisible ==='y'">
                                  <td style="width:60px" class="board_bet_victory_1">
                                    <span class="font_002 ng-binding">
                                      {{
                                        Number(tableItem.folders[0].bets.find(e => e.name === 'W1' || e.name === 'Over' || e.name === '1X' || e.name === 'Odd' || e.name === 'Home').price).toFixed(2)
                                      }}
                                    </span>
                                  </td>
                                  <td style="width:60px" class="board_bet_victory_1">
                                    <span class="font_002 ng-binding">
                                      {{
                                        tableItem.folders[0].bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'Away Goal') ? Number(tableItem.folders[0].bets.find(e => e.name === 'X' || e.name === '12' || e.name === 'Away Goal').price).toFixed(2) : ''
                                      }}
                                    </span>
                                  </td>
                                  <td style="width:60px" class="board_bet_victory_1">
                                    <span class="font_002 ng-binding">
                                      {{
                                        Number(tableItem.folders[0].bets.find(e => e.name === 'W2' || e.name === 'Under' || e.name === 'X2' || e.name === 'Even' || e.name === 'Away').price).toFixed(2)
                                      }}
                                    </span>
                                  </td>
                                  <td style="width:26px" class="board_bet_score_1 ng-scope">
                                    +{{ tableItem.foldersCnt.cnt }}
                                  </td>
                                </template>
                                <template v-else>
                                  <td style="width:60px" class="board_bet_victory_1">
                                    <i class="fa fa-fw fa-lock" style="font-size:3px; padding-right: 6px;"></i>
                                  </td>
                                  <td style="width:60px" class="board_bet_victory_1">
                                    <i class="fa fa-fw fa-lock" style="font-size:3px; padding-right: 6px;"></i>
                                  </td>
                                  <td style="width:60px" class="board_bet_victory_1">
                                    <i class="fa fa-fw fa-lock" style="font-size:3px; padding-right: 6px;" ></i>
                                  </td>
                                  <td style="width:26px" class="board_bet_score_1 ng-scope">
                                    <i class="fa fa-fw fa-lock" style="font-size:3px; padding-right: 6px;"></i>
                                  </td>
                                </template>
                              </template>
                              <template v-else>
                                <td style="width:60px" class="board_bet_victory_1">
                                  <i class="fa fa-fw fa-lock" style="font-size:3px; padding-right: 6px;"></i>
                                </td>
                                <td style="width:60px" class="board_bet_victory_1">
                                  <i class="fa fa-fw fa-lock" style="font-size:3px; padding-right: 6px;"></i>
                                </td>
                                <td style="width:60px" class="board_bet_victory_1">
                                  <i class="fa fa-fw fa-lock" style="font-size:3px; padding-right: 6px;" ></i>
                                </td>
                                <td style="width:26px" class="board_bet_score_1 ng-scope">
                                  <i class="fa fa-fw fa-lock" style="font-size:3px; padding-right: 6px;"></i>
                                </td>
                              </template>
                            </template>
                            <template v-else>
                              <td style="width:60px" class="board_bet_victory_1">
                                <i class="fa fa-fw fa-lock" style="font-size:3px; padding-right: 6px;"></i>
                              </td>
                              <td style="width:60px" class="board_bet_victory_1">
                                <i class="fa fa-fw fa-lock" style="font-size:3px; padding-right: 6px;"></i>
                              </td>
                              <td style="width:60px" class="board_bet_victory_1">
                                <i class="fa fa-fw fa-lock" style="font-size:3px; padding-right: 6px;" ></i>
                              </td>
                              <td style="width:26px" class="board_bet_score_1 ng-scope">
                                <i class="fa fa-fw fa-lock" style="font-size:3px; padding-right: 6px;"></i>
                              </td>
                            </template>
                          </tr>
                          </tbody>
                        </table>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </tr>
              </tbody>
            </table>
          </td>
        </tr>
        </tbody>
      </table>
      </div>
    </div>
    <div class="main_board" v-else style="text-align: center; padding-top:20px;">
      <Loading></Loading>
    </div>
  </div>


</template>

<script>
import { mapState } from "vuex";
import Loading from '@/components/Loading'
export default {
  name: 'InpAutoScroll',
  components: {
    Loading
  },
  data: () => ({
    selectedSport: null,
    lists: [],
    category: null,
    listTop: 0,
    isPaused: false,
    isMovingBackwards: false,
    isLoading: false,
  }),
  props: {
    scale: {
      type: Number,
      default: 1
    },
    transitionDuration: {
      type: Number,
      default: 1000
    },
    transitionDelay: {
      type: Number,
      default: 500
    },
    transition: {
      type: String,
      default: 'ease-in-out'
    },
    pauseOnHover: {
      type: Boolean,
      default: true
    },
    dateFormat: {
      type: String,
      default: 'day'
    }
  },
  watch: {
    selectedSport(val){
      if (!val) return this.lists = this.originList;
      this.lists = this.originList.filter(e => e.sportSeq === val)
    }
  },
  computed: {
    listCount () {
      return this.lists.length
    },
    itemHeight () {
      return 12
    },
    listHeight () {
      return this.itemHeight * this.listCount
    },
    itemStyle () {
      return `height: ${this.itemHeight}px;`
    },
    listStyle () {
      let transitionDelay = this.transitionDelay
      let transitionDuration = this.transitionDuration
      if (this.listTop === 0) {
        transitionDelay = 0
        transitionDuration = 0
      }

      return `height: ${this.listHeight}px;
            transition-delay: ${transitionDelay}ms;
            transition-duration: ${transitionDuration}ms;
            transition-timing-function: ${this.transition};
            will-change: transform;
            transform: translateY(${this.listTop * 3}px)`
    },
    sports(){
      return [...new Set(this.originList.map(e => e.sport).map(JSON.stringify))].map(JSON.parse)
    },
    ...mapState({
      originList: state => state.inplay.originList,
    }),
  },
  created () {
    this.initFolder();
    setTimeout(this.updateState, this.transitionDelay)
    // console.log(this.sports)
  },
  methods: {
    // 경기요청
    initFolder(payload){
      this.isLoading = false;
      this.$store.dispatch('GET_INPLAY_LIST', { payload }).then(() => {
        this.lists = this.originList;
        this.isLoading = true;
      })
    },
    updateState () {
      if (this.listCount <= 1) return;
      if (this.isPaused) return;
      if (this.listCount < 4) return;
      if (this.isMovingBackwards) {
        this.listTop += this.itemHeight
      } else {
        this.listTop -= this.itemHeight
      }
      if (this.listTop < this.itemHeight * -1 && this.lists.length >= 4) {
        const newList = this.lists.splice(0, 1)
        this.lists.push(...newList)
        this.listTop = 0
      }
      this.timeOutId = window.setTimeout(() => {
        this.updateState()
      }, this.transitionDuration + this.transitionDelay)
    },
    toggleAnimation () {
      if (this.pauseOnHover) {
        this.isPaused = !this.isPaused
        if (this.isPaused) {
          clearTimeout(this.timeOutId)
        } else {
          this.updateState()
        }
      }
    },
    moveDetail(id) {
      this.$router.push({path: '/sport/live/d', query: { gid: id }})
    },
    sort(seq){
      this.selectedSport = seq;
    }
  }
}
</script>

<style>
.auto-scroll-tab > .v-data-table__wrapper {
  overflow-y: hidden !important;
}
.body-4 {
  font-size: 13px;
  letter-spacing: -0.2px;
}

.main_title_2 {width:432px;  height:38px; float:left; border:solid 1px #262a2a;  color:#b7b7b7; line-height:38px; font-size: 14px; font-weight:700; letter-spacing:-1px; text-indent:20px; margin:0 0 10px 0; position:relative}

/* tab */
#tabs,#tabs2 {width:100%;}
#tabs ul,#tabs2 ul {width:100% ; float:left; background:#293133; margin:0 0 1px 0}
#tabs li,#tabs2 li {float:left; height:34px;margin:0; }
#tabs li a,#tabs2 li a {display: block;padding:0 8px 0 8px; background: #293133;text-decoration: none;color: #c1c3c4;height:34px;line-height:34px;text-align:center;}
#tabs li a:hover,#tabs2 li a:hover {background: #222627;color: #c1c3c4;}
#tabs_container {padding:0;position: relative;float:left; width:100%;background:#262a2b;}
#tabs_container div {width:100%; }

.board_table_2 {border-bottom:solid 1px #1c2021;background:#262a2b;}
.board_table_2 tr td {height:34px; text-align:center}
.board_table_2_t {height:34px;background:#222627;text-align:center; }
.tabulous_active {background: orange !important; color: #ffffff !important;}

</style>
