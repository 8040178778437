<template>
  <div class="m-game-wrap">
    <template v-if="isLoading">
          <div class="m-game-body"
         v-for="(row, index) in lists"
         v-if="row.status === '진행'"
         :key="'mobileGameList' + index"
    >
      <template v-if="row.isVisible ==='y'">
        <div class="m-game">
          <div class="day">
            <img v-if="row.sport.icon" :src="row.sport.icon">
            <img style="margin-left: 4px;" v-if="row.location.icon" :src="row.location.icon" alt="">
            <span class="t2">
              {{ row.league | leagueName }}
            </span>
            <span class="t3">{{ row | currentPeriod3('period') }}</span>
            <div class="right" v-if="row.foldersCnt.cnt > 0" @click="moveMobileGameDetail(row)">
              <span style="color:#A97BF5;">[{{ row.league.maxAmount | amtKor }}]</span>
              + {{ row.foldersCnt.cnt }}
            </div>
            <div class="right" v-else>
              <span style="color:#A97BF5; margin-right: 10px;">[{{ row.league.maxAmount | amtKor }}]</span>
              <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
            </div>
          </div>
          <div class="team" @click="moveMobileGameDetail(row)" style="cursor: pointer;">
            <div class="name">
              <span>
                {{ row.homeTeam | teamName }}
              </span>
              <!--     스코어         -->
              <span>
                <strong class="orange">
                  {{ row.homeResult }}
                </strong>
                vs
                <strong class="orange">
                  {{ row.awayResult }}
                </strong>
              </span>
              <span>
                {{ row.awayTeam | teamName }}
              </span>
            </div>
          </div>
          <template v-if="checkBlockGame(row)">
            <div class="choice">
              <dl>
                <!-- 종목 -->
                <dt>
                  <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                </dt>
              </dl>
              <dl>
                <!-- 종목 -->
                <dt>
                  <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                </dt>
              </dl>
              <dl>
                <!-- 종목 -->
                <dt>
                  <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                </dt>
              </dl>
            </div>
          </template>
          <template v-else-if="row.folders.length === 0 && row.foldersCnt.cnt === 0">
            <div class="choice">
              <dl>
                <!-- 종목 -->
                <dt>
                  <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                </dt>
              </dl>
              <dl>
                <!-- 종목 -->
                <dt>
                  <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                </dt>
              </dl>
              <dl>
                <!-- 종목 -->
                <dt>
                  <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                </dt>
              </dl>
            </div>
          </template>
          <template v-else-if="row.folders.length === 0 && row.foldersCnt.cnt > 0">
            <div class="choice">
              <dl>
                <!-- 종목 -->
                <dt>
                  <font-awesome-icon icon="fs fa-circle-plus"/>
                </dt>
              </dl>
              <dl>
                <!-- 종목 -->
                <dt>
                  <font-awesome-icon icon="fs fa-circle-plus"/>
                </dt>
              </dl>
              <dl>
                <!-- 종목 -->
                <dt>
                  <font-awesome-icon icon="fs fa-circle-plus"/>
                </dt>
              </dl>
            </div>
          </template>
          <template v-else>
            <div class="choice" v-if="row.folders[0].isVisible === 'y' && row.folders[0].isSuspended === 'n' && !row.folders[0].bets.find(e => Number(e.price) === 0)">
              <MPrice
                  :bets="row.folders[0].bets.find(e => e.name === 'W1' || e.name === 'Over' || e.name === 'Home')"
                  :folder="row.folders[0]"
                  :add-cart="addCart"
                  :check-cart="checkCart"
                  :game="row"
              ></MPrice>
              <MPrice
                  v-if="row.folders[0].bets.find(e => e.name === 'X')"
                  :bets="row.folders[0].bets.find(e => e.name === 'X')"
                  :folder="row.folders[0]"
                  :add-cart="addCart"
                  :check-cart="checkCart"
                  :game="row"
              ></MPrice>
              <MPrice
                  :bets="row.folders[0].bets.find(e => e.name === 'W2' || e.name === 'Under' ||  e.name === 'Away')"
                  :folder="row.folders[0]"
                  :add-cart="addCart"
                  :check-cart="checkCart"
                  :game="row"
              ></MPrice>
            </div>
            <div class="choice" v-else-if="row.folders[0].isVisible === 'y' && (row.folders[0].isSuspended === 'y' || row.folders[0].bets.find(e => Number(e.price) === 0))">
              <dl>
                <!-- 종목 -->
                <dt>
                  <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                </dt>
              </dl>
              <dl v-if="row.folders[0].bets.find(e => e.name ==='X')">
                <!-- 종목 -->
                <dt>
                  <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                </dt>
              </dl>
              <dl>
                <!-- 종목 -->
                <dt>
                  <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                </dt>
              </dl>
            </div>
            <div class="choice" v-else-if="row.folders[0].isVisible === 'n'">
              <dl>
                <!-- 종목 -->
                <dt>
                  <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                </dt>
              </dl>
              <dl>
                <!-- 종목 -->
                <dt>
                  <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                </dt>
              </dl>
              <dl>
                <!-- 종목 -->
                <dt>
                  <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
                </dt>
              </dl>
            </div>
          </template>
        </div>
      </template>
    </div>
    </template>
    <template v-else>
      <div style="min-height: 100px; text-align: center; padding-top: 30px">
        <Loading></Loading>
      </div>
    </template>

  </div>
</template>

<script>
import {mapState} from "vuex";
import Loading from '@/components/Loading'
import MSG from '@/contants/msg'
import MPrice from '@/components/Game/Sports/Inplay/MInplayListPrice'

export default {
  name: "MInpList",
  components: { Loading, MPrice },
  computed: {
    ...mapState({
      user: state => state.user.user,
      cartItem: state => state.inplay.cart,
      originList: state => state.inplay.originList,
    }),
    /* 해당경기의 베팅을 방지하는 설정*/
    blockSetting: function(){
      return this.$store.getters['INPLAY_SETTING'];
    },
  },
  props:['initFolder2'],
  data: function () {
    return {
      lists: null,
      isLoading: false,
      openDetailGame: [],
      payload: {},
    }
  },
  mounted: function () {
    this.initFolder(this.payload);
    this.$socket.client.on('inplayStats', this.matchStats)
    this.$socket.client.on('inplayMatchUpdate', this.matchUpdate)
    this.$socket.client.on('inplay', this.updateData)
  },
  methods: {
    initFolder(payload){
      this.isLoading = false;
      this.$store.dispatch('GET_INPLAY_LIST', { payload }).then((result) => {
        if (result.data.success) {
          this.isLoading = true;
          this.lists = this.originList;
        } else {
          console.log('인플레이 경기요청 에러', result)
          this.$store.dispatch('SHOW_ERR_MSG3', result.data.msg);
        }
      })
    },
    // 매치 상태 변경
    matchStats: function (data) {
      const results = JSON.parse(data);
      for (const r in results) {
        const result = results[r];
        const game = this.lists.find(e => String(e.id) === String(result.EventId))
        if (game) {
          const score = result.Score;
          if (score) {
            game.homeResult = score.split(':')[0] || 0;
            game.awayResult = score.split(':')[1] || 0;
            game.currentPeriod = result.Period;
          }
        }
      }
    },
    // 매치 상태 업데이트
    matchUpdate: function (data) {
      const results = JSON.parse(data);
      for (const r in results) {
        const matchData = results[r];
        // console.log('matchUpdate', matchData);
        const game = this.originList.find(e => String(e.id) === String(matchData.Id));
        if (game) {
          const cartItem = this.cartItem.find(e => String(e.gameId) === String(matchData.Id));
          if (cartItem) {
            cartItem.betsStatus = matchData.IsSuspended || !matchData.IsVisible ? 'y' : 'n';
            cartItem.betsUpdated = true;
          }
          if (matchData.IsStarted) game.status = '진행';
          game.isVisible = matchData.IsVisible ? 'y' : 'n';
          game.isSuspended = matchData.IsSuspended ? 'y' : 'n';
          if (matchData.MatchStatus === 2) {
            game.status = '종료';
            setTimeout(() => {
              this.removeGames(game.seq)
            }, 3000)
          }
        }
      }
    },
    removeGames: function (gameSeq) {
      if (this.lists && this.lists.length > 0) {
        let index = this.lists.indexOf(e => e.seq === gameSeq)
        if (index>=0) {
          this.lists.splice(index, 1)
        }
      }
      // 카트 아이템수정
      if (this.cartItem){
        let index = this.cartItem.indexOf(e => e.seq === gameSeq)
        if (index >= 0) {
          this.$store.state.inplay.cart.splice(index, 1);
        }
      }
    },

    // 배당 변경
    updateData: function (data) {
      const result = JSON.parse(data);
      this.$store.dispatch('UPDATE_INPLAY_LIST', result);
    },
    moveMobileGameDetail: function(game){
      this.initFolder2(game.id)
    },
    checkBlockGame: function(game){
      let blockConfig = {};
      if (game.sportId === 1) {
        blockConfig = this.blockSetting['football'];
        if (game.subResult?.indexOf('HT') >= 0) return false;
        if (Number(game.currentPeriod) === 1 && 45 - Number(game.currentTime) <= Number(blockConfig.first)) {
          this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
          return true;
        }
        if (Number(game.currentPeriod) === 2 && 90 - Number(game.currentTime) <= Number(blockConfig.second)) {
          this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
          return true;
        }
      }
      if (game.sportId === 2) {
        blockConfig = this.blockSetting['hockey'];
        let remainTime = game.remainTime;
        const time = game.subResult?.split(' ');
        if (time) remainTime = `00:${time[time.length -1]}`;
        if (Number(game.currentPeriod) === 1 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.first)}:00`) {
          this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
          return true;
        }
        if (Number(game.currentPeriod) === 2 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.second)}:00`) {
          this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
          return true;
        }
        if (Number(game.currentPeriod) === 3 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.third)}:00`) {
          this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
          return true;
        }
      }
      if (game.sportId === 3) {
        blockConfig = this.blockSetting['basketball'];
        let remainTime = game.remainTime;
        let currentPeriod = 1;
        const time = game.subResult?.split(' ');
        if (time) {
          remainTime = `00:${time[time.length - 1]}`;
          currentPeriod = time.reduce((cnt, element) => cnt + (element.includes('(')), 0);
          console.log(currentPeriod);
        }
        // console.log(remainTime)
        // console.log(`남은시간: ${remainTime}`, `경기제한: ${game.isSuspended} 경기노출: ${game.isVisible}`, `남은경기시간없음: ${remainTime === '00:00:00'}`);
        if (remainTime === '00:00:00'){
          if (game.isSuspended === 'y' || game.isVisible === 'n') return true;
          if (currentPeriod >= 3) return;
        } else {
          if (currentPeriod === 1 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.first)}:00`) {
            if (!remainTime) return true;
            this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
            return true;
          }
          if (currentPeriod === 2 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.second)}:00`) {
            if (!remainTime) return true;
            this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
            return true;
          }
          if (currentPeriod === 3 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.third)}:00`) {
            if (!remainTime) return true;
            this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
            return true;
          }
          if (currentPeriod === 4 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.four)}:00`) {
            if (!remainTime) return true;
            this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
            return true;
          }
          if (currentPeriod > 4) {
            this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
            return true;
          }
        }
      }
      if (game.sportId === 5) {
        blockConfig = this.blockSetting['volleyball'];
        const name = `set${game.currentPeriod}Score`;
        const setHomeScore = game[name] ? game[name].split(':')[0] : 0;
        const setAwayScore = game[name] ? game[name].split(':')[1] : 0;
        if (Number(game.currentPeriod) === 1){
          if (Number(setHomeScore) === 25 || Number(setAwayScore) === 25) return false;
          if (Number(setHomeScore) >= Number(blockConfig.first) - 1) {
            this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
            return true;
          }
          if (Number(setAwayScore) >= Number(blockConfig.first) - 1) {
            this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
            return true;
          }
        }

        if (Number(game.currentPeriod) === 2){
          if (Number(setHomeScore) === 25 || Number(setAwayScore) === 25) return false;

          if (Number(setHomeScore) >= Number(blockConfig.second) - 1) {
            this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
            return true;
          }
          if (Number(setAwayScore) >= Number(blockConfig.second) - 1) {
            this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
            return true;
          }
        }

        if (Number(game.currentPeriod) === 3){
          if (Number(setHomeScore) === 25 || Number(setAwayScore) === 25) return false;

          if (Number(setHomeScore) >= Number(blockConfig.third) - 1) {
            this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
            return true;
          }
          if (Number(setAwayScore) >= Number(blockConfig.third) - 1) {
            this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
            return true;
          }
        }

        if (Number(game.currentPeriod) === 4){
          if (Number(setHomeScore) === 25 || Number(setAwayScore) === 25) return false;

          if (Number(setHomeScore) >= Number(blockConfig.four) - 1) {
            this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
            return true;
          }
          if (Number(setAwayScore) >= Number(blockConfig.four) - 1) {
            this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
            return true;
          }
        }

        if (Number(game.currentPeriod) === 5){
          if (Number(setHomeScore) >= Number(blockConfig.five) - 1) {
            this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
            return true;
          }
          if (Number(setAwayScore) >= Number(blockConfig.five) - 1) {
            this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
            return true;
          }
        }

      }
      if (game.sportId === 6) {
        blockConfig = this.blockSetting['americanFootball'];
        let remainTime = game.remainTime;
        const time = game.subResult?.split(' ');
        if (time) remainTime = `00:${time[time.length -1]}`;
        if (Number(game.currentPeriod) === 1 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.first)}:00`) {
          this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
          return true;
        }
        if (Number(game.currentPeriod) === 2 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.second)}:00`) {
          this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
          return true;
        }
        if (Number(game.currentPeriod) === 3 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.third)}:00`) {
          this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
          return true;
        }
        if (Number(game.currentPeriod) === 4 && remainTime <= `00:${this.$options.filters.twoDigits(blockConfig.four)}:00`) {
          this.$store.dispatch('DELETE_CART_INPLAY_BY_GAME_ID', game.id)
          return true;
        }
      }
      if (game.sportId === 11) {
        blockConfig = this.blockSetting['baseball'];
        const detailResult = game.subResult?.split(' ');
        const periodCnt = detailResult?.filter(e => e.indexOf('(') >= 0).length;
        if (periodCnt > Number(blockConfig.first)) return true;
      }

      return false;
    },
    // 베팅카트에 담기
    addCart: function (bets, folders, game) {
      if (this.user){
        const config = this.user.members_detail_setting;
        if (!config.베팅권한.인플레이.active) return this.$store.dispatch('SHOW_ERR_MSG', MSG.error.authorization);
      } else return this.$store.dispatch('SHOW_ERR_MSG', MSG.error.isNotLogin);

      const cartItem = {
        gameId: game.id,
        gameSeq: game.seq,
        betsSeq: bets.seq,
        betsStatus: bets.status,
        betsId: bets.id,
        betsName: bets.name,
        betsUpdated: false,
        betsPrevOdds: bets.price,
        sportsId: game.sportId,
        foldersId: folders.id,
        foldersSeq: folders.seq,
        foldersType: folders.type,
        odds: bets.price,
        line: bets.line ? bets.line : null,
        leagueNameKr: game.league.nameKr,
        leagueNameEn: game.league.nameEn,
        leagueNameIcon: game.league.icon,
        sportNameKr: game.sport.nameKr,
        sportNameEn: game.sport.nameEn,
        sportNameIcon: game.sport.icon,
        homeNameKr: game.homeTeam.nameKr,
        homeNameEn: game.homeTeam.nameEn,
        awayNameKr: game.awayTeam.nameKr,
        awayNameEn: game.awayTeam.nameEn,
        marketsId: folders.marketId,
        isCheck: folders.market.isCheck,
        marketNameKr: folders.periodSequence !== '0' ? folders.market.nameKr?.replace('{sw}', folders.periodSequence) : folders.market.nameKr,
        marketNameEn: folders.periodSequence !== '0' ? folders.market.nameEn?.replace('{sw}', folders.periodSequence) : folders.market.nameEn,
        startTime: game.startTime,
        odds1: null,
        odds2: null,
        odds3: null,
        betType: '인플레이',
        detailType: null,
        currentPeriod: game.currentPeriod,
        currentTime: `${game.subResult}`,
        homeScore: game.homeResult,
        awayScore: game.awayResult,
        penalty: null,
        maxAmount: game.league.maxAmount,
      }

      if (cartItem.marketNameKr?.indexOf('{sw}') >= 0) {
        if (folders.periodSequence !== '0'){
          cartItem.marketNameKr = cartItem.marketNameKr.replace('{sw}', folders.periodSequence)
          cartItem.marketNameEn = cartItem.marketNameEn.replace('{sw}', folders.periodSequence)
        }
      }

      if (cartItem.marketNameKr?.indexOf('1팀') >= 0) {
        cartItem.marketNameKr = cartItem.marketNameKr.replace('1팀', cartItem.homeNameKr || cartItem.homeNameEn)
      }

      if (cartItem.marketNameKr?.indexOf('2팀') >= 0) {
        cartItem.marketNameKr = cartItem.marketNameKr.replace('2팀', cartItem.awayNameKr || cartItem.awayNameEn)
      }
      this.$store.dispatch('ADD_CART_INPLAY_ITEM', cartItem)
    },
    // 카트내역 체크해서 표시
    checkCart: function (bets, type) {
      if (type === '보너스' || !bets) {
        return null;
      } else {
        let index = this.cartItem.find(item => item.betsSeq === bets.seq)
        return !!index;
      }
    },
  },
}
</script>

<style scoped>

</style>
