<template>
  <div>
    <div>
      <!--        싱글        -->
      <div class="slip_bet_ing">
        <div class="slip-scroll">
          <template v-for="(item, index) in cartItem">
            <div class="slip-box" :class="{'disable_bet': item.betsStatus === 'y'}"  :key="'cartItem'+index">
              <div class="slip_bet_in">
                <div class="slip_bet_row">
                  <div class="slip_bet_cell_l">
                    {{ item.homeNameKr || item.homeNameEn }}
                    <span style="color:orange;"> vs </span><br>
                    {{ item.awayNameKr || item.awayNameEn }}
                  </div>
                  <div class="slip_bet_cell_r">
                    <a @click="deleteItemCart(item)" class="notify-close-btn "><img src="@/assets/img/ico/ing_close.jpg"></a>
                  </div>
                </div>
                <div class="slip_bet_row">
                  <div class=" slip_bet_cell_l">{{ item.marketNameKr || item.marketNameEn }}</div>
                  <div class="slip_bet_cell_r ">  <i style="color:grey;">({{ item.betsPrevOdds }})</i> </div>
                </div>
                <div class="slip_bet_row">
                  <div class="slip_bet_cell_l">
                    <span class="font_002 ">{{ item | cartBetsName }}</span>
                  </div>
                  <div class="slip_bet_cell_r font_002  ">{{ item.odds }}</div>
                </div>
              </div>
            </div>
            <div class="slip_bet_in " :key="'cartBettingAmount'+index">
              <div class="slip_bet_row">
                <div class="slip_bet_cell_ll">베팅금액</div>
                <div class="slip_bet_cell_cc">
                  <input type="number" class="input_style06" style="text-align:right;" format="number" v-model="item.betAmount" @keyup="setBetAmount(item)">
                </div>
                <div class="slip_bet_cell_rr">
                  <!--                <a @click="maxBet"><span class="btn10">맥스</span></a>-->
                </div>
              </div>
            </div>
            <div class="slip_bet_in " :key="'cartBetSlip'+index">
              <div class="slip_bet_row">
                <div class="slip_bet_cell_ll">예상당첨</div>
                <div class="slip_bet_cell_cc" style="text-align: right;">
                  <span class="font_002 ">{{ item.totalResult | makeComma }}</span>
                </div>
                <div class="slip_bet_cell_rr" style="text-align:left">&nbsp;&nbsp;&nbsp;원</div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="bet_btn_wrap" style="">
      <a @click="processBet">
        <span class="bet_btn">베팅하기</span>
      </a>
    </div>
  </div>
</template>

<script>
import MSG from '@/contants/msg';
import {mapState} from "vuex";

export default {
  name: "InPlaySingle",
  props: ['clearCart', 'singleBetCartSetAmount', 'isUpdate'],
  computed: {
    levelSetting: function (){
      return this.$store.getters["LEVEL_SETTING"]
    },
    minAmount: function(){
      return Number(this.levelSetting.inplaySetting.minBetting);
    },
    maxAmount: function(){
      return Number(this.levelSetting.inplaySetting.maxBetting);
    },
    maxWinAmount: function(){
      return Number(this.levelSetting.inplaySetting.manyOther.maxWin);
    },
    maxOneFolderAmount: function(){
      return Number(this.levelSetting.inplaySetting.soloOther.maxBetting);
    },
    maxOneFolderWinAmount: function(){
      return Number(this.levelSetting.inplaySetting.soloOther.maxWin);
    },
    maxOdds: function(){
      return this.levelSetting.inplaySetting.maxOdds;
    },
    oneFolderDeduction: function(){
      return this.levelSetting.levelOption.deduction.one;
    },
    twoFolderDeduction: function(){
      return this.levelSetting.levelOption.deduction.two;
    },
    threeFolderDeduction: function(){
      return this.levelSetting.levelOption.deduction.three;
    },
    fourFolderDeduction: function(){
      return this.levelSetting.levelOption.deduction.four;
    },
    fiveFolderDeduction: function(){
      return this.levelSetting.levelOption.deduction.five;
    },
    ...mapState({
      user: state => state.user.user,
      cartItem: state => state.inplay.cart,
    }),
  },
  watch:{
    cartItem: {
      handler(val){
        const index = val.length;
        val[index-1].betAmount = 0
        val[index-1].totalResult = Math.floor(val[index-1].betAmount * val[index-1].odds)
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    processBet: function(){
      if (this.cartItem.find(b => Number(b.odds) <= 1 )) return this.$store.dispatch('SHOW_ERR_MSG', MSG.error.notAvailableBet);

      // 최소 금액
      const minAmountCheck = this.cartItem.find(e => Number(e.betAmount) < this.minAmount);
      if (minAmountCheck) return this.$store.dispatch('SHOW_ERR_MSG', `최소 베팅금은 ${this.$options.filters.makeComma(this.minAmount)}원 입니다.`);

      // 단폴최대
      const maxAmountCheck = this.cartItem.find(e => Number(e.betAmount) > this.maxOneFolderAmount);
      if (maxAmountCheck) return this.$store.dispatch('SHOW_ERR_MSG', `단폴 최대 베팅금은 ${this.$options.filters.makeComma(this.maxOneFolderBetAmount)}원 입니다.`);

      console.log(this.cartItem)
      const maxAmountCheck2 = this.cartItem.find(e => Number(e.betAmount) > e.maxAmount);
      if (maxAmountCheck2) return this.$store.dispatch('SHOW_ERR_MSG', `해당 경기 최대 베팅금은 ${this.$options.filters.makeComma(maxAmountCheck2.maxAmount)}원 입니다.`);

      // 단폴 당첨금 최대
      const maxWinCheck = this.cartItem.find(e => Number(e.totalResult) > this.maxWinAmount);
      if (maxWinCheck) return this.$store.dispatch('SHOW_ERR_MSG', `단폴 최대 당첨금은 ${this.$options.filters.makeComma(this.maxWinAmount)}원 입니다.`);

      // 싱글벳
      if (this.user.members_cash < this.totalCartAmount()) return this.$store.dispatch('SHOW_ERR_MSG', '베팅금액은 보유금액을 초과할 수 없습니다.');

      let data = {};
      if (['', 0, '0'].indexOf(this.user.members_detail_setting.베팅차감.스포츠.one) < 0){
        let confirmMsg = `단폴 베팅시 ${this.user.members_detail_setting.베팅차감.스포츠.one}% 배당 하락이 발생합니다.`;
        const oneConfirm = confirm(confirmMsg);
        if (!oneConfirm) return;
        this.betDeduction(this.user.members_detail_setting.베팅차감.스포츠.one, '유저설정-단폴베팅')
      } else {
        if (this.oneFolderDeduction) {
          let confirmMsg = `단폴 베팅시 ${this.oneFolderDeduction}% 배당 하락이 발생합니다.`;
          const singleConfirm = confirm(confirmMsg);
          if (!singleConfirm) return;
          this.singleBetDeduction()
        }
      }
      data.bettings = this.cartItem;
      this.$store.dispatch('SET_PROCESS', data);


      if (this.cartItem.find(b => b.betsStatus === 'y')) return this.$store.dispatch('SHOW_ERR_MSG', MSG.notAvailableBet);
      this.$store.dispatch('SHOW_MODAL', 'bettingProcessInplaySingle')

      this.amount = 0;
      this.clearCart();
    },
    singleBetDeduction:function(){
      for (const c in this.cartItem){
        this.cartItem[c].penalty = this.oneFolderDeduction;
        this.cartItem[c].penaltyReason = '단폴 베팅';
      }
    },
    deleteItemCart: function(obj){
      this.$store.dispatch('DELETE_CART_INPLAY_ITEM',obj)
    },
    setBetAmount(item){
      let result = Math.floor(item.odds * item.betAmount);
      if (this.maxOneFolderAmount < Number(item.betAmount)) {
        item.betAmount = Number(this.maxOneFolderAmount);
        item.totalResult = Math.floor(item.odds * item.betAmount);
        this.$forceUpdate();
        return this.$store.dispatch('SHOW_ERR_MSG', `단폴 최대 베팅금은 ${this.$options.filters.makeComma(this.maxOneFolderBetAmount)}원 입니다.`);
      }

      if (Number(item.maxAmount) < Number(item.betAmount)) {
        item.betAmount = Number(this.maxAmount);
        item.totalResult = Math.floor(item.odds * item.betAmount);
        this.$forceUpdate();
        return this.$store.dispatch('SHOW_ERR_MSG', `단폴 최대 당첨금은 ${this.$options.filters.makeComma(this.maxOneFolderAmount)}원 입니다.`);
      }

      if (this.maxWinAmount < result) {
        item.betAmount = Math.floor(this.maxWinAmount / item.odds);
        item.totalResult = Math.floor(item.betAmount * item.betAmount);
        this.$forceUpdate();
        return this.$store.dispatch('SHOW_ERR_MSG', `단폴 최대 당첨금은 ${this.$options.filters.makeComma(this.maxWinAmount)}원 입니다.`);
      }

      item.totalResult = result;
      this.$forceUpdate();
    },
    totalCartAmount: function(){
      let total = 0;
      for (const i in this.cartItem){
        total += Number(this.cartItem[i].betAmount)
      }
      return total;
    },
    betDeduction:function(penalty, reason){
      for (const c in this.cartItem){
        this.cartItem[c].penalty = penalty;
        this.cartItem[c].penaltyReason = reason;
      }
    },
  }
}
</script>

<style scoped>
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
