<template>
  <section v-if="!this.$store.state.page.isMobile">
<!--    <h2 class="subTitle">충전신청</h2>-->


    <div class="nbox size">

      <ul class="mypTabs">
        <li><router-link to="/cash/in" >충전신청</router-link></li>
        <li><router-link to="/cash/out" >환전신청</router-link></li>
        <li class="active"><router-link to="/point/change" class="on">포인트전환</router-link></li>
<!--        <li><router-link to="/point/list">포인트 사용 내역</router-link></li>-->
<!--        <li><router-link to="/recommender">추천인</router-link></li>-->
      </ul>

      <transition name="fade">
        <div class="mypBox" v-if="true" style="display: block;">
        <span class="line"></span>
        <div class="mypForm">
          <div class="fl">
            <div class="swpoint_box">
              <div class="cash_in">
                <div class="cash_10">
                  <p style="float:left">보유 포인트</p>
                  <p style="float:right" class="font_002 pointavail" id="showpoint">{{ user.members_point | makeComma }} P</p>
                </div>
                <!-- <div class="cash_9">
                  <input class="input_style03" id="point" name="point" style="text-align: right; padding-right:10px;" placeholder="전환할 포인트" :value="chargeForm.amount">
                </div> -->
              </div>
              <!-- <div class="cash_in">
                <span @click="addAmount(100)" class="ebtn btn1" id="p1">100포인트</span>
                <span @click="addAmount(300)" class="ebtn btn1" id="p2">300포인트</span>
                <span @click="addAmount(500)" class="ebtn btn1" id="p3">500포인트</span>
                <span @click="addAmount(1000)" class="ebtn btn1" id="p5">1000포인트</span>
                <span @click="addAmount(5000)" class="ebtn btn1" id="p4">5000포인트</span>
                <span @click="resetAmount" class="ebtn btn1" id="reset">정정</span>
              </div> -->
              <div class="cash_in exbtn" style="padding-left:30%;">
                <span v-if="!isLoading" ><button type="submit" id="pointBtnSbmit" class="btn3c" @click="processChange">전환신청</button></span>
                <Loading v-else></Loading>
              </div>
            </div>
          </div>
          <div class="fr">
            <img style="width: 460px;" src="@/assets/img/main/lotto.png" alt="">
          </div>
        </div>
        </div>
      </transition>

      <p class="mypSubj"><a>사용 리스트 내역</a></p>
      <transition name="fade">
        <div class="tableWrap" v-if="true" style="display: block">
          <table class="titleTable01 mt10">
            <colgroup>
              <col width="200" />
              <col width="200" />
              <col width="200" />
              <col width="200" />
              <col width="200" />
            </colgroup>
            <tr>
              <th>일시</th>
              <th>차감 포인트</th>
              <th>가산 포인트</th>
              <th>잔여 포인트</th>
              <th>상태</th>
            </tr>
            <tr v-for="(row, index) in lists" :key="'pointList'+index">
              <td>{{ row.cash_regdatetime | formatDate('YYYY-MM-DD') }}</td>
              <td>{{ row.cash_amount * -1 > 0 ? row.cash_amount : 0 | makeComma }}</td>
              <td>{{ row.cash_amount > 0 ? row.cash_amount : 0 | makeComma }}</td>
              <td>{{ row.cash_after | makeComma }}</td>
              <td>{{ row | cashReasonFilter }}</td>
            </tr>
          </table>
        </div>
      </transition>
    </div>

  </section>
  <div v-else style="background-color: #1c2021; height:700px;">
    <div class="header2" >
      <div class="left">
        <a @click="$router.push('/main')" class="btn-prev"></a>
      </div>
      <h2>포인트전환</h2>
    </div>
    <div id="contents_wrap">
      <div class="contents">
        <div class="sub_wrap">

          <div class="menu_3_2">
            <div style="width:100%">
              <div ><router-link to="/cash/in">충전신청</router-link></div>
              <div><router-link to="/cash/out">환전신청</router-link></div>
              <div class="active"><router-link to="/point/change">포인트전환</router-link></div>
            </div>
          </div>

          <div class="tab_container">
            <div class="tab_content" style="display: block;">
              <ul class="smk_accordion">
                <li class="acc_section acc_active">
                  <div id="TITLE_FORM" class="acc_head"><h3>포인트전환내역</h3></div>
                  <div class="acc_content" style="display: block;">
                    <div class="acc_content_in_1" style="padding-top: 10px">
                      <form target="iframe_charge" name="form_charge" method="post" action="charge_run.php" onsubmit="return false">
                        <input type="hidden" name="actype">
                        <input type="hidden" name="bank_name">
                        <input type="hidden" name="bank_num">
                        <input type="hidden" name="bank_owner">
                        <input type="hidden" name="charge_level">
                        <div class="cash_box">
                          <div class="cash_in" style="padding-bottom:10px;">
                            <div class="cash_1"><p style="float:left;padding:0 0 0 10px">보유포인트</p><p style="float:right;padding:0 10px 0 0"><span class="font_002">{{ user.members_point | makeComma }}</span> P</p></div>
                          </div>
                          <div class="cash_in">
                            <table width="100%" cellpadding="0" cellspacing="2">
                              <tbody>
                                <tr>
                                  <td colspan="2" width="50%"><a><span class="btn1ci" style="width: 100% !important;" @click="processChange">전환</span></a></td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </li>
                <li class="acc_section">
                  <div id="TITLE_LIST" class="acc_head k_open"><h3>전환신청 리스트</h3></div>
                  <div class="acc_content" style="display: block;">
                    <div class="acc_content_in_1" id="LIST_ACCORDION">
                      <table class="list_table" cellspacing="0" cellpadding="0" width="100%">
                        <tbody>
                        <tr>
                          <td class="list_table_t" width="20%">번호</td>
                          <td class="list_table_t" width="20%">일시</td>
                          <td class="list_table_t" width="20%">금액</td>
                          <td class="list_table_t" width="25%">상태</td>
                        </tr>
                        <tr v-for="(row, index) in lists" :key="'test' + index">
                          <td class="list_table_center">{{ index + 1 }}</td>
                          <td class="list_table_center">{{ row.cash_regdatetime | formatDate('YYYY-MM-DD') }}</td>
                          <td class="list_table_center"><span class="font_002">{{ row.cash_amount | makeComma }}</span>P</td>
                          <td class="list_table_center font_009">{{ row | cashReasonFilter }}</td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from '@/components/Loading'
import MSG from '@/contants/msg'
import {mapState} from "vuex";
export default {
  name: "PointChange",
  components: {
    Loading,
  },
  data: function(){
    return {
      amount: 0,
      account: '',
      showReq: false,
      showList: false,
      showDescription: false,
      isLoading: false,
      payload: {
        page: 1,
        size: 20,
        detailType: ['포인트', '수동-포인트']
      },
      chargeForm: {
        amount: 0,
      },
      pagination: {}
    }
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
      lists: state => state.user.balanceHistory
    }),
  },
  watch: {
    amount: function(val){
      if (Number(this.user.members_point) <= Number(val)) {
        this.amount = this.user.members_point
      }
    },
  },
  mounted: function() {
    window.scrollTo(0,0);
    this.reqData(this.payload)
  },
  methods: {
    reqData: function(payload){
      this.$store.dispatch('BALANCE_HISTORY', { payload })
          .catch(err => {
            alert(err.response.data.error);
          })
    },
    // 포인트 전환
    processChange: function(){
      this.isLoading = true;
      if (confirm('포인트를 전환 하시겠습니까?')){
        if (this.user.members_point < 5000) {
          this.isLoading = false;
          return alert(MSG.minPointAmount);
        }
        if (this.user.members_point < this.chargeForm.amount) {
          this.isLoading = false;
          return alert(MSG.hasPointErrMsg);
        }
        this.chargeForm.amount = this.user.members_point;
        this.$store.dispatch('PROCESS_POINT_CHANGE', { payload: this.chargeForm })
            .then((response) => {
              if (response.data.success) {
                this.$store.dispatch('ME')
                this.isLoading = false;
              }
            })
      }
    },
    processChangeAmount: function(){
      this.isLoading = true;
      if (confirm('포인트를 전환 하시겠습니까?')){
        if (this.chargeForm.amount < 5000) {
          this.isLoading = false;
          return alert(MSG.minPointAmount);
        }
        if (this.user.members_point < this.chargeForm.amount) {
          this.isLoading = false;
          return alert(MSG.hasPointErrMsg);
        }
        // this.chargeForm.amount = this.user.members_point;
        this.$store.dispatch('PROCESS_POINT_CHANGE_AMOUNT', { payload: this.chargeForm })
            .then((response) => {
              if (response.data.success) {
                this.$store.dispatch('ME')
                this.isLoading = false;
              }
            })
      }
    },
    movePage: function(page){
      this.$router.push({ path: `/${page}`})
    },
    addAmount(amount){
      console.log(amount)
      this.chargeForm.amount += amount;
    },
    resetAmount(){
      this.amount = 0;
    }
    // 기록 삭제
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s
}

.fade-enter,
.fade-leave-to
  /* .fade-leave-active in <2.1.8 */

{
  opacity: 0
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.sub_title {width:100%;height:55px; line-height:55px; text-align:center; font-size:1.1em; color:#ffffff; background:#151818; font-weight:bold; position:relative;}
.arrow_back {position:absolute; left:5px; top:0}
.arrow_back img{height:25px; width:auto}

.sub_wrap {width:100%;  float:left; padding:0 0 30px 0; clear:both}
/* 3칸 2줄 Grid Menu : 충전신청 ,환전신청 , 포인트전화, 머니전화, 포인트 사용내역, 머니사용내역 */
.menu_3_2 {
  width:100%;
  display: table;
  background-color: #1c2021;
}

.menu_3_2 DIV {
  display: table-row;
  border-left: #2D3232 solid 1px;
  border-top: #2D3232 solid 1px;
}

.menu_3_2 DIV DIV{
  width:33.33%;
  height: 40px;
  border-right: #2D3232 solid 1px;
  border-bottom: #2D3232 solid 1px;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

.menu_3_2 DIV DIV a {
  color:#6B7A7A;
  font-weight: bold;
}

.menu_3_2 DIV DIV.active{
  background-color: #252929;
}

.menu_3_2 DIV DIV.active a {
  color:orange;
  font-weight: bold;
}






/* 2칸 2줄 Grid Menu : 충전신청 ,환전신청 , 포인트전화, 머니전화, 포인트 사용내역, 머니사용내역 */
.menu_2_2 DIV {
  display: table-row;
  border-left: #2D3232 solid 1px;
  border-top: #2D3232 solid 1px;
}

.menu_2_2 DIV DIV{
  width:33.33%;
  height: 40px;
  border-right: #2D3232 solid 1px;
  border-bottom: #2D3232 solid 1px;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

.menu_2_2 DIV DIV a {
  color:#6B7A7A;
  font-weight: bold;
}

.menu_2_2 DIV DIV.active{
  background-color: #252929;
}

.menu_2_2 DIV DIV.active a {
  color:orange;
  font-weight: bold;
}

/* tab */
ul.tabs {float: left; height: 40px; border: 1px solid #2d3232; width: 100%;}
ul.tabs li {float: left; height: 40px;line-height: 40px;margin-bottom: -1px;background: #1c2021;border-right: 1px solid #2d3232;overflow: hidden;position: relative;width:49.7%;text-align:center;font-weight:bold;display:inline-block;}
ul.tabs li:last-child{border-right:none}
ul.tabs li a {background: #1c2021;color:#6b7a7a; width:100%; height:40px;display:inline-block;}
ul.tabs li a:hover {background: #1c2021;color:#6b7a7a; width:100%; height:40px;display:inline-block;}
.tabs li.active a:hover{background: #252929; color:#02a8e4 ;width:100%;  height:40px;display:inline-block;}
.tabs li.active a  {background: #252929; color:#02a8e4 ;border-bottom: none;width:100%; height:40px;display:inline-block;}
.tab_container {clear: both; float: left; width: 100%;}
.tab_content {padding: 10px 0 0 0;}

/* accordion */
.smk_accordion { width:100%; position: relative; }
.smk_accordion .acc_section .acc_head {position: relative;background: #2d3232 url("/design/images/arrow_bottom.png") center right 10px no-repeat;padding:0 3% 0 0;display: block; cursor: pointer; width:97%; text-indent:10px; }
.smk_accordion .acc_section .acc_head h3 {line-height: 1;  font-weight:500;height:40px; line-height:40px}
.smk_accordion .acc_section .acc_content {background:#191a1b; }
.smk_accordion .acc_section.acc_active > .acc_head {background: #222727 url("/design/images/arrow_top.png") center right 10px no-repeat;width:97%;padding:0 3% 0 0;}
.smk_accordion .acc_section.acc_active > .acc_head h3 {line-height: 1;  font-weight:500;height:40px; line-height:40px}
.smk_accordion li{margin:0 0 1px 0}
.acc_content_in_1 {padding:10% 3% 10% 3%; background:#191a1b; clear:both; position:relative}


/* 충전/환전/포인트/머니 */
.cash_box {width:100%; display:block}
.cash_in {width:100%; display:inline-block;}
.cash_1 {width:99.5%; height:38px; line-height:38px; background:#191a1b; float:left; border:solid 1px #505455; margin:0 0 10px 0}
.cash_2 {float:left;width:100%; height:40px;margin:0 0 10px 0; }
.cash_3 {float:left; width:100%; }
.cash_3 div{text-align:center }
.cash_4 {width:100%; height:40px; margin:0 0 10px 0; float:left; }
.cash_5 { float:left; color:#6b7f7f; margin:0 0 10px 0}
.cash_7 {float:left; color:#ffa800;margin:5px 0 10px 0}
.cash_8 {width:100%; float:left; height:40px;  color:#ffa800; margin:0 0 0 0}
.cash_in div{text-align:center }
.cash_warning {line-height:25px; width:100%; margin:0 0 30px 0}
.cash_bank {width:100%;}
.back_table {width:100%;border-top:solid 1px #2e3032; margin:20px 0 0 0 }
.bank_name {width:25%;color:#ffffff; background:#151516;border-bottom:solid 1px #2e3032; text-align:center; border-right:solid 1px #2e3032;height:40px ;border-left:solid 1px #2e3032}
.bank_time {width:25%;color:#ffa800; background:#191a1b;border-bottom:solid 1px #2e3032; text-align:center;height:40px; border-right:solid 1px #2e3032;height:40px  }

/* input */
input { font-family: Nanum Gothic; color:#ffffff}
input:not([type='checkbox,radio']):focus::-webkit-input-placeholder {color: transparent;} /* 크롬에서 placeholder 사라지게 */
input:not([type='checkbox,radio']):focus::-moz-input-placeholder {color: transparent;} /* 크롬에서 placeholder 사라지게 */
input:not([type='checkbox,radio']):focus::-o-input-placeholder {color: transparent;} /* 크롬에서 placeholder 사라지게 */
textarea:not([type='checkbox,radio']):focus::-webkit-input-placeholder {color: transparent;} /* 크롬에서 placeholder 사라지게 */

input:focus {outline:none}
select:focus {outline:none}
.input_style01d {border:solid 1px #505455; background:none; height:40px; line-height:25px; padding:0 0 0 10px; color:#6b7f7f; width:327px;}
/* btn */
.btn1i {width:98%; height:40px; display:inline-block; background:#363e3f; text-align:center; color:#ffffff; line-height:40px; border-radius:5px;font-weight:bold }
.btn1i:hover {background:#323435}
.btn1ci {width:98%; height:40px; display:inline-block; background:orange; text-align:center; color:#ffffff; line-height:40px; border-radius:5px;font-weight:bold }
.btn1ci:hover {background:#0d9fd4}
.btn2i{width:98%; height:40px; display:inline-block; background:#323232; text-align:center; color:#ffffff; line-height:40px; border-radius:5px;font-weight:bold }
.btn2i:hover {background:#323435}

/* 게시판list */
.list_table {width:100%; margin:0 0 20px 0; border-top:solid 1px orange; }
.list_table_center { height:40px; text-align:center;  border-bottom:solid 1px #2e3032}
.list_table_t {color:#ffffff; background:#151516; height:40px;  text-align:center; border-bottom:solid 1px #000000;}
.hand img{height:30px; width:auto }


.swpoint_box {
  width: 650px;
  display: block;
  float: left;
}
.cash_in {
  width: 100%;
  display: inline-block;
  margin: 0 0 10px 0;
}
.cash_10 {
  width: 500px;
  height: 38px;
  margin: 25px 30px 10px 30px;
  line-height: 38px;
  background: #191a1b;
  padding: 0 10px 0 10px;
  float: center;
  border: solid 1px #2e3032;
}
.cash_9 {
  width: 265px;
  height: 40px;
  margin: 0 5px 0 0;
  float: left;
}

.input_style03 {width:100%; border:solid 1px #2e3032; background:none; background:#191a1b; height:40px; line-height:40px; padding:0 0 0 10px; color:#c1c3c4}
span.btn1 {
  cursor: pointer!important;
}
.ebtn {
  width: 94px;
  height: 40px;
  margin-right: 3px;
  background: #222627;
  text-align: center;
  color: #ffffff;
  line-height: 40px;
  border-radius: 5px;
  display: inline-block;
}
</style>
