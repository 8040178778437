<template>
  <dl :class="{'active': checkCart(bets)}" v-if="folder.isSuspended === 'y'">
    <!-- 종목 -->
    <dt>
      <img style="width: 9px;" src="@/assets/img/ico/ico_secret.png">
    </dt>
  </dl>
  <dl
      v-else
      :class="{'active': checkCart(bets)}"
      @click="addCart(bets, folder, game)"
  >
    <dt>
      {{ name }}
    </dt>
    <!-- 배당  -->
    <dd>
      <i v-if="priceStatus === 'up'" class="ico-up blink"></i>
      <i v-if="priceStatus === 'down'" class="ico-down blink"></i>
      <p>{{ price }}</p>
    </dd>
  </dl>
</template>

<script>
export default {
  name: "Price",
  props: ['bets', 'folder', 'addCart', 'checkCart', 'game'],
  data: function(){
    return {
      startPrice: null,
      priceStatus: null,
    }
  },
  computed: {
    price: function(){
      return this.bets.price
    },
    name: function(){
      if (this.bets.name === '1') return '승'
      if (this.bets.name === '2') return '패'
      if (this.bets.name === 'X') return '무'
      if (this.bets.name === 'Home') return `승 (${this.bets.line})`
      if (this.bets.name === 'Away') return `패 (${this.bets.line})`
      if (this.bets.name === 'Over') return '오버'
      if (this.bets.name === 'Under') return '언더'
      return '승'
    }
  },
  watch: {
    price: function(newVal, val){
      if (Number(newVal) - Number(val) < 0) this.priceStatus = 'down';
      if (Number(newVal) - Number(val) > 0) this.priceStatus = 'up';
      if (Number(newVal) !== Number(val)) this.startPrice = val;
    },
    priceStatus: function(){
      setTimeout(() => {
        this.priceStatus = null
        this.startPrice = null;
      } ,5000)
    },
  },
}
</script>

<style scoped>
.blink {
  animation: top-arrow-down 1s 10!important;
}
</style>
