<template>
  <div>
    <div class="header2">
      <div class="left">
        <router-link to="/index" class="btn-prev"></router-link>
      </div>
      <h2>카지노</h2>
    </div>
    <div id="contents_wrap">
      <img src="@/assets/img/etc/flechas-arrow.gif" class="swipe_arrow">
      <Swiper :options="swiperOption" class="swiper-horizontal swiper-wrapper">
        <!-- <Swiper-slide class="swiper-slide swipe_tab" >
          <span @click="changeMobileCategory('bookMark')" :class="{'swiper-slide-bookmark': selectedMobileCategory === 'bookMark'}">즐겨찾기</span>
        </Swiper-slide> -->
        <!-- <Swiper-slide class="swiper-slide swipe_tab">
          <span @click="changeMobileCategory('lobby')" :class="{'swiper-slide-active': selectedMobileCategory === 'lobby'}" >카지노 로비</span>
        </Swiper-slide> -->
        <Swiper-slide class="swiper-slide swipe_tab" v-for="(liveCasino, index) in liveCasinoGameList" :key="index+'liveCasinoCategory'">
          <span @click="changeMobileCategory(liveCasino.name)" :class="{'swiper-slide-active': selectedMobileCategory === liveCasino.name}">{{liveCasino.name}}</span>
        </Swiper-slide>
      </Swiper>
    <!-- <span class="swiper-notification" aria-live="assertive" aria-atomic="true"></span> -->
      <div class="contents">
        <div>
          <template v-if="selectedMobileCategory === 'lobby'">
            <div class="game_item" align="center" v-for="(liveCasinoLobby, index) in liveCasinoLobbyList" :key="'liveCasinoLobby' + index" @click="openCasino(liveCasinoLobby.code, liveCasinoLobby.gameList[0].code)">
              <center>
                <table border="0" cellpadding="0" cellspacing="0" width="95%" class="game_table">
                  <tbody>
                    <tr>
                      <td colspan="2" style="background-color: black">
                        <a>
                          <img class="casino-image" :src="liveCasinoLobby.gameList[0].img_1" style="width: 100%" width="114" height="114"/>
                        </a>
                      </td>
                    </tr>
                    <tr style="height: 35px">
                      <td style="width: 100%" class="short_string">{{liveCasinoLobby.gameList[0].name_kor}}</td>
                      <!-- <td style="padding-right: 5px; text-align: right">
                        <a href="javascript:click_favor('EVOLUTION',  'zaidasfortune000')">
                          <img name="FAVOR" state="OFF" id="FAVOR_zaidasfortune000" src="/design/images/icon_bookmark.png"/>
                        </a>
                      </td> -->
                    </tr>
                  </tbody>
                </table>
              </center>
            </div>
          </template>
          <template v-else>
            <template v-for="(game, index) in selectedGameList.gameList">
              <div v-if="index < 9 || extention" class="game_item" align="center" :key="'liveCasinoLobby' + index" @click="openCasino(selectedGameList.code, game.code)">
                <center>
                  <table border="0" cellpadding="0" cellspacing="0" width="95%" class="game_table">
                    <tbody>
                      <tr>
                        <td colspan="2" style="background-color: black">
                          <a>
                            <img name="SLOTIMG" :src="game.img_1" style="width: 100%" width="114" height="114"/>
                          </a>
                        </td>
                      </tr>
                      <tr style="height: 35px">
                        <td style="width: 100%" class="short_string">{{game.name_kor}}</td>
                        <!-- <td style="padding-right: 5px; text-align: right">
                          <a href="javascript:click_favor('EVOLUTION',  'zaidasfortune000')">
                            <img name="FAVOR" state="OFF" id="FAVOR_zaidasfortune000" src="/design/images/icon_bookmark.png"/>
                          </a>
                        </td> -->
                      </tr>
                    </tbody>
                  </table>
                </center>
              </div>
            </template>
          </template>
        </div>

        <table width="100%" height="20">
          <tbody>
            <tr>
              <td>&nbsp;</td>
            </tr>
          </tbody>
        </table>

        <div id="BTN_MORE" style="width: 100%" v-if="!extention && selectedMobileCategory !== 'lobby' && selectedGameList.gameList.length > 6">
          <center>
            <a href="javascript:click_more()">
              <table cellpadding="0" cellspacing="0" width="95%" style="background-color: #111111; border-color: #444444; border-style: solid; border-width: 1px; height: 40px;">
                <tbody>
                  <tr>
                    <td style="text-align: center; color: rgb(1, 174, 240)" @click="extendList"> 더 보기</td>
                  </tr>
                </tbody>
              </table>
            </a>
          </center>
        </div>

        <table width="100%" height="20">
          <tbody>
            <tr>
              <td>&nbsp;</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import "swiper/css/swiper.css";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { mapState } from "vuex";
import MSG from "../../../contants/msg";
export default {
  name: "Main",
  components: {
    Swiper,
    SwiperSlide,
  },
  data: function () {
    return {
      lists: [],
      swiperOption: {
        slidesPerView: 3,
        spaceBetween: 10,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      liveCasinoGameList: [],
      liveCasinoLobbyList: [],
      selectedCategory: null,
      selectedMobileCategory: 'lobby',
      extention: false,
      bookMarkedGameList: [],
      selectedGameList: [],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
    }),
    menuVisible(){
      return this.$store.getters['MENU_VISIBLE'];
    },
  },
  mounted() {
    if (!this.menuVisible.find(e => e.name === '라이브카지노').visible){
      return this.$store.dispatch('SHOW_ERR_MSG3', '라이브 카지노는 점검중입니다.');
    }
    // this.checkMemberType();
    this.goToTop();
    this.reqData();
    // this.lists = this.gameList;
  },
  methods: {
    reqData: function () {
      this.$store
        .dispatch("GET_LIVE", { payload: { sitename: "viking" } })
        .then((res) => {
          const data = res.data.payload.data;
          this.liveCasinoGameList = data.live.liveCasinoGameList;
          this.liveCasinoLobbyList = data.live.liveCasinoLobbyList;
          this.changeMobileCategory(this.liveCasinoGameList[0].name)
        });
    },
    changeCategory: function (category) {
      this.selectedCategory = category;
    },
    changeMobileCategory: function (category) {
      this.selectedMobileCategory = category;
      this.extention = false;
      this.selectedGameList = this.liveCasinoGameList.find(liveCasinoGame=> liveCasinoGame.name === category)
    },
    openCasino: function (lobbyId, gameId) {
      console.log(this.$store.state.page.isMobile ? "MOBILE" : "PC")
      if (!this.user) return alert(MSG.isNotLogin);
      const config = this.user.members_detail_setting;
      // this.$store.dispatch('CHECK_CASINO_WALLET');
      if (gameId === "evolution" && !config.베팅권한.에볼루션.active) {
        this.$store.dispatch("SET_ERR_MSG", "에볼루션은 이용하실 수 없습니다.");
        this.$store.dispatch("SHOW_MODAL", "error2");
        return;
      }
      if (gameId === "asia" && !config.베팅권한.아시아게이밍.active) {
        this.$store.dispatch(
          "SET_ERR_MSG",
          "아시아게이밍은 이용하실 수 없습니다."
        );
        this.$store.dispatch("SHOW_MODAL", "error2");
        return;
      }
      const data = {
        payload: {
          lobbyId,
          gameId,
          platform: this.$store.state.page.isMobile ? "MOBILE" : "PC",
        },
      };
      this.$store
        .dispatch("GET_GAME", data)
        .then((data) => {
          const result = data.data;
          console.log("리절트", result);
          if (result.success) {
            window.open(result.payload.url, "Casino", "_blank");
          } else {
            alert(result.payload.msg);
          }
        })
        .catch((err) => {
          alert(err.response.data.error);
        });
    },
    movePage: function (page) {
      this.$router.push({ path: `/${page}` });
    },
    goToTop: function () {
      window.scrollTo(0, 0);
    },
    extendList: function () {
      this.extention = true
    },
    checkMemberType: function () {
      if(this.user.members_type === '정산제외회원') return
      else {
        alert('카지노는 점검 중입니다.')
        this.$router.push({ path: '/main' })
      }
    }
  },
};
</script>

<style scoped>
.swiper-horizontal {
    touch-action: pan-y;
    background-color:black;
    font-size: 15px;
    color: #ffffff;
    margin: 0 0 0 0;
    font-family: Nanum Gothic, 굴림, 돋음, Dotum, Arial, Helvetica, sans-serif;
}
.swiper-wrapper {
    transition-timing-function: ease-out;
    margin: 0 auto;
    transform: translate3d(0px,0,0);
}
.swiper-wrapper {
    position: relative;
    /* width: 100%; */
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    transition-timing-function: var(--swiper-wrapper-transition-timing-function,initial);
    box-sizing: content-box;
}
.swiper-slide {
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    text-align: center;
    width: 93.75px;
    color: rgb(128, 128, 128);
}
.swiper-slide {
    flex-shrink: 0;
    height: 100%;
    position: relative;
    transition-property: transform;
    display: block;
}
.swiper-slide-bookmark {
    color: yellowgreen;
}
.swiper-slide-active {
    color: orange;
}
.swipe_tab {
    height: 30px;
    vertical-align: middle;
    padding-top: 10px;
    font-size: 14px;
    font-weight: bold;
}
.swipe_arrow {
    height:8px;
    position: absolute;
    z-index: 10;
    margin-left: 95%;
    opacity: 0.3;
}
.contents {
    padding: 5px;
    margin: 0px auto;
}
.game_item {
    width: 33.33333%;
    float: left;
    padding: 5px 0px 5px 0px;
    text-align: center;
    align-items: center;
    overflow: hidden;
}
.casino-image {
  object-fit: cover;
}
.game_table {
    border-color: #444444;
    border-style: solid;
    border-width: 1px;
}
.short_string {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #CCCCCC;
    font-size: 9pt;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 10px;
}
</style>
