<template>
  <div>
    <div>
      <!--       다폴더         -->
      <div class="slip_bet_ing" style="">
        <div class="slip-scroll">
          <div class="slip-box" :class="{'disable_bet': item.betsStatus === 'y'}" v-for="(item, index) in cartItem" :key="'cartItem'+index">
            <div class="slip_bet_in">
              <div class="slip_bet_row">
                <div class="slip_bet_cell_l">
                  {{ item.homeNameKr || item.homeNameEn }}
                  <span style="color:#0d6c9a;"> vs </span><br>
                  {{ item.awayNameKr || item.awayNameEn }}
                </div>
                <div class="slip_bet_cell_r">
                  <a @click="deleteItemCart(item)" class="notify-close-btn ">
                    <img src="@/assets/img/ico/ing_close.jpg">
                  </a>
                </div>
              </div>
              <div class="slip_bet_row">
                <div class=" slip_bet_cell_l">
                  {{ item.marketNameKr || item.marketNameEn }}
                </div>
                <div class="slip_bet_cell_r ">  <i style="color:grey;">({{ item.betsPrevOdds }})</i> </div>
              </div>
              <div class="slip_bet_row">
                <div class="slip_bet_cell_l">
                  <span class="font_002 ">{{ item | cartBetsName }}</span>
                  &nbsp;<span>({{ item.homeScore }} : {{ item.awayScore }})</span>
                </div>
                <div class="slip_bet_cell_r font_002  ">{{ item.odds }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="slip_bet_table_wrap" style="padding-top: 10px;">
          <div class="bet_table_wrap">
            <div class="bet_table">
              <div class="bet_table_row">
                <div class="bet_table_cell_l" style="color: #c1c3c4;">배당률</div>
                <div id="betPrice" class="bet_table_cell_r">
                  <b><font class="font_002 ng-binding">{{ odds }}</font></b>
                </div>
              </div>
            </div>
            <div class="bet_table">
              <div class="bet_table_row">
                <div class="bet_table_cell_ll" style="color: #c1c3c4; font-size: 12px;">베팅금액</div>
                <div class="bet_table_cell_cc">
                  <input type="text" class="input_style06 ng-pristine ng-untouched ng-valid" style="text-align: right;" v-model="amount">
                </div>
                <div class="bet_table_cell_rr">
                  <a @click="maxBet"><span class="btn10">맥스</span></a>
                </div>
              </div>
            </div>
            <div class="bet_table">
              <div class="bet_table_row">
                <div class="bet_table_cell_l" style="color: #c1c3c4; font-size: 12px;">베팅한도</div>
                <div class="bet_table_cell_r" style="color: #c1c3c4;">
                  <span class="font_002 ng-binding">{{ maxAmount | makeComma }}</span>
                  원
                </div>
              </div>
            </div>
            <div class="bet_table">
              <div class="bet_table_row">
                <div class="bet_table_cell_l" style="color: #c1c3c4;">예상당첨</div>
                <div class="bet_table_cell_r" style="color: #c1c3c4;">
                  <span class="font_002 ng-binding">{{ winnings | makeComma }}</span>
                  원
                </div>
              </div>
            </div>
          </div>
          <ul class="money_ul">
            <li>
              <button @click="addAmount(5000)">5,000원</button>
            </li>
            <li>
              <button @click="addAmount(10000)">10,000원</button>
            </li>
            <li>
              <button @click="addAmount(50000)">50,000원</button>
            </li>
            <li>
              <button @click="addAmount(100000)">100,000원</button>
            </li>
            <li>
              <button @click="addAmount(500000)">500,000원</button>
            </li>
            <li>
              <button @click="addAmount(1000000)">1,000,000원</button>
            </li>
            <li class="red">
              <button @click="maxBet">최대배팅</button>
            </li>
            <li class="red" style="margin: 6px 0 0 6px;">
              <button @click="resetAmount">지우기</button>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="bet_btn_wrap" style=""><a @click="processBet"><span class="bet_btn">베팅하기</span></a></div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import MSG from "@/contants/msg";
export default {
  name: "LsportInplayMulti",
  props: ['clearCart', 'isUpdate', 'fixed', 'socket'],
  data: function(){
    return {
      winnings: 0,
      amount: 0,
      maxOddsAmount: 0,
      limitWinnings: 0,
      maxAmount: 5000000
    }
  },

  computed:{
    odds: function(){
      return Number(this.$store.getters["LSPORT_INPLAY_TOTAL_ODDS"])
    },
    levelSetting: function (){
      return this.$store.getters["LEVEL_SETTING"]
    },
    minAmount: function(){
      return Number(this.levelSetting.inplaySetting.minBetting);
    },
    maxWinAmount: function(){
      return Number(this.levelSetting.inplaySetting.manyOther.maxWin);
    },
    maxOneFolderAmount: function(){
      return Number(this.levelSetting.inplaySetting.soloOther.maxBetting);
    },
    maxOneFolderWinAmount: function(){
      return Number(this.levelSetting.inplaySetting.soloOther.maxWin);
    },
    maxOdds: function(){
      return this.levelSetting.inplaySetting.maxOdds;
    },
    oneFolderDeduction: function(){
      return this.levelSetting.levelOption.deduction.one;
    },
    twoFolderDeduction: function(){
      return this.levelSetting.levelOption.deduction.two;
    },
    threeFolderDeduction: function(){
      return this.levelSetting.levelOption.deduction.three;
    },
    fourFolderDeduction: function(){
      return this.levelSetting.levelOption.deduction.four;
    },
    fiveFolderDeduction: function(){
      return this.levelSetting.levelOption.deduction.five;
    },
    ...mapState({
      user: state => state.user.user,
      cartItem: state => state.lsport_inplay.cart,
    }),
  },
  mounted() {
    this.setMaxAmount()
  },
  watch:{
    // 배당변경시 자동으로 최대 가능 베팅금액 변경
    odds: function (val){
      if (Number(this.maxOdds) <= Number(val)) {
        this.cartItem.pop();
        return this.$store.dispatch('SHOW_ERR_MSG', '최대 가능 배당을 초과했습니다.');
      }
      this.calWinnings();
    },
    // 베팅금액 초과시 자동으로 최대 가능 베팅금액 변경
    amount: {
      handler(val){
        if (this.maxAmount < Number(val)) {
          this.amount = this.maxAmount;
          return this.$store.dispatch('SHOW_ERR_MSG', '베팅금액은 최대베팅금액을 초과할 수 없습니다.');
        }
        if (this.user.members_cash < Number(val)) {
          this.amount = this.user.members_cash;
          return this.$store.dispatch('SHOW_ERR_MSG', '베팅금액은 보유금액을 초과할 수 없습니다.');
        }
        this.calWinnings();
        // this.setMaxAmount();
      },
      immediate: true,
    },
    cartItem: {
      handler: function(){
        this.setMaxAmount();
        this.calWinnings();
      },
      immediate: true,
      deep: true,
    },
    // 당첨금 변동
    winnings: function(val){
      if (Number(val) > this.limitWinnings){
        this.amount = Math.floor(this.limitWinnings / this.odds);
        return this.$store.dispatch('SHOW_ERR_MSG', `당첨금액은 ${this.$options.filters.makeComma(this.limitWinnings)}을 초과할 수 없습니다.`);
      }
    }
  },
  methods: {
    deleteItemCart: function(obj){
      this.$store.dispatch('DELETE_CART_LSPORT_INPLAY_ITEM',obj)
    },
    maxBet: function(){
      const values = [Math.floor(this.limitWinnings/this.odds), this.maxAmount, this.user.members_cash]

      this.amount = Math.min.apply(null, values)
      // 보유금액 * 배당이 limitWinnings에 못미칠때
      // if (this.winnings < this.limitWinnings){
      //   this.amount = Math.floor(this.limitWinnings / this.odds);
      // }
    },
    processBet: function(){
      let data = {};
      if (this.amount < this.minAmount) return this.$store.dispatch('SHOW_ERR_MSG', `최소 베팅금은 ${this.minAmount}원 입니다.`);
      if (this.cartItem.find(b => Number(b.odds) <= 1 )) return this.$store.dispatch('SHOW_ERR_MSG', '1배당이 포함되어 베팅이 불가합니다.');
      if (this.odds > this.maxOdds) return this.$store.dispatch('SHOW_ERR_MSG', '최대 배당을 초과하여 베팅할 수 없습니다.')
      if (this.maxAmount < this.amount) {
        return this.$store.dispatch('SHOW_ERR_MSG', '베팅이 가능한 금액을 초과했습니다.');
      }

      // 유저설정 우선
      if (this.cartItem.filter(e=>e.leagueNameEn !== '보너스').length === 1 && ['', 0, '0'].indexOf(this.user.members_detail_setting.베팅차감.스포츠.one) < 0){
        let confirmMsg = `단폴 베팅시 ${this.user.members_detail_setting.베팅차감.스포츠.one}% 배당 하락이 발생합니다.`;
        const oneConfirm = confirm(confirmMsg);
        if (!oneConfirm) return;
        this.betDeduction(this.user.members_detail_setting.베팅차감.스포츠.one, '유저설정-단폴베팅')
      } else {
        if (this.cartItem.filter(e=>e.leagueNameEn !== '보너스').length === 1 && this.oneFolderDeduction) {
          let confirmMsg = `단폴 베팅시 ${this.oneFolderDeduction}% 배당 하락이 발생합니다.`;
          const singleConfirm = confirm(confirmMsg);
          if (!singleConfirm) return;
          this.singleBetDeduction()
        }
      }

      if (
          this.cartItem.filter(e=>e.leagueNameEn !== '보너스').length === 2 && ['', 0, '0'].indexOf(this.user.members_detail_setting.베팅차감.스포츠.two) < 0
      ){
        let confirmMsg = `투폴 베팅시 ${this.user.members_detail_setting.베팅차감.스포츠.two}% 배당 하락이 발생합니다.`;
        const doubleConfirm = confirm(confirmMsg);
        if (!doubleConfirm) return;
        this.betDeduction(this.user.members_detail_setting.베팅차감.스포츠.two, '유저설정-투폴베팅')
      }
      else {
        if (this.cartItem.filter(e=>e.leagueNameEn !== '보너스').length === 2 && this.twoFolderDeduction){
          let confirmMsg = `투폴 베팅시 ${this.twoFolderDeduction}% 배당 하락이 발생합니다.`;
          const doubleConfirm = confirm(confirmMsg);
          if (!doubleConfirm) return;
          this.doubleBetDeduction()
        }
      }
      if (
          this.cartItem.filter(e=>e.leagueNameEn !== '보너스').length === 3 && ['', 0, '0'].indexOf(this.user.members_detail_setting.베팅차감.스포츠.three) < 0
      ){
        let confirmMsg = `세폴 베팅시 ${this.user.members_detail_setting.베팅차감.스포츠.three}% 배당 하락이 발생합니다.`;
        const doubleConfirm = confirm(confirmMsg);
        if (!doubleConfirm) return;
        this.betDeduction(this.user.members_detail_setting.베팅차감.스포츠.three, '유저설정-세폴베팅')
      }

      if (
          this.cartItem.filter(e=>e.leagueNameEn !== '보너스').length > 3 &&
          this.user.is_discount_three_over === 'Y'
      ){
        let confirmMsg = `세폴 베팅 초과시 ${this.user.members_detail_setting.베팅차감.스포츠.three}% 배당 하락이 발생합니다.`;
        const doubleConfirm = confirm(confirmMsg);
        if (!doubleConfirm) return;
        this.betDeduction(this.user.members_detail_setting.베팅차감.스포츠.three, '유저설정-세폴초과베팅')
      }

      data.bettings = this.cartItem;
      data.amount = this.amount;
      data.totalOdds = this.odds;
      data.winnings = this.winnings;
      this.$store.dispatch('SET_PROCESS', data);

      if (this.cartItem.find(b => b.betsStatus === 'y')) return this.$store.dispatch('SHOW_ERR_MSG', MSG.error.notAvailableBet);

      this.$store.dispatch('SHOW_MODAL', 'bettingProcessLsportInplayMulti')
      //다폴더

      this.amount = 0;
      this.clearCart();
    },
    betDeduction:function(penalty, reason){
      for (const c in this.cartItem){
        this.cartItem[c].penalty = penalty;
        this.cartItem[c].penaltyReason = reason;
      }
    },
    singleBetDeduction:function(){
      for (const c in this.cartItem){
        this.cartItem[c].penalty = this.oneFolderDeduction;
        this.cartItem[c].penaltyReason = '단폴 베팅';
      }
    },
    doubleBetDeduction:function(){
      for (const c in this.cartItem){
        this.cartItem[c].penalty = this.twoFolderDeduction;
        this.cartItem[c].penaltyReason = '투폴 베팅';
      }
    },
    calWinnings: function (){
      this.winnings = Math.floor(this.amount * this.odds);
    },
    setMaxAmount: function(){
      let max = Number(this.levelSetting.inplaySetting.maxBetting);

      if (this.cartItem.length === 1) {
        this.limitWinnings = this.maxOneFolderWinAmount;
        max = this.cartItem[0].maxAmount;
      }
      if (this.cartItem.length > 1) {
        this.limitWinnings = this.maxWinAmount;
        max = Math.min.apply(Math, this.cartItem.map(function(o) { return o.maxAmount; }))
      }
      this.maxAmount = max;
      if (Number(this.amount) > Number(max)) {
        if (max > this.user.members_cash) this.amount = this.user.members_cash
        else this.amount = max;
      }
    },
    addAmount(amt){
      this.amount += amt;
    },
    resetAmount(){
      this.amount = 0;
    }
  }
}
</script>

<style scoped>

</style>
